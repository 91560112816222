import { DefaultTheme } from "styled-components";

const purpleTints = {
  "purple-50": "#EDE6FA",
  "purple-100": "#DBCDF6",
  "purple-200": "#C9B5F1",
  "purple-300": "#B59CED",
  "purple-400": "#A184E8",
  "purple-500": "#745ABA",
  "purple-600": "#5C4891",
  "purple-700": "#46376B",
  "purple-800": "#302647",
  "purple-900": "#1C1726",
};

const magentaTints = {
  "magenta-25": "#fef4ff",
  "magenta-50": "#F7E1FB",
  "magenta-100": "#EFC2F6",
  "magenta-200": "#E6A4F1",
  "magenta-300": "#DC84ED",
  "magenta-400": "#D15FE8",
  "magenta-500": "#A22EBA",
  "magenta-600": "#802991",
  "magenta-700": "#5F226B",
  "magenta-800": "#401C47",
  "magenta-900": "#231326",
};

const mauveTints = {
  "mauve-50": "#f4e6f4",
  "mauve-100": "#e9cde9",
  "mauve-200": "#d29cd2",
  "mauve-300": "#bc6abc",
  "mauve-400": "#a539a5",
  "mauve-500": "#8f078f",
  "mauve-600": "#720672",
  "mauve-700": "#560456",
  "mauve-800": "#390339",
  "mauve-900": "#1d011d",
};

const seaBlueTints = {
  "seaBlue-50": "#ebeef5",
  "seaBlue-100": "#B0BDD7",
  "seaBlue-200": "#899BC4",
  "seaBlue-300": "#617AB0",
  "seaBlue-400": "#3A599C",
  "seaBlue-500": "#34508C",
  "seaBlue-600": "#293E6D",
  "seaBlue-700": "#1D2D4E",
  "seaBlue-800": "#0C121F",
  "seaBlue-900": "#060910",
};

const richBlue = {
  "richBlue-50": "#e7e8ed",
  "richBlue-100": "#d0d0db",
  "richBlue-200": "#a1a1b7",
  "richBlue-300": "#717292",
  "richBlue-400": "#42436e",
  "richBlue-500": "#13144a",
  "richBlue-600": "#111243",
  "richBlue-700": "#0d0e34",
  "richBlue-800": "#0a0a25",
  "richBlue-900": "#060616",
};

const greyTints = {
  "grey-50": "#f8f8f8",
  "grey-100": "#f5f5fa",
  "grey-200": "#eeeeee",
  "grey-300": "#e8e8e8",
  "grey-400": "#e0e0e0",
  "grey-500": "#dddddd",
  "grey-600": "#d8d8d8",
  "grey-700": "#cccccc",
  "grey-800": "#bbbbbb",
  "grey-900": "#666666",
};

const colors = {
  richBlack: "#161710",
  richBlue: "#13144a",
  darkBlue: "#211f3b",
  seaBlue: "#3a599c",
  purple: "#926beb",
  lightPurple: "#ba9bf3",
  magenta: "#d716eb",
  magentaSafe: "#e221f6",
  mauve: "#8f078f",
  black: "#15160f",
  white: "#ffffff",
  vapor: "#f8f8f8",
  grey: "#f5f5fa",
  clouds: "#eeeeee",
  midGrey: "#e8e8e8",
  lightGrey: "#e0e0e0",
  darkGrey: "#d8d8d8",
  success: "#0acf97",
  danger: "#E54938",
  ...purpleTints,
  ...magentaTints,
  ...greyTints,
  ...mauveTints,
  ...seaBlueTints,
  ...richBlue,
};

const sizes = {
  xs: "0",
  sm: "600px",
  md: "900px",
  lg: "1280px",
  xl: "1536px",
  xxl: "1920px",
};

export const handleSizes = (size: string) => {
  switch (size) {
    case "xs":
      return sizes.xs;
    case "sm":
      return sizes.sm;
    case "md":
      return sizes.md;
    case "lg":
      return sizes.lg;
    case "xl":
      return sizes.xl;
    case "xxl":
      return sizes.xxl;
    default:
      return size;
  }
};

const fontSize = 14;
const htmlFontSize = 16;
const coef = fontSize / 14;

const typography = {
  pxToRem: (size: number) => `${(size / htmlFontSize) * coef}rem`,
  bodyFontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
  headingFontFamily: "'GT Super', serif",
  lineHeightBase: "0.25rem",
};

const theme: DefaultTheme = {
  header: {
    height: "5rem",
  },
  grid: {
    maxWidth: "1280px",
  },
  typography,
  colors: colors,
  breakpoints: {
    up: (size) => `@media (min-width: ${handleSizes(size)})`,
    down: (size) => `@media (max-width: ${handleSizes(size)})`,
    values: sizes,
    unit: "",
  },
  shadows: {
    navBar: "0px 6px 20px -10px rgba(0, 0, 0, 0.06)",
    small: "0px 2px 20px rgba(0, 0, 0, 0.05)",
    normal: "0px 2px 40px rgba(0, 0, 0, 0.25)",
    medium: "0px 2px 30px rgba(0, 0, 0, 0.08)",
    large: "0px 2px 18px rgba(0, 0, 0, 0.2)",
    bottom: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    neumorphic: "25px 25px 40px rgba(166, 171, 189, 0.6)",
    straightNeumorphic: "0px 25px 40px rgba(166, 171, 189, 0.6);",
    smallNeumorphic: "20px 20px 30px rgba(166, 171, 189, 0.5)",
    smallStraightNeumorphic: "0px 20px 30px rgba(166, 171, 189, 0.5)",
    extraSmallNeumorphic: "10px 15px 30px rgba(166, 171, 189, 0.25)",
  },
  theme: {
    header: "dark",
    page: "light",
  },
  zIndex: {
    topBar: 9,
    popup: 99,
    other: 8,
    banner: 120,
  },
};

const darkTheme: DefaultTheme = {
  ...theme,
  theme: {
    header: "light",
    page: "dark",
  },
};

export { theme, darkTheme, colors, sizes, typography };
