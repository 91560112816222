import { useQuery } from "react-query";
import Cookies from "js-cookie";

export function useIsUserAuth() {
  const antiforgery: string | undefined = Cookies.get(
    "Tillit.Gateway.Antiforgery"
  );
  const authCookie: string | undefined = Cookies.get(
    process.env.GATSBY_TILLIT_SESSION_TOKEN || ""
  );
  const initData = {
    authenticated: authCookie ? true : false,
  };

  const { isLoading, error, data } = useQuery({
    queryKey: "isUserAuth",
    queryFn: () =>
      fetch(`${process.env.GATSBY_APP_ROOT_PATH}/auth/status`, {
        credentials: "include",
        method: "POST",
        headers: {
          "X-CSRF-TOKEN": antiforgery ? antiforgery : "",
        },
      }).then((res) => res.json()),
    initialData: initData,
    staleTime: 5000,
  });

  return { isLoading, error, data };
}
