import React from "react";
import { graphql } from "gatsby";
import {
  FeaturedContentCarouselContent,
  ObjectFit,
  linkTarget,
} from "../../_shared";
import SliceFooterActions from "../SliceFooterActions";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import { PureFeaturedContentCarousel } from "../../design-system/Slices/SliceFeaturedContentCarousel/SliceFeaturedContentCarousel";
import CustomRichText from "../../CustomRichText";
import {
  employerReferralHelper,
  employerTextHelper,
} from "../../../helpers/employerHelper";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedContentCarouselSlice} FeaturedContentCarouselSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedContentCarouselSlice>} FeaturedContentCarouselProps
 * @param { FeaturedContentCarouselProps }
 */
interface FeaturedContentCarouselProps {
  slice: any;
  context?: any;
}

const FeaturedContentCarousel = ({
  slice,
  context,
  ...props
}: FeaturedContentCarouselProps) => {
  const featuredContent: FeaturedContentCarouselContent[] = [];
  const hasFooterOptions = slice.primary.cta_options.document;
  const actions =
    hasFooterOptions &&
    slice.primary.cta_options.document.data.slices.length > 0 ? (
      <SliceFooterActions
        slice={slice.primary.cta_options.document.data.slices[0]}
        align={"center"}
        context={context}
      />
    ) : null;

  const riskWarningAndDisclosure = (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning || ""}
      content={slice.primary.below_cta_content?.richText || null}
      context={context}
    />
  );

  if (slice.items.length > 0) {
    slice.items.forEach((content: any) => {
      featuredContent.push({
        subTitle: employerTextHelper({
          text: content.subtitle,
          context: context,
        }),
        withDivider: content.display_divider || false,
        title: employerTextHelper({
          text: content.title || "",
          context: context,
        }),
        content: content.content.richText ? (
          <CustomRichText
            field={content.content.richText}
            components={{
              paragraph: ({ children }) => <p className="small">{children}</p>,
            }}
            context={context}
          />
        ) : (
          ""
        ),
        link:
          employerReferralHelper({ url: content.link.url, context: context }) ||
          undefined,
        target: content.link.target || linkTarget._self,
        image:
          content.featured_image.localFile?.childImageSharp?.fixed?.src || null,
        imageObjectFit:
          content.featured_image_cover || content.featured_image_cover == null
            ? ObjectFit.cover
            : ObjectFit.contain,
        leftInfo: content.left_info.richText ? (
          <CustomRichText
            field={content.left_info.richText}
            context={context}
          />
        ) : (
          ""
        ),
        rightInfo: content.right_info.richText ? (
          <CustomRichText
            field={content.right_info.richText}
            context={context}
          />
        ) : (
          ""
        ),
      });
    });
  }

  return (
    <PureFeaturedContentCarousel
      withBullets={slice.primary.show_pagination_bullets || false}
      withArrows={slice.primary.show_navigation_arrows || false}
      loop={slice.primary.loop_carousel || false}
      initialSlide={slice.primary.initial_slide || 0}
      content={featuredContent}
      {...props}
    >
      {slice.primary.content.richText && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}
      {actions}
      {riskWarningAndDisclosure}
    </PureFeaturedContentCarousel>
  );
};

export default FeaturedContentCarousel;

export const query = graphql`
  fragment FeaturedContentCarouselDefault on PrismicFeaturedContentCarouselDefault {
    id
    slice_type
    slice_label
    version
    variation
    primary {
      initial_slide
      loop_carousel
      show_navigation_arrows
      show_pagination_bullets
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      content {
        richText
      }
      below_cta_content {
        richText
      }
      risk_warning
    }
    items {
      display_divider
      featured_image_cover
      subtitle
      title
      content {
        richText
      }
      featured_image {
        alt
        url
        localFile {
          childImageSharp {
            fixed(
              width: 600
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              originalName
              src
            }
          }
        }
      }
      left_info {
        richText
      }
      link {
        url
        target
        id
        uid
      }
      right_info {
        richText
      }
    }
  }
`;
