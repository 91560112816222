import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import { colors, sizes } from "../../../../theme/theme";

interface SliceInsightsContainerProps {
  variant?: string;
}

export const SliceInsightsContainer = styled.div<SliceInsightsContainerProps>`
  display: grid;
  width: 100%;
  gap: 2.5rem;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${sizes.sm};
  text-align: center;
  margin: 0 auto;
  padding: 0 1.75rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0;
  }
`;

export const InsightWrapper = styled.div`
  box-sizing: border-box;

  margin: 0 1rem;
  width: calc(100vw - 2rem);

  ${({ theme }) => theme.breakpoints.up("sm")} {
    margin: 0 3rem;
    width: calc(100vw - 6rem);
  }

  max-width: 1580px;
  justify-content: center;
  overflow: hidden;

  .swiper {
    position: relative;
    overflow: visible;
    display: flex;
    margin: 0 1rem;
    padding: 0.5rem 0 3rem 0;
    .swiper-wrapper {
      transition-timing-function: linear !important;
      .swiper-slide {
        height: auto;
        display: flex;
        &.swiper-slide-active {
        }
      }
    }
  }

  .swiper-pagination {
    margin: 20px 0 8px 0;
    justify-content: center;
    display: flex;

    &-lock {
      display: none;
    }

    .swiper-pagination-bullet {
      cursor: pointer;
      border-radius: 50%;
      width: 6px !important;
      height: 6px !important;
      margin: 0 5px !important;
      background-color: ${colors.richBlue};
      transition: transform 0.3s, background-color 0.3s;

      &:focus {
        transform: scale(3);
      }

      &:hover {
        transform: scale(1.33);
      }

      &.swiper-pagination-bullet-active {
        transform: scale(2);
        background-color: ${colors.magenta};
      }
    }
  }
`;
