import React, { useEffect } from "react";
import {
  BgImages,
  bgImageLookup,
  bgImageMobileLookup,
} from "../_shared/images";
import { Container, Padding } from "../../Container/Container";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import {
  CustomImg,
  Content,
  ContentContainer,
  StyledContainer,
  MobCustomImg,
} from "./FSCS.styles";
import { useTheme } from "styled-components";

export interface SliceFscsProps {
  content: JSX.Element | React.ReactNode | string;
}

export function SliceFscs({ content, ...props }: SliceFscsProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";
  const isMdUp = useBreakpointMdUp();
  const bgImage = bgImageLookup[BgImages.SIMPLE_HEXAGON];
  const mobBgImage = bgImageMobileLookup[BgImages.SIMPLE_HEXAGON];
  const [fscsLogoSrc, setFscsLogoSrc] = React.useState(
    "/static/logos/fscs-horizontal.png"
  );

  const innerContent = (
    <>
      <ContentContainer>
        <CustomImg src={fscsLogoSrc} style={{ width: "150px" }} />
        <Content>{content}</Content>
      </ContentContainer>
    </>
  );
  const contentTemplate = isMdUp ? (
    <>
      <Container padding={Padding.both}>{innerContent}</Container>
    </>
  ) : (
    <>{innerContent}</>
  );

  useEffect(() => {
    setFscsLogoSrc(
      isMdUp
        ? "/static/logos/fscs-horizontal.png"
        : isDark
        ? "/static/logos/fscs-horizontal.png"
        : "/static/logos/fscs-horizontal-dark.png"
    );
  }, [isMdUp, isDark]);

  return (
    <>
      <StyledContainer
        bgImage={bgImage}
        padding={Padding.none}
        fullWidth={isMdUp}
        className={isDark ? "dark-background" : ""}
        {...props}
      >
        <MobCustomImg src={mobBgImage} alt="Regulated and FSCS protected" />
        {contentTemplate}
      </StyledContainer>
    </>
  );
}
