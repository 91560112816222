import React from "react";
import { useTheme } from "styled-components";
import { HeroProps } from "../_shared";
import { StyledHeroContainer } from "../Hero.styles";
import { SimpleHeroInner } from "./SimpleHero.styles";
import { Padding } from "../../design-system/Container/Container";

export interface SimpleHeroProps extends HeroProps {
  fullHeight?: boolean;
}

export function SimpleHero({
  title,
  content,
  callToActions,
  belowCTA,
  fullHeight = true,
  ...props
}: SimpleHeroProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <StyledHeroContainer
      fullWidth
      padding={fullHeight ? Padding.horizontal : Padding.both}
      $fullHeight={fullHeight}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <SimpleHeroInner>
        {title}
        {content}
        {callToActions}
        {belowCTA}
      </SimpleHeroInner>
    </StyledHeroContainer>
  );
}
