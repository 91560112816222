import React from "react";
import { useTheme } from "styled-components";
import { Container } from "../../Container/Container";
import { Accordion, AccordionProps } from "../../Accordion/Accordion";
import { FaqsContainer, FaqText, FaqsWrapper } from "./SliceFaqs.styles";
import { Slugify } from "../../../../helpers/slugify";

export enum FAQSliceType {
  default = "default",
  contained = "contained",
}

export interface PureSliceFaqsProps {
  content: JSX.Element | React.ReactNode | string;
  faqs?: AccordionProps[];
  type?: keyof typeof FAQSliceType;
}

export function PureSliceFaqs({
  content,
  faqs,
  type = "default",
  ...props
}: PureSliceFaqsProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container className={isDark ? "dark-background" : ""} {...props}>
      <FaqsContainer variant={type}>
        {content && <FaqText variant={type}>{content}</FaqText>}
        <FaqsWrapper>
          {faqs?.map((faq, i: number) => {
            return (
              <Accordion
                key={`${Slugify(faq?.question || "")}-${i}`}
                question={faq?.question}
              >
                {faq.children}
              </Accordion>
            );
          })}
        </FaqsWrapper>
      </FaqsContainer>
    </Container>
  );
}
