import React from "react";
import { FeaturedVideoContainer } from "./SliceFeaturedVideo.styles";
import { VimeoVideo } from "../../Video/Video";
import { BaseContainerProps } from "../../Container/Container";

export interface PureFeaturedVideoProps extends BaseContainerProps {
  vidId: string;
  rounded?: boolean;
  shadows?: boolean;
  fullWidth?: boolean;
  marginTop?: boolean;
}

export function PureFeaturedVideo({
  vidId,
  rounded = true,
  shadows = true,
  fullWidth = false,
  marginTop,
  ...props
}: PureFeaturedVideoProps) {
  return (
    <FeaturedVideoContainer fullWidth={fullWidth} {...props}>
      <VimeoVideo
        vidId={vidId}
        shadows={shadows}
        rounded={rounded}
        marginTop={marginTop}
      />
    </FeaturedVideoContainer>
  );
}
