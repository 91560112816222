// App Paths:
export const tillitAppHome =
  process.env.GATSBY_APP_ROOT_PATH || "https://my.tillitinvest.com";

export const hexagonPagePath = tillitAppHome;
export const dashboardPath = `${tillitAppHome}/dashboard`;
export const fundListPath = `${tillitAppHome}/funds`;

export const signUpPath = `${tillitAppHome}/open-account`;
export const signInPath = `${tillitAppHome}/sign-in`;
export const startTransferPath = `${tillitAppHome}/open-account/transfer`;
export const logoutPath = `${tillitAppHome}/logout?ReturnPath=/signed-out`;
