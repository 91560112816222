import styled from "styled-components";
import { Text } from "../_shared/Text/Text";
import { colors } from "../../../../theme/theme";
import { Container } from "../../Container/Container";

export interface ContainerProps {
  bgImage: string;
}

export const StyledContainer = styled(Container)<ContainerProps>`
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    align-items: center;
    background-color: #211f3b;
    background-image: url("/static/backgrounds/simple-hexagon.png");
    background-position: 10% center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 38rem;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    background-image: url("/static/backgrounds/simple-hexagon_wide.png");
    background-position: center center;
    min-height: 43rem;
  }
`;

export const MobCustomImg = styled.img`
  width: 100%;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

export const CustomImg = styled.img`
  padding-top: 50px;
`;

export const ContentContainer = styled.div`
  padding: 2rem 4rem;
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    margin-left: auto;
    padding: 2rem 0px;
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    width: 45%;
  }
`;

export const Content = styled(Text)`
  text-align: center;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.white : colors.richBlack};
  }

  h1 {
    margin-top: 0;
  }

  p {
    margin: 24px 0;
  }
  &:last-child {
    margin-bottom: 32px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: left;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
      color: ${colors.white};
    }
  }
`;
