import React from "react";
import { graphql } from "gatsby";
import { PureFeaturedVideo } from "../../design-system/Slices/SliceFeaturedVideo/SliceFeaturedVideo";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedVideoSlice} FeaturedVideoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedVideoSlice>} FeaturedVideoProps
 * @param { FeaturedVideoProps }
 */
interface FeaturedVideoProps {
  slice: any;
}

const FeaturedVideo = ({ slice }: FeaturedVideoProps) => {
  return (
    <PureFeaturedVideo
      vidId={slice.primary.video_id}
      fullWidth={slice.primary.full_width}
      rounded={slice.primary.rounded}
      shadows={slice.primary.shadows}
    />
  );
};

export default FeaturedVideo;

export const query = graphql`
  fragment FeaturedVideoDefault on PrismicFeaturedVideoDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      full_width
      rounded
      shadows
      video_id
    }
  }
`;
