import Cookies from "js-cookie";

export enum AccountType {
  Gia = "GIA",
  Isa = "ISA",
  NotSpecified = "NOT_SPECIFIED",
  Sipp = "SIPP",
  Unknown = "UNKNOWN",
}

const ONBOARDING_STATUS_COOKIE_NAME = "is_onboarding";
const ONBOARDING_STATUS_COOKIE_EXPIRY_DAYS = 30;
const ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME = "onboarding_account_type";
const ONBOARDING_ACCOUNT_TYPE_COOKIE_EXPIRY_DAYS = 30;

export function useOnboardingStatus() {
  const onboardingStatus: boolean =
    Cookies.get(ONBOARDING_STATUS_COOKIE_NAME) === "true" || false;
  const onboardingAccountType = Cookies.get(
    ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME
  );

  const setOnboardingStatus = (status: boolean) => {
    Cookies.set(ONBOARDING_STATUS_COOKIE_NAME, status.toString(), {
      expires: ONBOARDING_STATUS_COOKIE_EXPIRY_DAYS,
      domain: ".tillitinvest.com",
    });
  };

  const setOnboardingAccountType = (accountType: AccountType) => {
    Cookies.set(ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME, accountType, {
      expires: ONBOARDING_ACCOUNT_TYPE_COOKIE_EXPIRY_DAYS,
      domain: ".tillitinvest.com",
    });
  };

  const removeOnboardingAccountType = () => {
    Cookies.remove(ONBOARDING_ACCOUNT_TYPE_COOKIE_NAME, {
      domain: ".tillitinvest.com",
    });
  };

  return {
    onboardingStatus,
    onboardingAccountType,
    setOnboardingStatus,
    setOnboardingAccountType,
    removeOnboardingAccountType,
  };
}
