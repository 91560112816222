import React from "react";
import { graphql } from "gatsby";
import SliceFooterActions from "../SliceFooterActions";
import { useBreakpointMdUp } from "../../../hooks/useBreakpoint";
import { PureTextImage } from "../../design-system/Slices/SliceTextImage/SliceTextImage";
import { Image as StyledImage } from "../../design-system/Image/Image";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.TextImageSlice} TextImageSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextImageSlice>} TextImageProps
 * @param { TextImageProps }
 */

export interface TextImageProps {
  slice: any;
  context?: any;
}

const TextImage = ({ slice, context, ...props }: TextImageProps) => {
  const isLarge = useBreakpointMdUp();
  const hasFooterOptions = slice.primary.cta_options.document;
  const withShadows = slice.primary.shadows ? true : false;
  const mobImgOrder = slice.primary.mobile_image_order;

  return (
    <PureTextImage
      mobImgOrder={mobImgOrder}
      layout={slice.variation}
      image={
        slice.primary.image.localFile?.childImageSharp ? (
          <StyledImage
            image={slice.primary.image.localFile?.childImageSharp}
            withShadows={withShadows}
            rounded={true}
          />
        ) : (
          <></>
        )
      }
      {...props}
    >
      {slice.primary.content && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}

      {hasFooterOptions &&
        slice.primary.cta_options.document.data.slices.length > 0 && (
          <SliceFooterActions
            slice={slice.primary.cta_options.document.data.slices[0]}
            align={isLarge ? "left" : "center"}
            context={context}
          />
        )}

      <RiskWarningAndDisclosures
        riskWarning={slice.primary.risk_warning}
        content={slice.primary.below_cta_content?.richText || null}
        context={context}
      />
    </PureTextImage>
  );
};

export default TextImage;

export const query = graphql`
  fragment TextImageTextImage on PrismicTextImageTextImage {
    id
    slice_type
    slice_label
    variation
    primary {
      shadows
      mobile_image_order
      content {
        richText
      }
      image {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 492
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
            )
          }
        }
        url
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment TextImageImageText on PrismicTextImageImageText {
    id
    slice_type
    slice_label
    variation
    primary {
      shadows
      mobile_image_order
      content {
        richText
      }
      image {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 492
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
            )
          }
        }
        url
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
`;
