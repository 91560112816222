import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import {
  PureFullPageTypeform,
  PurePopupTypeform,
  PureSlideTypeform,
  PureStandardTypeform,
} from "../../design-system/Slices/SliceTypeform/Typeform";
import { ButtonColours } from "../../_shared";
import { Padding } from "../../design-system/Container/Container";
import { employerTextHelper } from "../../../helpers/employerHelper";

/**
 * @typedef {import("@prismicio/client").Content.TypeformEmbedSlice} TypeformEmbedSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TypeformEmbedSlice>} TypeformEmbedProps
 * @param { TypeformEmbedProps }
 */

export interface TypeformEmbedProps {
  slice: any;
  context?: any;
}

const TypeformEmbed = ({ slice, context, ...props }: TypeformEmbedProps) => {
  const variant = slice.variation;
  const formId = slice.primary?.form_id;
  const fullWidth = slice.primary?.full_width ? true : false;
  const content = slice.primary?.content ? (
    <PrismicRichText field={slice.primary.content.richText} />
  ) : (
    <></>
  );
  const btnTitle = slice.primary?.button_title
    ? slice.primary.button_title
    : "View form";
  const btnColor = slice.primary?.button_type
    ? slice.primary.button_type
    : ButtonColours.primary;
  const hiddenFields: Record<string, string> = {};

  if (slice.items.length > 0) {
    slice.items.forEach((item: any) => {
      if (!item.hidden_field_name || !item.hidden_field_value) return;
      hiddenFields[item.hidden_field_name] = context
        ? employerTextHelper({ text: item.hidden_field_value, context })
        : item.hidden_field_value;
    });
  }

  if (variant === "fullPage") {
    const belowHeader = slice.primary?.is_above_the_fold;
    return (
      <PureFullPageTypeform
        $id={formId}
        belowHeader={belowHeader}
        hiddenFields={hiddenFields}
        {...props}
      />
    );
  }

  if (variant === "popup") {
    return (
      <PurePopupTypeform
        fullWidth={fullWidth}
        $id={formId}
        content={content}
        buttonTitle={btnTitle}
        buttonType={btnColor}
        hiddenFields={hiddenFields}
        {...props}
      />
    );
  }

  if (variant === "slide") {
    return (
      <PureSlideTypeform
        fullWidth={fullWidth}
        $id={formId}
        content={content}
        buttonTitle={btnTitle}
        buttonType={btnColor}
        hiddenFields={hiddenFields}
        {...props}
      />
    );
  }

  return (
    <PureStandardTypeform
      fullWidth={fullWidth}
      $id={formId}
      height={slice.primary.form_height ? slice.primary.form_height : 500}
      padding={slice.primary?.spacing ?? Padding.both}
      hiddenFields={hiddenFields}
      {...props}
    />
  );
};

export default TypeformEmbed;

export const query = graphql`
  fragment TypeformEmbedDefault on PrismicTypeformEmbedDefault {
    id
    variation
    slice_label
    slice_type
    primary {
      full_width
      form_height
      form_id
    }
    items {
      hidden_field_name
      hidden_field_value
    }
  }

  fragment TypeformEmbedPopup on PrismicTypeformEmbedPopup {
    id
    slice_label
    variation
    slice_type
    primary {
      full_width
      button_title
      button_type
      content {
        richText
      }
      form_id
    }
    items {
      hidden_field_name
      hidden_field_value
    }
  }

  fragment TypeformEmbedSlide on PrismicTypeformEmbedSlide {
    id
    slice_label
    variation
    slice_type
    primary {
      full_width
      button_title
      button_type
      content {
        richText
      }
      form_id
    }
    items {
      hidden_field_name
      hidden_field_value
    }
  }

  fragment TypeformEmbedFullPage on PrismicTypeformEmbedFullPage {
    id
    slice_label
    variation
    slice_type
    primary {
      form_id
      is_above_the_fold
    }
    items {
      hidden_field_name
      hidden_field_value
    }
  }
`;
