import React from "react";
import { SliceFscs } from "../../../design-system/Slices/SliceFscs/SliceFscs";
import { PrismicRichText } from "@prismicio/react";

export interface FscsProps {
  slice: any;
}

export function Fscs({ slice, ...props }: FscsProps) {
  return (
    <SliceFscs
      content={
        slice.primary.content ? (
          <PrismicRichText field={slice.primary.content.richText} />
        ) : (
          <></>
        )
      }
      {...props}
    />
  );
}
