import React from "react";
import { colors } from "../../../theme/theme";
import { Button, ButtonProps } from "@mui/material";
import styled, { css } from "styled-components";
import { ButtonColours, ButtonColour } from "../../_shared";

export enum ButtonSize {
  extraSmall = "extraSmall",
  small = "small",
  normal = "normal",
  large = "large",
  header = "header",
}

const buttonSizeLookup = {
  desktop: {
    [ButtonSize.extraSmall]: {
      height: "24px",
      fontSize: "0.625rem",
    },
    [ButtonSize.small]: {
      height: "32px",
      fontSize: "0.75rem",
    },
    [ButtonSize.normal]: {
      height: "38px",
      fontSize: "0.8125rem",
    },
    [ButtonSize.large]: {
      height: "44px",
      fontSize: "1rem",
    },
    [ButtonSize.header]: {
      height: "32px",
      fontSize: "0.75rem",
    },
  },
  mobile: {
    [ButtonSize.extraSmall]: {
      height: "28px",
      fontSize: "0.75rem",
    },
    [ButtonSize.small]: {
      height: "34px",
      fontSize: "0.75rem",
    },
    [ButtonSize.normal]: {
      height: "40px",
      fontSize: "0.8125rem",
    },
    [ButtonSize.large]: {
      height: "44px",
      fontSize: "1rem",
    },
    [ButtonSize.header]: {
      height: "28px",
      fontSize: "0.75rem",
    },
  },
};

const buttonColourLookup = {
  [ButtonColours.primary]: {
    colour: colors.white,
    background: colors.magentaSafe,
  },
  [ButtonColours.secondary]: {
    colour: colors.white,
    background: colors.richBlue,
  },
  [ButtonColours.magenta]: {
    colour: colors.white,
    background: colors.magentaSafe,
  },
  [ButtonColours.richBlue]: {
    colour: colors.white,
    background: colors.richBlue,
  },
  [ButtonColours.seaBlue]: {
    colour: colors.white,
    background: colors.seaBlue,
  },
  [ButtonColours.purple]: { colour: colors.white, background: colors.purple },
  [ButtonColours.mauve]: { colour: colors.white, background: colors.mauve },
  [ButtonColours.richBlack]: {
    colour: colors.white,
    background: colors.richBlack,
  },
  [ButtonColours.white]: {
    colour: colors.richBlack,
    background: colors.white,
  },
  [ButtonColours.light]: {
    colour: colors.richBlack,
    background: colors.clouds,
  },
};

const outlinedWhiteMixin = css`
  background-color: transparent;
  color: ${buttonColourLookup.white.background};
  border: 1px solid;
  border-color: ${buttonColourLookup.white.background};
  &:hover,
  &.active {
    background-color: transparent;
    color: ${buttonColourLookup.white.background};
    border-color: ${buttonColourLookup.white.background};
  }
  &[disabled] {
    color: ${buttonColourLookup.white.background};
    border-color: ${buttonColourLookup.white.background};
  }
`;

const containedWhiteMixin = css`
  color: ${buttonColourLookup.white.colour};
  background-color: ${buttonColourLookup.white.background};
  &:hover,
  &.active {
    color: ${buttonColourLookup.white.colour};
    background-color: ${buttonColourLookup.white.background};
  }
  &[disabled] {
    color: ${buttonColourLookup.white.colour};
    background-color: ${buttonColourLookup.white.background};
  }
`;

type CustomButtonProps = {
  $size?: keyof typeof ButtonSize;
  isWide?: boolean;
  noHover?: boolean;
  $color?: ButtonColour;
} & ButtonProps;

/**
 *  Custom Button V2 Styles
 *  How to use?
 *
 * Import this file instead of the Button from MUI and pass all usual
 * props to it, to change the look of the button just add in a className
 * to it to extend it's appearance.
 * @property {string} $size - controls the height and padding ('extraSmall', 'small', 'normal', 'large')
 * @property {boolean} isWide - sets width to 17.5rem on desktop
 * @property {boolean} noHover - controls whether the y axis transform fires on desktop
 * @property {string} $color - controls colour of button ('primary', 'secondary', 'purple', etc)
 */
export const CustomButton = styled(Button)<CustomButtonProps>`
  padding: 0 ${({ $size }) => ($size === "header" ? "0.875rem" : "1.75rem")};
  width: 100%;
  border-radius: 5px;
  transform: translateY(0);
  transition: transform 0.25s ease-in-out;
  box-shadow: none;
  display: inline-grid;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;

  ${(p) => p.theme.breakpoints.up("sm")} {
    padding: 0 1.75rem;
  }

  &:hover {
    ${({ theme }) => theme.breakpoints.up("lg")} {
      transform: ${({ noHover }) => (noHover ? "none" : "translateY(-5px)")};
    }
  }

  ${({ $size }) =>
    $size
      ? css`
          height: ${buttonSizeLookup.mobile[$size].height};
          font-size: ${buttonSizeLookup.mobile[$size].fontSize};
        `
      : css`
          height: ${buttonSizeLookup.mobile[ButtonSize.normal].height};
          font-size: ${buttonSizeLookup.mobile[ButtonSize.normal].fontSize};
        `};

  ${(p) => p.theme.breakpoints.up("sm")} {
    width: auto;
    min-width: ${({ isWide }) => (isWide ? "17.5rem" : "auto")};
    ${({ $size }) =>
      $size
        ? css`
            height: ${buttonSizeLookup.desktop[$size].height};
            font-size: ${buttonSizeLookup.desktop[$size].fontSize};
          `
        : css`
            height: ${buttonSizeLookup.desktop[ButtonSize.normal].height};
            font-size: ${buttonSizeLookup.desktop[ButtonSize.normal].fontSize};
          `};
  }

  ${({ $color, variant }) =>
    $color
      ? css`
          &:hover,
          &.active {
            color: ${buttonColourLookup[$color].colour};
            background-color: ${buttonColourLookup[$color].background};
            box-shadow: none;
          }

          ${variant === "outlined"
            ? css`
                background-color: transparent;
                color: ${buttonColourLookup[$color].background};
                border: 1px solid;
                border-color: ${buttonColourLookup[$color].background};
                &:hover,
                &.active {
                  background-color: transparent;
                  color: ${buttonColourLookup[$color].background};
                  border-color: ${buttonColourLookup[$color].background};
                }
                &[disabled] {
                  color: ${buttonColourLookup[$color].background};
                  border-color: ${buttonColourLookup[$color].background};
                }
              `
            : css`
                color: ${buttonColourLookup[$color].colour};
                background-color: ${buttonColourLookup[$color].background};
                &[disabled] {
                  color: ${buttonColourLookup[$color].colour};
                  background-color: ${buttonColourLookup[$color].background};
                }
              `}
        `
      : css`
          &:hover,
          &.active {
            color: ${buttonColourLookup[ButtonColours.primary].colour};
            background-color: ${buttonColourLookup[ButtonColours.primary]
              .background};
            box-shadow: none;
          }

          ${variant === "outlined"
            ? css`
                background-color: transparent;
                color: ${buttonColourLookup[ButtonColours.richBlack]
                  .background};
                border-color: ${buttonColourLookup[ButtonColours.richBlack]
                  .background};
              `
            : css`
                color: ${buttonColourLookup[ButtonColours.primary].colour};
                background-color: ${buttonColourLookup[ButtonColours.primary]
                  .background};
              `}
        `};

  ${({ theme, $color }) =>
    theme.theme.page === "dark" &&
    $color === ButtonColours.secondary &&
    css`
      ${outlinedWhiteMixin}
    `}
  ${({ theme, $color }) =>
    theme.theme.page === "dark" &&
    $color === ButtonColours.richBlack &&
    css`
      ${containedWhiteMixin}
    `}
`;

CustomButton.defaultProps = {
  $size: ButtonSize.normal,
  isWide: false,
  noHover: false,
  $color: ButtonColours.primary,
};

export interface PrismicioButtonProps extends CustomButtonProps {
  $type: string;
  children: JSX.Element | string;
}

export function PrismicioButton({
  $type,
  children,
  ...props
}: PrismicioButtonProps) {
  return (
    <CustomButton $color={$type as ButtonColour} {...props}>
      {children}
    </CustomButton>
  );
}
