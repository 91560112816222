import styled, { css } from "styled-components";

export const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.75rem 0;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: start;
    justify-items: start;
    gap: 0 7.5rem;
  }
`;

const baseVideoStyles = css`
  width: 100%;
  border-radius: 10px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 100%;
    order: 2;
  }
`;

const BaseVideoShadows = css`
  ${({ theme }) =>
    theme.theme.page === "light" &&
    css`
      filter: drop-shadow(-15.6px -15.6px 31.2px #fafbff)
        drop-shadow(5.2px 7.8px 15.6px rgba(166, 171, 189, 0.25));
    `};

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ theme }) =>
      theme.theme.page === "light" &&
      css`
        filter: drop-shadow(10px 15px 30px rgba(166, 171, 189, 0.25));
      `}
  }
`;

export interface VideoProps {
  $shadows: boolean;
}

export const StyledVideo = styled.video<VideoProps>`
  ${baseVideoStyles};
  ${({ $shadows }) => $shadows && BaseVideoShadows}
`;

export const ImagePlaceholderContainer = styled.div`
  width: auto;
  display: none;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: inline-block;
    width: 100%;
    order: 2;
  }
`;

export interface ContentContainerProps {
  $order: 1 | 3;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: left;
    order: ${({ $order }) => $order};
  }

  p.block-img {
    display: inline-block;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
    a {
      &:after,
      &:before {
        display: none;
      }
    }
  }
`;

ContentContainer.defaultProps = {
  $order: 1,
};
