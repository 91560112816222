import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import {
  ContentContainer,
  StyledDot,
  TimeLineItem,
  TimeLineRail,
  StyledTimeLineRow,
  TimeLineSection,
  StyledFilledTimeLineRail,
} from "./SliceTimeline.styles";
import { Container, Padding } from "../../Container/Container";
import { useIntersectionObserver } from "usehooks-ts";

interface PureTimelineProps {
  children: React.ReactNode | JSX.Element | String;
  callToActions?: React.ReactNode | JSX.Element | String;
  rows: React.ReactNode[] | JSX.Element[] | String[];
}

export function PureTimeline({
  children,
  callToActions,
  rows,
}: PureTimelineProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container
      padding={Padding.both}
      className={isDark ? "dark-background" : ""}
    >
      {children && <ContentContainer>{children}</ContentContainer>}

      {rows && (
        <TimeLineSection>
          <TimeLineRail />
          {rows.map((row, i) => {
            return <TimeLineRow key={i} row={row} index={i + 1} />;
          })}
        </TimeLineSection>
      )}

      {callToActions && <ContentContainer>{callToActions}</ContentContainer>}
    </Container>
  );
}

const buildThresholdList = () => {
  const thresholds = [0];
  const numSteps = 100;

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  return thresholds;
};

interface TimeLineRowProps {
  row: String | JSX.Element | React.ReactNode;
  index: number;
}

function TimeLineRow({ row, index }: TimeLineRowProps) {
  const [hasIntersected, setHasIntersected] = useState(false);
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: buildThresholdList(),
    rootMargin: "50% 0% -50% 0%",
  });

  const height = hasIntersected
    ? "100%"
    : entry?.intersectionRatio
    ? `${entry.intersectionRatio * 100}%`
    : "0%";

  useEffect(() => {
    if (entry?.isIntersecting) {
      setActive(true);
    }
    if (entry?.intersectionRatio && entry.intersectionRatio >= 0.99) {
      setHasIntersected(true);
    }
  }, [entry?.isIntersecting, entry?.intersectionRatio]);

  return (
    <StyledTimeLineRow ref={ref}>
      <TimeLineItem>{row}</TimeLineItem>
      <StyledDot active={active}>{index}</StyledDot>
      <StyledFilledTimeLineRail style={{ height }} />
    </StyledTimeLineRow>
  );
}
