import React from "react";
import { useTheme } from "styled-components";
import {
  ContainerInner,
  CalculatorContainer,
  ContentContainer,
} from "./FeeCalculator.styles";
import { Container } from "../../Container/Container";
import { FeeCalculator } from "../../FeeCalculator/FeeCalculator";

export interface PureFeeCalculatorProps {
  children: JSX.Element | React.ReactNode | string;
  layout: "left" | "right";
}

export function PureFeeCalculator({
  children,
  layout,
  ...props
}: PureFeeCalculatorProps) {
  const layoutOrder = layout === "left" ? 3 : 1;
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container className={isDark ? "dark-background" : ""} {...props}>
      <ContainerInner>
        <CalculatorContainer>
          <FeeCalculator />
        </CalculatorContainer>
        <ContentContainer $order={layoutOrder}>{children}</ContentContainer>
      </ContainerInner>
    </Container>
  );
}
