import React from "react";
import { useTheme } from "styled-components";
import {
  ContainerInner,
  ContentContainer,
  ImagePlaceholderContainer,
  StyledVideo,
} from "./SliceTextVideo.styles";
import { Container } from "../../Container/Container";
import {
  ImageOrder,
  ImageOrdering,
  TextVideoLayout,
  TextVideoLayouts,
} from "../../../_shared";
import { useBreakpointMdDown } from "../../../../hooks/useBreakpoint";

export interface PureTextVideoProps {
  children: JSX.Element | React.ReactNode | string;
  videoUrl?: string;
  videoPoster?: string;
  mobVideoOrder?: ImageOrdering;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  withShadows?: boolean;
  layout: TextVideoLayouts;
}

export function PureTextVideo({
  children,
  layout,
  mobVideoOrder,
  videoUrl,
  videoPoster,
  autoPlay = true,
  loop = true,
  muted = true,
  withShadows = false,
  ...props
}: PureTextVideoProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";
  const layoutOrder = layout === TextVideoLayout.textVideo ? 1 : 3;
  const mobVidOrder = mobVideoOrder || (layoutOrder === 1 ? "bottom" : "top");
  const video = videoUrl ? (
    <StyledVideo
      muted={muted}
      playsInline
      autoPlay={autoPlay}
      loop={loop}
      poster={videoPoster}
      $shadows={withShadows}
    >
      <source src={videoUrl} type="video/mp4" />
    </StyledVideo>
  ) : (
    <ImagePlaceholderContainer />
  );

  return (
    <Container className={isDark ? "dark-background" : ""} {...props}>
      <ContainerInner>
        {mobVidOrder === ImageOrder.top && video}
        <ContentContainer $order={layoutOrder}>{children}</ContentContainer>
        {mobVidOrder === ImageOrder.bottom && video}
      </ContainerInner>
    </Container>
  );
}
