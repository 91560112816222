import React from "react";
import { AssetEligibilityTable } from "./assetEligibilityTable/AssetEligibilityTable";
import { DarkVsTillitUniverseTable } from "./darkVsTillitUniverseTable/DarkVsTillitUniverseTable";
import { FeeCalculator } from "./feeCalculator/FeeCalculator";
import { Fscs } from "./fscs/Fscs";
import { TransferConcierge } from "./transferConcierge/TransferConcierge";
import { UniversePerformance } from "./universePerformance/UniversePerformance";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedSlicesSlice} FeaturedSlicesSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedSlicesSlice>} FeaturedSlicesProps
 * @param { FeaturedSlicesProps }
 */
export interface FeaturedSlicesProps {
  slice: any;
  context?: any;
}

const FeaturedSlices = ({ slice, context, ...props }: FeaturedSlicesProps) => {
  const variant = slice.variation;

  switch (variant) {
    case "fscs":
      return <Fscs slice={slice} />;
    case "feeCalculator":
      return <FeeCalculator slice={slice} />;
    case "darkVsTillitUniverseTable":
      return <DarkVsTillitUniverseTable slice={slice} />;
    case "assetEligibilityTable":
      return <AssetEligibilityTable slice={slice} />;
    case "transferConcierge":
      return <TransferConcierge slice={slice} context={context} />;
    case "universePerformance":
      return <UniversePerformance slice={slice} context={context} />;
    default:
      return <div {...props}></div>;
  }
};

export default FeaturedSlices;
