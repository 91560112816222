import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import {
  PureDualTestimonialSlice,
  PureSingleTestimonialSlice,
  PureSliderSliceTestimonials,
} from "../../design-system/Slices/SliceTestimonials/SliceTestimonials";
import SliceFooterActions from "../SliceFooterActions";
import { QuotePrismicRichText } from "../../design-system/Slices/_shared/Text/Text";
import { QuoteTextSize, QuoteTextSizes } from "../../_shared";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";

/**
 * @typedef {import("@prismicio/client").Content.TestimonialsSlice} TestimonialsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TestimonialsSlice>} TestimonialsProps
 * @param { TestimonialsProps }
 */
export interface TestimonialsProps {
  slice: any;
  context?: any;
}

const Testimonials = ({ slice, context, ...props }: TestimonialsProps) => {
  const variant = slice.variation;
  const testimonials = [];

  if (variant === "single") {
    const name = slice.primary?.testimonial?.document.data.full_name || "";
    const testimonial =
      slice.primary?.testimonial?.document.data.testimonial.richText || "";
    const hasActions =
      slice.primary.cta_options.document &&
      slice.primary.cta_options.document.data.slices.length > 0;
    return (
      <PureSingleTestimonialSlice
        id="testimonials"
        testimonial={
          <QuotePrismicRichText
            field={testimonial}
            paragraphClassName={QuoteTextSize.quote}
          />
        }
        name={name}
        actions={
          hasActions && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align="center"
              context={context}
            />
          )
        }
        content={
          <RiskWarningAndDisclosures
            riskWarning={slice.primary.risk_warning}
            content={slice.primary.below_cta_content?.richText || null}
            context={context}
          />
        }
        {...props}
      />
    );
  }

  for (let singleItem of slice.items) {
    const testimonial = singleItem.testimonial.document.data;
    testimonials.push({
      id: singleItem.id,
      testimonial: (
        <QuotePrismicRichText
          paragraphClassName={QuoteTextSize.smallQuote as QuoteTextSizes}
          field={testimonial.testimonial.richText}
        />
      ),
      name: testimonial.full_name,
      tag: testimonial.tag || undefined,
      rating: testimonial.testimonial_rating || 0,
    });
  }

  if (variant === "default") {
    const content = <PrismicRichText field={slice.primary.content.richText} />;
    return (
      <PureSliderSliceTestimonials
        content={content}
        testimonials={testimonials}
        {...props}
      />
    );
  }

  return <PureDualTestimonialSlice testimonials={testimonials} {...props} />;
};

export default Testimonials;

export const query = graphql`
  fragment TestimonialsDefault on PrismicTestimonialsDefault {
    id
    variation
    slice_type
    slice_label
    primary {
      content {
        richText
      }
    }
    items {
      testimonial {
        id
        uid
        document {
          ...testimonialType
        }
      }
    }
  }
  fragment TestimonialsSingle on PrismicTestimonialsSingle {
    id
    slice_label
    slice_type
    variation
    primary {
      cta_options {
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
      testimonial {
        id
        uid
        document {
          ...testimonialType
        }
      }
    }
  }
  fragment TestimonialsDual on PrismicTestimonialsDual {
    id
    slice_label
    slice_type
    variation
    items {
      testimonial {
        id
        uid
        document {
          ...testimonialType
        }
      }
    }
  }
`;
