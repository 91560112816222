import React from "react";
import { useTheme } from "styled-components";
import { HeroProps } from "../_shared";
import { StyledHeroContainer } from "../Hero.styles";
import { Padding } from "../../design-system/Container/Container";
import {
  Background,
  Title,
  ContentContainer,
  Content,
} from "./TubeHero.styles";

export interface TubeHeroProps extends HeroProps {}

export function TubeHero({
  title,
  content,
  callToActions,
  belowCTA,
  ...props
}: TubeHeroProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <StyledHeroContainer
      fullWidth
      padding={Padding.none}
      $fullHeight={true}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <Background />
      <ContentContainer>
        <Title>{title}</Title>
        <Content>{content}</Content>
        {callToActions}
        {belowCTA}
      </ContentContainer>
    </StyledHeroContainer>
  );
}
