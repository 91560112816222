import styled from "styled-components";
import { Container } from "../../Container/Container";

export const CollectionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CollectionGrid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 0 0 3.5rem 0;
`;
