import React from "react";
import {
  ImageOrder,
  ImageOrdering,
  TextImageLayout,
  TextImageLayouts,
} from "../../../_shared";
import { Container } from "../../Container/Container";
import {
  ContainerInner,
  ContentContainer,
  ImagePlaceholderContainer,
  SectionLink,
} from "./FeaturedInTheNews.styles";
import { useTheme } from "styled-components";

export interface FeaturedInTheNewsProps {
  children: JSX.Element | React.ReactNode | string;
  image: JSX.Element | React.ReactNode | string;
  url: string;
  openInNewTab?: boolean;
  mobImgOrder?: ImageOrdering;
  layout: TextImageLayouts;
}

export function FeaturedInTheNews({
  children,
  mobImgOrder,
  image,
  url,
  openInNewTab = true,
  layout,
  ...props
}: FeaturedInTheNewsProps) {
  const layoutOrder = layout === TextImageLayout.textImage ? 1 : 3;
  const imageMobOrder = mobImgOrder || (layoutOrder === 1 ? "bottom" : "top");
  const img = image ? image : <ImagePlaceholderContainer />;
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <SectionLink href={url} target={openInNewTab ? "_blank" : "_self"}>
      <Container className={isDark ? "dark-background" : ""} {...props}>
        <ContainerInner>
          {imageMobOrder === ImageOrder.top && img}
          <ContentContainer $order={layoutOrder}>{children}</ContentContainer>
          {imageMobOrder === ImageOrder.bottom && img}
        </ContainerInner>
      </Container>
    </SectionLink>
  );
}
