import styled, { css } from "styled-components";
import { colors, sizes } from "../../../../theme/theme";
import { TextAlignment } from "../../../_shared";
import { Container } from "../../Container/Container";
import { Text } from "../_shared/Text/Text";

export interface TextOnImageInnerProps {
  $bgColor: string;
  $minHeight?: string;
  $align: TextAlignment.left | TextAlignment.center;
  $bgImage?: string;
  $mobBgImage?: string;
}

export interface TextOnImageProps extends TextOnImageInnerProps {}

export const StyledTextOnImageContainer = styled(Container)<TextOnImageProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  min-height: ${({ $minHeight }) => $minHeight || "0px"};
  color: ${colors.white};
  text-align: center;
  background-image: url("${({ $mobBgImage }) => $mobBgImage}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ $minHeight }) =>
    $minHeight &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    align-items: center;
    text-align: ${({ $align }) => $align};
    background-image: url("${({ $bgImage }) => $bgImage}");
    justify-content: ${({ $align }) =>
      $align === TextAlignment.left ? "flex-start" : "center"};
  }
`;

export const TextOnImageInner = styled.div<TextOnImageInnerProps>`
  background-color: ${({ $bgColor }) => $bgColor};
  min-height: ${({ $minHeight }) => $minHeight || "0px"};
  padding: 2rem 1.25rem;
  text-align: center;
  border-radius: 20px;
  background-image: url("${({ $mobBgImage }) => $mobBgImage}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    align-items: center;
    padding: 4rem;
    text-align: ${({ $align }) => $align};
    ${({ theme }) =>
      theme.theme.page === "light" &&
      css`
        filter: drop-shadow(${({ theme }) => theme.shadows.normal});
      `};
    background-image: url("${({ $bgImage }) => $bgImage}");
    justify-content: ${({ $align }) =>
      $align === TextAlignment.left ? "flex-start" : "center"};
  }
`;

export interface ContentContainerProps {
  $align: TextAlignment.left | TextAlignment.center;
  $darkBackground: boolean;
  $width?: string;
}

export const ContentContainer = styled(Text)<ContentContainerProps>`
  width: 100%;
  color: ${({ $darkBackground }) =>
    $darkBackground ? colors.white : colors.richBlack};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ $darkBackground }) =>
      $darkBackground ? colors.white : colors.richBlue};
  }

  p,
  i,
  strong,
  span,
  li {
    color: ${({ $darkBackground }) =>
      $darkBackground ? colors.white : colors.richBlack};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: ${({ $width }) => $width || sizes.sm};
  }
`;
