import React from "react";
import { RichTextField } from "@prismicio/types";
import { Text } from "../_shared/Text/Text";
import { RiskWarningCopy } from "../../../_shared";
import styled from "styled-components";
import CustomRichText from "../../../CustomRichText";

type paddingType = "top" | "bottom" | "both" | "none";

interface StyledContainerProps {
  $pad: paddingType;
}

const StyledContainer = styled.div<StyledContainerProps>`
  padding: ${({ $pad }) => ($pad === "top" || $pad === "both" ? "0.75rem" : 0)}
    0 ${({ $pad }) => ($pad === "bottom" || $pad === "both" ? "0.75rem" : 0)} 0;
`;

export interface RiskWarningAndDisclosuresProps {
  riskWarning?: string;
  content?: RichTextField;
  $pad?: paddingType;
  context?: any;
}

export function RiskWarningAndDisclosures({
  riskWarning,
  content,
  $pad = "none",
  context,
}: RiskWarningAndDisclosuresProps) {
  return (
    <StyledContainer $pad={$pad}>
      {riskWarning && (
        <Text>
          <p>{(RiskWarningCopy as { [index: string]: string })[riskWarning]}</p>
        </Text>
      )}
      <CustomRichText field={content} context={context} />
    </StyledContainer>
  );
}
