import React from "react";
import { useTheme } from "styled-components";
import {
  TransferConciergeContainer,
  TransferConciergeInner,
  ContentContainer,
  TestimonialCopy,
  SliderTestimonialInner,
} from "./SliceTransferConcierge.styles";
import { Padding } from "../../Container/Container";
import { ContentAlignment, TestimonialProps } from "../../../_shared";
import { Box } from "../../Box/Box";
import "swiper/css";
import "swiper/css/navigation";
import { Keyboard, Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";

export interface PureTransferConciergeProps {
  children: JSX.Element | React.ReactNode | string;
  testimonials: TestimonialProps[];
}

export function PureTransferConcierge({
  testimonials,
  children,
  ...props
}: PureTransferConciergeProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";
  const isBreakpointMdUp = useBreakpointMdUp();

  return (
    <TransferConciergeContainer
      padding={Padding.horizontal}
      fullWidth
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <TransferConciergeInner>
        <ContentContainer>{children}</ContentContainer>
        <SliderTestimonialInner>
          <Swiper
            watchOverflow
            slidesPerView={1}
            centeredSlides={true}
            navigation={isBreakpointMdUp}
            loop={true}
            speed={1500}
            spaceBetween={48}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            pagination={
              isBreakpointMdUp
                ? undefined
                : {
                    dynamicBullets: true,
                    dynamicMainBullets: 1,
                  }
            }
            keyboard={{
              enabled: true,
            }}
            modules={[Autoplay, Navigation, Keyboard, Pagination]}
          >
            {testimonials.map((testimonial, index) => {
              return (
                <SwiperSlide key={testimonial.id}>
                  <Box $align={ContentAlignment.left} shadow="small">
                    <TestimonialCopy>{testimonial.testimonial}</TestimonialCopy>
                    <p>
                      <strong>{testimonial.name}</strong>
                      {testimonial.tag && (
                        <>
                          <br />
                          <i>{testimonial.tag}</i>
                        </>
                      )}
                    </p>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SliderTestimonialInner>
      </TransferConciergeInner>
    </TransferConciergeContainer>
  );
}
