import React, { useState } from "react";
import {
  AccordionWrapper,
  Question,
  Chevron,
  Plus,
  Answer,
} from "./Accordion.styles";
import { H5, H6 } from "../Typography/headings/Headings";
import { useBreakpointMdDown } from "../../../hooks/useBreakpoint";

export enum AccordionIconType {
  chevron = "chevron",
  plus = "plus",
}

export interface AccordionProps {
  question: string;
  children: JSX.Element | React.ReactNode;
  iconType?: keyof typeof AccordionIconType;
}

export function Accordion({
  question,
  children,
  iconType = AccordionIconType.chevron,
  ...props
}: AccordionProps) {
  const [collapsed, setCollapsed] = useState(true);
  const isMobile = useBreakpointMdDown();

  return (
    <AccordionWrapper {...props}>
      <Question
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        {isMobile ? <H6>{question}</H6> : <H5>{question}</H5>}
        {iconType === AccordionIconType.chevron ? (
          <Chevron collapsed={collapsed} />
        ) : (
          <Plus collapsed={collapsed} />
        )}
      </Question>
      <Answer collapsed={collapsed}>{children}</Answer>
    </AccordionWrapper>
  );
}
