import { transparentize } from "polished";
import styled from "styled-components";
import { colors } from "../../../../theme/theme";
import { Box } from "../../Box/Box";

export const StyledBox = styled(Box)`
  border: 1px solid
    ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.65, colors.purple)
        : colors.clouds};
  background-color: ${({ theme }) =>
    theme.theme.page === "dark"
      ? transparentize(0.9, colors.purple)
      : colors.white};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Head = styled.thead`
  border-bottom: 1px
    ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.65, colors.white)
        : colors.grey}
    solid;
`;

export const Row = styled.tr`
  border-bottom: 1px
    ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.85, colors.white)
        : colors.clouds}
    solid;

  :last-child {
    border-bottom: 0;
  }
`;

export interface ThProps {
  $width?: string;
  $align?: string;
}

export const TH = styled.th<ThProps>`
  width: ${({ $width }) => $width ?? "auto"};
  text-align: ${({ $align }) => $align ?? "right"};
  margin: 0;
  padding: 1.5rem 0;
  font-weight: 400;
`;

export interface TdProps {
  $align?: string;
}

export const TD = styled.td<TdProps>`
  text-align: ${({ $align }) => $align ?? "right"};
  padding: 1rem 0;
`;
