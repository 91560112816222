import styled, { css } from "styled-components";
import { FAQSliceType } from "./SliceFaqs";
import { transparentize } from "polished";
import { colors } from "../../../../theme/theme";

interface FaqsContainerProps {
  variant?: keyof typeof FAQSliceType;
}

export const FaqsContainer = styled.div<FaqsContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  justify-items: center;
  grid-gap: 1rem;

  ${({ variant }) =>
    variant === "contained" &&
    css`
      ${({ theme }) => theme.breakpoints.up("md")} {
        box-shadow: ${({ theme }) => theme.shadows.normal};
        background-color: ${({ theme }) =>
          theme.theme.page === "dark"
            ? transparentize(0.98, colors.white)
            : colors.white};
        border: ${({ theme }) =>
            theme.theme.page === "dark"
              ? transparentize(0.95, colors.clouds)
              : colors.clouds}
          solid 1px;
        border-radius: 20px;
        padding: 6rem 5.25rem;
      }
    `}
`;

export const FaqText = styled.div<FaqsContainerProps>`
  width: 100%;
  text-align: center;
  max-width: 650px;
  padding: 0 0 1.25rem 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: ${({ variant }) =>
      variant === "default" ? "0 0 3.25rem 0" : "0 0 1.25rem 0"};
  }
`;

export const FaqsWrapper = styled.div`
  width: 100%;
  max-width: 960px;
`;
