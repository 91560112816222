export enum BgImages {
  TRUST,
  TRUST_BG,
  PURPLE_TEXTURE,
  EMPOWER,
  OPEN,
  SIMPLE_BLURRED,
  SIMPLE_HEXAGON,
}

export const bgImageLookup = {
  [BgImages.TRUST]: "/static/backgrounds/slice-on-text-bg.png",
  [BgImages.TRUST_BG]: "/static/backgrounds/trust-background.png",
  [BgImages.PURPLE_TEXTURE]: "/static/backgrounds/purple-texture.png",
  [BgImages.EMPOWER]: "/static/backgrounds/empower-background.jpeg",
  [BgImages.OPEN]: "/static/backgrounds/open-background.png",
  [BgImages.SIMPLE_BLURRED]: "/static/backgrounds/simple-blurred.png",
  [BgImages.SIMPLE_HEXAGON]: "/static/backgrounds/simple-hexagon_wide.jpg",
};

export const bgImageMobileLookup = {
  [BgImages.TRUST]: "/static/backgrounds/slice-on-text-bg_mobile.jpg",
  [BgImages.TRUST_BG]: "/static/backgrounds/trust-background_mobile.jpg",
  [BgImages.PURPLE_TEXTURE]: "/static/backgrounds/purple-texture_mobile.jpg",
  [BgImages.EMPOWER]: "/static/backgrounds/empower-background.jpeg",
  [BgImages.OPEN]: "/static/backgrounds/open-background_mobile.jpg",
  [BgImages.SIMPLE_BLURRED]: "/static/backgrounds/simple-blurred.png",
  [BgImages.SIMPLE_HEXAGON]: "/static/backgrounds/simple-hexagon_mobile.jpg",
};

export enum FgImages {
  MONEY_AGE = "MONEY_AGE",
  BRITISH_BUSINESS_AWARDS = "BRITISH_BUSINESS_AWARDS",
}

export const fgImageLookup = {
  [FgImages.MONEY_AGE]: "/static/logos/money-age-awards-2022.png",
  [FgImages.BRITISH_BUSINESS_AWARDS]:
    "/static/logos/british-business-awards-2022.png",
};
