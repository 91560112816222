import React from "react";
import { useTheme } from "styled-components";
import {
  ContainerInner,
  ContentContainer,
  ImagePlaceholderContainer,
} from "./SliceTextImage.styles";
import { Container } from "../../Container/Container";
import {
  ImageOrder,
  ImageOrdering,
  TextImageLayout,
  TextImageLayouts,
} from "../../../_shared";

export interface PureTextImageProps {
  children: JSX.Element | React.ReactNode | string;
  image: JSX.Element | React.ReactNode | string;
  mobImgOrder?: ImageOrdering;
  layout: TextImageLayouts;
}

export function PureTextImage({
  children,
  layout,
  mobImgOrder,
  image,
  ...props
}: PureTextImageProps) {
  const layoutOrder = layout === TextImageLayout.textImage ? 1 : 3;
  const imageMobOrder = mobImgOrder || (layoutOrder === 1 ? "bottom" : "top");
  const img = image ? image : <ImagePlaceholderContainer />;
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container className={isDark ? "dark-background" : ""} {...props}>
      <ContainerInner>
        {imageMobOrder === ImageOrder.top && img}
        <ContentContainer $order={layoutOrder}>{children}</ContentContainer>
        {imageMobOrder === ImageOrder.bottom && img}
      </ContainerInner>
    </Container>
  );
}
