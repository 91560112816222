import React from "react";
import { useTheme } from "styled-components";
import { StyledHeroContainer } from "../Hero.styles";
import { Padding } from "../../design-system/Container/Container";
import {
  ImageHeroInner,
  ContentContainer,
  ContentInner,
  MediaContainer,
} from "./ImageHero.styles";
import {
  ImageOrder,
  ImageOrdering,
  ImagePosition,
  ImagePositions,
} from "../../_shared";
import { H1Xl } from "../../design-system/Typography/headings/Headings";

export interface ImageHeroProps {
  heading: string;
  children?: JSX.Element | React.ReactNode | string;
  image: JSX.Element | React.ReactNode | string;
  mobileImagePosition?: ImageOrdering;
  imagePosition: ImagePositions;
  fullHeight?: boolean;
}

export function ImageHero({
  heading,
  children,
  image,
  fullHeight = true,
  imagePosition = ImagePosition.left,
  mobileImagePosition,
  ...props
}: ImageHeroProps) {
  const defaultImageOrder = imagePosition === ImagePosition.left ? 1 : 3;
  const imageMobPosition = mobileImagePosition
    ? mobileImagePosition === ImageOrder.top
      ? 1
      : 3
    : defaultImageOrder;
  const contentAlign =
    imagePosition === ImagePosition.left ? "flex-start" : "flex-end";
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <StyledHeroContainer
      fullWidth
      padding={fullHeight ? Padding.horizontal : Padding.both}
      $fullHeight={fullHeight}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <ImageHeroInner>
        <ContentContainer $align={contentAlign}>
          <ContentInner>
            <H1Xl>{heading}</H1Xl>
            {children}
          </ContentInner>
        </ContentContainer>

        <MediaContainer
          $order={defaultImageOrder}
          $mobileOrder={imageMobPosition}
        >
          {image}
        </MediaContainer>
      </ImageHeroInner>
    </StyledHeroContainer>
  );
}
