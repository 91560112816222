import React from "react";
import { Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Container } from "../../Container/Container";
import {
  SliceContainer,
  InsightWrapper,
  ContentContainer,
  OuterContainer,
} from "./SliceFeaturedContentCarousel.styles";
import { FeaturedContentCarouselContent } from "../../../_shared";
import { FeaturedContentCard } from "../../FeaturedContentCard/FeaturedContentCard";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";

interface PureFeaturedContentCarouselProps {
  content: FeaturedContentCarouselContent[];
  withBullets?: boolean;
  withArrows?: boolean;
  loop?: boolean;
  initialSlide?: number;
  children: JSX.Element | React.ReactNode | string;
}

export function PureFeaturedContentCarousel({
  content,
  withBullets = false,
  withArrows = false,
  loop = false,
  initialSlide = 0,
  children,
  ...props
}: PureFeaturedContentCarouselProps) {
  const isMdUp = useBreakpointMdUp();
  return (
    <OuterContainer>
      <Container fullWidth={false} {...props}>
        <SliceContainer>
          {children && <ContentContainer>{children}</ContentContainer>}
          <InsightWrapper>
            <Swiper
              watchOverflow
              spaceBetween={32}
              navigation={withArrows && isMdUp}
              slidesPerView="auto"
              loop={loop}
              centeredSlides={true}
              initialSlide={initialSlide}
              breakpoints={{
                200: { slidesPerView: 1 },
                960: { slidesPerView: 2 },
                1280: { slidesPerView: 3 },
              }}
              pagination={withBullets ? { clickable: true } : false}
              keyboard={{
                enabled: true,
              }}
              modules={[Navigation, Keyboard, Pagination]}
            >
              {content.map((content) => (
                <SwiperSlide key={content.title}>
                  <FeaturedContentCard
                    subTitle={content.subTitle}
                    withDivider={content.withDivider}
                    title={content.title}
                    content={content.content}
                    leftInfo={content.leftInfo}
                    rightInfo={content.rightInfo}
                    link={content.link}
                    target={content.target}
                    image={content.image}
                    imageObjectFit={content.imageObjectFit}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </InsightWrapper>
        </SliceContainer>
      </Container>
    </OuterContainer>
  );
}
