import styled, { keyframes } from "styled-components";
import { colors } from "../../theme/theme";
import { Card, IconButton } from "@mui/material";

const slideInAnimation = keyframes`
  0% {
    transform: translateY(calc(100% + 6rem));
  }
  100% {
    transform: translateY(0);
  }
`;

export const ResumeOnboardingNudgeContainer = styled(Card)`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 1.25rem;
  border-radius: 20px;
  z-index: ${({ theme }) => theme.zIndex.popup};
  background-color: ${colors.richBlue};
  border-color: ${colors.richBlue};
  transform: translateY(calc(100% + 6rem));
  animation-name: ${slideInAnimation};
  animation-duration: 250ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 0.54, 0.58, 1.22);

  ${(p) => p.theme.breakpoints.up("md")} {
    width: auto;
    max-width: 30rem;
    right: 1.25rem;
    bottom: 1.25rem;
  }
`;

export const ResumeOnboardingNudgeHead = styled.div`
  display: grid;
  grid-template-columns: auto 3rem;
  gap: 1rem;
  margin-bottom: 1.25rem;
`;

export const ResumeOnboardingNudgeHeading = styled.h4`
  color: ${colors.white};
  margin-bottom: 0;
`;

export const ResumeOnboardingNudgeCopy = styled.p`
  color: ${colors.white};
`;

export const DismissButton = styled(IconButton)`
  padding: 0;
  color: ${colors.white};
`;
