import styled from "styled-components";
import { colors } from "../../../theme/theme";
import { transparentize } from "polished";

export const RangeInput = styled.input`
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  height: 4px;
  background: ${transparentize(0.85, colors.magenta)};
  border-radius: 5px;
  background-image: linear-gradient(${colors.magenta}, ${colors.magenta});
  background-size: 0;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
  }

  /* Input Thumb */

  &::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: ${colors.magenta};
    cursor: ew-resize;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: ${colors.magenta};
    cursor: ew-resize;
  }

  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000000;
    border: 0px solid #000000;
    height: 14px;
    width: 14px;
    border-radius: 7px;
    background: ${colors.magenta};
    cursor: ew-resize;
  }

  /* 
        &::-webkit-slider-thumb:hover {
            background: #ff0200;
        }

        &::-moz-range-thumb:hover {
            background: #ff0200;
        }

        &::-ms-thumb:hover {
            background: #ff0200;
        } */

  /* Input Track */

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: ${transparentize(0.85, colors.magenta)};
    border-radius: 2px;
    border: 0px solid #000000;
  }

  &:focus::-webkit-slider-runnable-track {
    background: ${transparentize(0.85, colors.magenta)};
  }

  &::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: ${transparentize(0.85, colors.magenta)};
    border-radius: 2px;
    border: 0px solid #000000;
  }

  &::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: ${colors.magenta};
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }
  &::-ms-fill-upper {
    background: ${transparentize(0.85, colors.magenta)};
    border: 0px solid #000000;
    border-radius: 4px;
    box-shadow: 0px 0px 0px #000000;
  }

  &:focus::-ms-fill-lower,
  &::-moz-range-progress {
    background: ${colors.magenta};
  }
  &:focus::-ms-fill-upper,
  &::-moz-range-track {
    background: ${transparentize(0.85, colors.magenta)};
  }
`;
