import { createGlobalStyle } from "styled-components";

export interface BodyOverflowProps {
  overflowHidden?: boolean;
}

export const BodyOverflow = createGlobalStyle<BodyOverflowProps>`
    body {
        overflow: ${(p) => (p.overflowHidden ? "hidden" : "auto")};
    }
`;
