import styled from "styled-components";
import { Container } from "../../Container/Container";
import { colors } from "../../../../theme/theme";
import { transparentize } from "polished";

export const TransferConciergeContainer = styled(Container)`
  background: ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  justify-content: center;
`;

export const TransferConciergeInner = styled.div`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  margin: 2rem 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: calc(42% - 3.5rem) calc(58% - 3.5rem);
    grid-template-rows: auto;
    align-items: center;
    justify-items: start;
    margin: 0;
    gap: 0 7rem;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: left;
  }
`;

export const TestimonialCopy = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 2.5rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 26px;
    height: 1px;
    background-color: ${({ theme }) =>
      theme.theme.page === "dark"
        ? colors.white
        : transparentize(0.75, colors.magenta)};
  }
`;

export const SliderTestimonialInner = styled.div`
  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 3.5rem 0;
  }
  width: 100%;

  .swiper {
    padding: 1rem 0 1.75rem;
    ${({ theme }) => theme.breakpoints.up("md")} {
      padding: 1rem 2rem;
    }

    .swiper-button-prev {
      color: ${({ theme }) =>
        theme.theme.page === "dark" ? colors.white : colors.richBlue};
      margin-top: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.lightGrey};
      &::after {
        font-size: 1.5rem;
      }
    }

    .swiper-button-next {
      color: ${({ theme }) =>
        theme.theme.page === "dark" ? colors.white : colors.richBlue};
      top: 0;
      right: 0;
      height: 100%;
      margin-top: 0;
      width: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.lightGrey};
      &::after {
        font-size: 1.5rem;
      }
    }

    .swiper-wrapper {
      align-items: center;
      .swiper-slide {
        display: flex;
        flex-direction: column;

        &.swiper-slide-active,
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 1;
        }
      }
    }

    .swiper-pagination {
      align-items: center;
      display: flex;
      height: 1rem;
      bottom: 0;

      &-lock {
        display: none;
      }

      .swiper-pagination-bullet {
        cursor: pointer;
        border-radius: 50%;
        width: 9px !important;
        height: 9px !important;
        margin: 0 4px !important;
        background-color: ${colors.richBlue};
        transition: transform 0.3s, background-color 0.3s;
        opacity: 1;

        &:focus {
          transform: scale(3);
        }

        &:hover {
          transform: scale(1.33);
        }

        &.swiper-pagination-bullet-active-prev,
        &.swiper-pagination-bullet-active-next {
          transform: scale(1);
        }

        &.swiper-pagination-bullet-active-prev-prev,
        &.swiper-pagination-bullet-active-next-next {
          transform: scale(0.55);
        }

        &.swiper-pagination-bullet-active {
          transform: scale(1.33);
          background-color: ${colors.magenta};
        }
      }
    }
  }
`;
