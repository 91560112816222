import React from "react";
import { Text } from "../Typography/Typography";
import {
  Container,
  Content,
  Footer,
  Hr,
  Image,
  ReadTime,
  FundName,
  FundNameWrapper,
  Author,
  Title,
} from "./FundCard.styles";
import { FeaturedFundsContent, ObjectFit } from "../../_shared";
import numeral from "numeral";

interface FundCardProps extends FeaturedFundsContent {}

export function FundCard({
  fundName,
  title,
  content,
  fundManager,
  ocf,
  link,
  image,
  imageObjectFit = ObjectFit.cover,
  ...props
}: FundCardProps) {
  return (
    <Container href={link} {...props}>
      {image && (
        <Image
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: `${imageObjectFit}`,
          }}
        />
      )}
      <FundNameWrapper>
        <FundName className="small">{fundName}</FundName>
      </FundNameWrapper>
      <Hr />
      <Title>{title}</Title>
      <Content>{content}</Content>
      <Footer>
        <Author className="small">
          <b>Manager: {fundManager}</b>
        </Author>
        {ocf && (
          <ReadTime className="small">
            OCF: {numeral(ocf).format("0.00")}%
          </ReadTime>
        )}
      </Footer>
    </Container>
  );
}
