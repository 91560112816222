import React, { useEffect, useState } from "react";
import {
  CalculatorBox,
  CalculatorInner,
  RangeContainer,
  Legend,
  Label,
  Fee,
  Year,
} from "./FeeCalculator.styles";
import { RangeField } from "../RangeField/RangeField";
import numeral from "numeral";

export interface FeeCalculatorProps {}

export function FeeCalculator({ ...props }: FeeCalculatorProps) {
  const basisPoints = 40;
  const [accountVal, setAccountVal] = useState(5000);
  const accountValMin = 100;
  const accountValMax = 100000;
  const [year, setYear] = useState(5);
  const [platformFee, setPlatformFee] = useState("0.35");
  const [feeAmount, setFeeAmount] = useState("0");

  const handleAccountValChange = (val: string) => {
    setAccountVal(parseInt(val));
  };

  const handleFeeChange = (val: string) => {
    setYear(parseInt(val));
  };

  useEffect(() => {
    const fee = (basisPoints - year) * 0.01;
    const roundedFee = (Math.round((fee + Number.EPSILON) * 100) / 100).toFixed(
      2
    );
    const calculatedFeeAmount = ((fee / 100) * accountVal).toFixed(2);
    setPlatformFee(roundedFee);
    setFeeAmount(calculatedFeeAmount.toString());
  }, [year, accountVal]);

  return (
    <CalculatorBox {...props}>
      <CalculatorInner>
        <h5>Fee calculator</h5>

        <RangeContainer>
          <Legend>
            <span>£{numeral(accountValMin).format("0,0")}</span>
            <span>£{numeral(accountValMax).format("0,0")}</span>
          </Legend>
          <RangeField
            id="accountValue"
            min={accountValMin}
            max={accountValMax}
            step={100}
            val={accountVal}
            onValChange={handleAccountValChange}
          />
          <Label htmlFor="accountValue">
            £{numeral(accountVal).format("0,0")}
            <br />
            <span>Account value</span>
          </Label>
        </RangeContainer>

        <RangeContainer>
          <Legend>
            <span>Year 0</span>
            <span>Year 15</span>
          </Legend>
          <RangeField
            id="platformFee"
            min={0}
            max={15}
            step={1}
            val={year}
            onValChange={handleFeeChange}
          />
          <Label htmlFor="platformFee">
            {platformFee}%<br />
            <span>Platform fee</span>
          </Label>
        </RangeContainer>

        <div>
          <Fee>£{feeAmount}</Fee>
          <Year>Year {year}</Year>
        </div>
      </CalculatorInner>
    </CalculatorBox>
  );
}
