import { useMediaQuery, useIsClient } from "usehooks-ts";
import { theme, handleSizes } from "../theme/theme";

// isClient is used to prevent hydration errors.
// useBreakpointMdUp will always return false on build - and will return true correctly on the
// client. This causes hydration errors as the server and client render different markup.
// useIsClient will return false initially, and then return true on the client - this avoids
// hydration errors.

export function useBreakpoint(breakpoint: string) {
  const isClient = useIsClient();

  return (
    useMediaQuery(theme.breakpoints.up(breakpoint).replace("@media ", "")) &&
    isClient
  );
}

export function useBreakpointMdUp() {
  return useBreakpoint("md");
}

export function useBreakpointSmUp() {
  return useBreakpoint("sm");
}

export function useBreakpointXsUp() {
  return useBreakpoint("xs");
}

export function useBreakpointMdDown() {
  const isBreakpointMdUp = useBreakpointMdUp();
  return !isBreakpointMdUp;
}

export function useBreakpointXsDown() {
  const isBreakpointXsUp = useBreakpointXsUp();
  return !isBreakpointXsUp;
}
