import React from "react";
import { useTheme } from "styled-components";
import { Padding } from "../../../Container/Container";
import {
  StyledContainer,
  ColumnContainer,
  ContentContainer,
} from "./ColumnsText.styles";
import {
  ColumnsLayout,
  TextAlignment,
  TextAlignments,
} from "../../../../_shared";

export interface PureColumnsTextProps {
  isWide: boolean;
  dividers: boolean;
  header: JSX.Element | React.ReactNode | string;
  columnsTextAlignment?: TextAlignments;
  columns: JSX.Element[] | React.ReactNode[] | string[];
}

export function PureColumnsText({
  isWide = true,
  dividers = true,
  header,
  columnsTextAlignment = TextAlignment.center,
  columns,
  ...props
}: PureColumnsTextProps) {
  const layout = () => {
    if (columns.length === 1) return ColumnsLayout.single;
    if (columns.length === 2) return ColumnsLayout.double;
    return ColumnsLayout.default;
  };

  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <StyledContainer
      fullWidth={isWide}
      padding={Padding.both}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <ContentContainer>{header}</ContentContainer>
      <ColumnContainer
        $align={columnsTextAlignment}
        $divider={dividers}
        $layout={layout()}
      >
        {columns.length > 0 &&
          columns.map((column: any, i: number) => <div key={i}>{column}</div>)}
      </ColumnContainer>
    </StyledContainer>
  );
}
