import styled, { css } from "styled-components";
import { Container } from "../../Container/Container";
import { colors } from "../../../../theme/theme";
import { transparentize } from "polished";
import { Text } from "../_shared/Text/Text";

export interface TextRowContainerProps {
  $align: "left" | "center";
  $mdUpAlign?: "left" | "center";
  $rowSize: string;
}

export const TextRowContainer = styled(Container)<TextRowContainerProps>`
  text-align: ${({ $align }) => $align};
  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ $mdUpAlign }) => ($mdUpAlign ? `text-align: ${$mdUpAlign}` : "")};
  }

  max-width: ${({ $rowSize }) => $rowSize};
`;

TextRowContainer.defaultProps = {
  $align: "center",
};

export interface TextRowInnerProps {
  $border: boolean;
}

export const TextRowInner = styled(Text)<TextRowInnerProps>`
  ${({ theme, $border }) =>
    $border &&
    css`
      border-top: 1px
        ${theme.theme.page === "dark"
          ? transparentize(0.85, colors.white)
          : colors.clouds}
        solid;
      border-bottom: 1px
        ${theme.theme.page === "dark"
          ? transparentize(0.85, colors.white)
          : colors.clouds}
        solid;
      padding: 2rem 0;
    `}
`;
