import React from "react";
import { useTheme } from "styled-components";
import { Container, Padding } from "../../Container/Container";
import {
  ContentContainer,
  Nav,
  StyledLink,
} from "./SliceInPageNavigation.styles";

export interface LinkProps {
  id: string;
  url: string;
  label: string;
}

export interface PureInPageNavigationProps {
  children: JSX.Element | React.ReactNode | string;
  links: Array<LinkProps>;
}

export function PureInPageNavigation({
  children,
  links,
  ...props
}: PureInPageNavigationProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container
      padding={Padding.both}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <ContentContainer>{children}</ContentContainer>
      <Nav>
        {links.map((link) => (
          <li key={link.id}>
            <h4>
              <StyledLink href={link.url}>{link.label}</StyledLink>
            </h4>
          </li>
        ))}
      </Nav>
    </Container>
  );
}
