import styled from "styled-components";
import { sizes } from "../../../theme/theme";

export const ImageHeroInner = styled.div`
  text-align: center;
  width: 100%;
  max-width: ${sizes.xxl};
  display: flex;
  flex-direction: column;
  gap: 1.75rem 0;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    gap: 0 7.5rem;
  }
`;

export interface ContentContainerProps {
  $align: "flex-start" | "flex-end";
}

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  order: 2;

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    margin-bottom: 0;
    display: flex;
    justify-content: ${({ $align }) => $align};
    align-items: center;
  }
`;

export const ContentInner = styled.div`
  max-width: ${sizes.sm};
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0;
    > div {
      justify-content: left;
    }
  }

  .block-img {
    img {
      width: auto;
    }
  }
`;

export interface MediaProps {
  $order: number;
  $mobileOrder?: number;
}

export const MediaContainer = styled.div<MediaProps>`
  position: relative;
  width: 100%;
  order: ${({ $mobileOrder }) => $mobileOrder || "3"};

  > * {
    max-width: unset;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    order: ${({ $order }) => $order};
    width: 50%;
  }
`;
