import styled, { css } from "styled-components";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const baseImgStyles = css`
  width: 100%;
  max-width: 75%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 100%;
    order: 2;
  }
`;

const BaseImgShadows = css`
  ${({ theme }) =>
    theme.theme.page === "light" &&
    css`
      filter: drop-shadow(-15.6px -15.6px 31.2px #fafbff)
        drop-shadow(5.2px 7.8px 15.6px rgba(166, 171, 189, 0.25));
    `};

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ theme }) =>
      theme.theme.page === "light" &&
      css`
        filter: drop-shadow(10px 15px 30px rgba(166, 171, 189, 0.25));
      `}
  }
`;

export interface ImageProps {
  $shadows: boolean;
  $rounded: boolean;
}

export const StyledGatsbyImage = styled(GatsbyImage)<ImageProps>`
  ${baseImgStyles}
  ${({ $shadows }) => $shadows && BaseImgShadows}
    ${({ $rounded }) => $rounded && `border-radius: 10px;`}
`;
export const StyledStaticImage = styled(StaticImage)<ImageProps>`
  ${baseImgStyles}
  ${({ $shadows }) => $shadows && BaseImgShadows}
    ${({ $rounded }) => $rounded && `border-radius: 10px;`}
`;
export const StyledImage = styled.img<ImageProps>`
  ${baseImgStyles}
  ${({ $shadows }) => $shadows && BaseImgShadows}
    ${({ $rounded }) => $rounded && `border-radius: 10px;`}
`;
