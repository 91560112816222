import React from "react";
import { RelativeHeader } from "../../layout/Header/Header";
import { graphql } from "gatsby";
import {
  HeaderActions,
  HeaderCustomNav,
  HeaderPosition,
} from "../../layout/Header/HeaderCustomNav";
import { CustomNavProps } from "../../layout/Header/Navigation";
import { createGlobalStyle } from "styled-components";

// When the header is position fixed we need to ensure that the content does not overlap the header,
// as we cannot user the HeaderShim here as we don't know the position of the slice
// this is the next best solution.
const FirstElementMargin = createGlobalStyle`
    body {
        padding-top: 4rem;
    }
`;

/**
 * @typedef {import("@prismicio/client").Content.HeaderSlice} HeaderSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<HeaderSlice>} HeaderProps
 * @param { HeaderProps }
 */

export interface HeaderProps {
  slice: any;
}

enum HeaderActionsLookup {
  "Sign up" = HeaderActions.signUp,
  "Sign up and login" = HeaderActions.static,
  "Dynamic" = HeaderActions.dynamic,
}

const getHeaderAction = (action: keyof typeof HeaderActionsLookup) =>
  HeaderActionsLookup[action] || null;

const Header = ({ slice, ...props }: HeaderProps) => {
  const variant = slice.variation;

  if (variant === "default") return <RelativeHeader {...props} />;

  const position =
    slice.primary.position === "Default"
      ? HeaderPosition.Relative
      : HeaderPosition.Fixed;
  const HeaderActions = getHeaderAction(slice.primary.header_actions) || null;
  const customNav: CustomNavProps[] = [];

  slice.items.forEach((item: any) => {
    customNav.push({
      label: item.nav_label,
      url: item.nav_internal_deep_link_slug || item.nav_external_url.url || "",
      target: item.nav_external_url.url
        ? item.nav_external_url.target
        : "_self",
    } as CustomNavProps);
  });

  return (
    <>
      {position === HeaderPosition.Fixed && <FirstElementMargin />}
      <HeaderCustomNav
        position={position}
        nav={customNav}
        actions={HeaderActions}
      />
    </>
  );
};

export default Header;

export const query = graphql`
  fragment HeaderDefault on PrismicHeaderDefault {
    id
    slice_label
    slice_type
    variation
  }
  fragment HeaderCustomNav on PrismicHeaderCustomNav {
    id
    slice_type
    slice_label
    variation
    primary {
      header_actions
      position
    }
    items {
      nav_internal_deep_link_slug
      nav_label
      nav_external_url {
        url
        target
        id
        slug
        uid
      }
    }
  }
`;
