import React from "react";
import { graphql } from "gatsby";
import { PureFeaturedFunds } from "../../design-system/Slices/SliceFeaturedFunds/SliceFeaturedFunds";
import { FeaturedFundsContent, ObjectFit } from "../../_shared";
import SliceFooterActions from "../SliceFooterActions";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";
import {
  employerReferralHelper,
  employerTextHelper,
} from "../../../helpers/employerHelper";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedFundsSlice} FeaturedFundsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedFundsSlice>} FeaturedFundsProps
 * @param { FeaturedFundsProps }
 */

export interface FeaturedFundsProps {
  slice: any;
  context?: any;
}

const FeaturedFunds = ({ slice, context, ...props }: FeaturedFundsProps) => {
  const funds: FeaturedFundsContent[] = [];
  const hasFooterOptions = slice.primary.cta_options.document;
  const actions =
    hasFooterOptions &&
    slice.primary.cta_options.document.data.slices.length > 0 ? (
      <SliceFooterActions
        slice={slice.primary.cta_options.document.data.slices[0]}
        align={"center"}
        context={context}
      />
    ) : null;

  const riskWarningAndDisclosure = (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning || ""}
      content={slice.primary.below_cta_content?.richText || null}
      context={context}
    />
  );

  if (slice.items.length > 0) {
    slice.items.forEach((fund: any) => {
      funds.push({
        fundName: employerTextHelper({ text: fund.fund_name, context }),
        title: employerTextHelper({ text: fund.tillit_one_liner, context }),
        content: fund.description.richText ? (
          <CustomRichText
            field={fund.description.richText}
            components={{
              paragraph: ({ children }) => <p className="small">{children}</p>,
            }}
            context={context}
          />
        ) : (
          ""
        ),
        fundManager: fund.fund_manager_name.richText ? (
          <CustomRichText
            field={fund.fund_manager_name.richText}
            components={{
              paragraph: ({ children }) => <>{children}</>,
            }}
            context={context}
          />
        ) : (
          ""
        ),
        ocf: fund.ocf,
        link: employerReferralHelper({ url: fund.fund_url?.url, context }),
        imageObjectFit:
          fund.featured_image_cover || fund.featured_image_cover == null
            ? ObjectFit.cover
            : ObjectFit.contain,
        image:
          fund.featured_image.localFile?.childImageSharp?.fixed?.src || null,
      });
    });
  }

  return (
    <PureFeaturedFunds funds={funds} {...props}>
      {slice.primary.content.richText && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}
      {actions}
      {riskWarningAndDisclosure}
    </PureFeaturedFunds>
  );
};

export default FeaturedFunds;

export const query = graphql`
  fragment FeaturedFundsDefault on PrismicFeaturedFundsDefault {
    id
    slice_label
    slice_type
    version
    variation
    items {
      tillit_one_liner
      ocf
      fund_name
      fund_url {
        url
        id
      }
      description {
        richText
      }
      fund_manager_name {
        richText
      }
      featured_image_cover
      featured_image {
        alt
        url
        localFile {
          childImageSharp {
            fixed(
              width: 600
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              originalName
              src
            }
          }
        }
      }
    }
    primary {
      below_cta_content {
        richText
      }
      content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
    }
  }
`;
