import styled from "styled-components";

export const ContentContainer = styled.div`
  width: 100%;
  text-align: center;
  max-width: 650px;
  margin: 0 auto;
  padding: 0 0 1.25rem 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0 0 3.25rem 0;
  }
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 0.5rem;

  > * {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;

    > * {
      flex-basis: calc(50% - 1rem);
    }
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    gap: 3rem;
    > * {
      flex-basis: calc(33.333% - 3rem);
    }
  }
`;
