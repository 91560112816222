import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql } from "gatsby";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";
import { SliceVideoAsk } from "../../design-system/Slices/SliceVideoAsk/VideoAsk";
import SliceFooterActions from "../SliceFooterActions";
import { useBreakpointMdDown } from "../../../hooks/useBreakpoint";

/**
 * @typedef {import("@prismicio/client").Content.VideoAskSlice} VideoAskSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<VideoAskSlice>} VideoAskProps
 * @param { VideoAskProps }
 */

export interface VideoAskProps {
  slice: any;
  context: any;
}

const VideoAsk = ({ slice, context, ...props }: VideoAskProps) => {
  const hasFooterOptions = slice.primary.cta_options.document;
  const isLandscape = slice.variation === "landscape";
  const isMobile = useBreakpointMdDown();

  const disclosureAndRiskWarning = (slice.primary.risk_warning ||
    slice.primary.below_cta_content?.richText.length > 0) && (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning}
      content={slice.primary.below_cta_content?.richText || null}
      $pad="top"
      context={context}
    />
  );

  const actions =
    hasFooterOptions &&
    slice.primary.cta_options.document.data.slices.length > 0 ? (
      <SliceFooterActions
        slice={slice.primary.cta_options.document.data.slices[0]}
        align={isLandscape || isMobile ? "center" : "left"}
        context={context}
      />
    ) : null;

  return (
    <SliceVideoAsk
      layout={isLandscape ? "landscape" : "portrait"}
      heading={slice.primary.heading}
      videoId={slice.primary.videoask_id}
      videoAlign={isLandscape ? undefined : slice.primary.video_ask_alignment}
      rounded={slice.primary.rounded_borders}
      withShadows={slice.primary.show_shadows}
      fullScreen={slice.primary.fullscreen}
      justVideo={slice.primary.just_video}
      videoMinHeight={slice.primary.video_min_height || 56}
      videoWidth={slice.primary.video_width || 100}
      {...props}
    >
      {slice.primary.content.richText && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}
      {actions}
      {disclosureAndRiskWarning}
    </SliceVideoAsk>
  );
};

export default VideoAsk;

export const query = graphql`
  fragment VideoAskLandscape on PrismicVideoAskLandscape {
    id
    variation
    slice_label
    slice_type
    primary {
      risk_warning
      rounded_borders
      show_shadows
      videoask_id
      video_min_height
      video_width
      fullscreen
      just_video
      heading
      below_cta_content {
        richText
      }
      content {
        richText
      }
      cta_options {
        document {
          ...callToActions
        }
        id
        uid
      }
    }
  }

  fragment VideoAskPortrait on PrismicVideoAskPortrait {
    id
    slice_label
    variation
    slice_type
    primary {
      risk_warning
      rounded_borders
      show_shadows
      just_video
      video_ask_alignment
      video_ask_mobile_align
      videoask_id
      video_min_height
      video_width
      heading
      content {
        richText
      }
      below_cta_content {
        richText
      }
      cta_options {
        document {
          ...callToActions
        }
        id
        uid
      }
    }
  }
`;
