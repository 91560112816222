import {
  JSXFunctionSerializer,
  JSXMapSerializer,
  PrismicRichText,
} from "@prismicio/react";
import { RichTextField } from "@prismicio/types";
import React from "react";
import {
  PrismicSegment,
  prismicRichTextTemplateStringHelper,
} from "../../helpers/prismicRichTextTemplateStringHelper";
import { getEmployerFirstAnniversary } from "../../helpers/employerHelper";

export interface CustomRichTextProps {
  field?: RichTextField;
  components?: JSXFunctionSerializer | JSXMapSerializer;
  context?: any;
}

export function CustomRichText({
  field,
  components,
  context,
}: CustomRichTextProps) {
  if (!field) return null;
  const customField =
    context &&
    (context.hasOwnProperty("employer_display_name") ||
      context.hasOwnProperty("join_date") ||
      context.hasOwnProperty("referral_code"))
      ? (prismicRichTextTemplateStringHelper(
          {
            employerName: context.employer_display_name || "",
            employerFirstAnniversary: getEmployerFirstAnniversary(
              context.join_date
            ),
            employerReferralCode: context.referral_code || "",
          },
          field as PrismicSegment[]
        ) as RichTextField)
      : field;

  return <PrismicRichText field={customField} components={components} />;
}
