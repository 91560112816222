import * as React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./linkResolver";
import { PageTemplateWithPreview } from "./src/templates/page";
import { HomePageTemplateWithPreview } from "./src/templates/home";

const queryClient = new QueryClient();
const allowPreview = process.env.GATSBY_ALLOW_PREVIEW == "true";
const allowQueryDebug = process.env.GATSBY_QUERY_DEBUG == "true";

const ConditionalPrismicPreviewProvider = ({ children }) => {
  if (allowPreview) {
    return (
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || "",
            linkResolver,
            componentResolver: componentResolverFromMap({
              page: PageTemplateWithPreview,
              homepage: HomePageTemplateWithPreview,
            }),
          },
        ]}
      >
        {children}
      </PrismicPreviewProvider>
    );
  }
  return <>{children}</>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConditionalPrismicPreviewProvider>
        {element}
      </ConditionalPrismicPreviewProvider>
      {allowQueryDebug && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

declare const window: Window & { dataLayer: Record<string, unknown>[] };

export function onRouteUpdate({ prevLocation, location }) {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    if (window.dataLayer && prevLocation) {
      window.dataLayer.push({
        event: "virtual_pageview",
        prevURL: prevLocation.href,
        currentURL: location.href,
        pageTitle: window.document.title,
      });
    }
  }, 50);
}
