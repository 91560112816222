import React from "react";
import { useTheme } from "styled-components";
import { Container, Padding } from "../../Container/Container";
import { StyledBox } from "./SliceFeaturedTable.styles";
import { Table, TableProps } from "../../Table/Table";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";

export interface PureFeaturedTableProps<T> extends TableProps<T> {}

export function PureFeaturedTable<T>({
  columns,
  data,
  ...props
}: PureFeaturedTableProps<T>) {
  const isMdUp = useBreakpointMdUp();
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <Container
      padding={Padding.both}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      {isMdUp && (
        <StyledBox>
          <Table columns={columns} data={data} />
        </StyledBox>
      )}
      {!isMdUp && <Table columns={columns} data={data} />}
    </Container>
  );
}
