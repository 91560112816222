import { EmployerContext } from "../components/_shared";
import Cookies from "js-cookie";
import * as format from "./formarting";

export function getEmployerFirstAnniversary(date: string | undefined) {
  if (!date) return "";
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  return `${format.month(month)} ${format.day(day)} ${year + 1}`;
}

export interface employerTextHelperProps {
  context: EmployerContext;
  text: string;
}

export function employerTextHelper({ text, context }: employerTextHelperProps) {
  if (!text) return text;
  if (!context) return text;
  return text
    .replaceAll("{{employerName}}", context.employer_display_name || "")
    .replaceAll(
      "{{employerFirstAnniversary}}",
      getEmployerFirstAnniversary(context.join_date)
    );
}

export interface employerReferralHelperProps {
  context: EmployerContext;
  url: string;
}

export function employerReferralHelper({
  url,
  context,
}: employerReferralHelperProps) {
  if (!url) return url;
  if (!context) return url;
  return url.replaceAll(
    "{{employerReferralCode}}",
    context.referral_code || ""
  );
}

const EMPLOYER_REFERRAL_COOKIE_NAME = "employerReferralCode";
const EMPLOYER_REFERRAL_COOKIE_EXPIRY = 30;

export function savedEmployer() {
  const employerCode = Cookies.get(EMPLOYER_REFERRAL_COOKIE_NAME);

  const setEmployerCode = (referralCode: string) => {
    Cookies.set(EMPLOYER_REFERRAL_COOKIE_NAME, referralCode, {
      expires: EMPLOYER_REFERRAL_COOKIE_EXPIRY,
      domain: ".tillitinvest.com",
    });
  };

  return { employerCode, setEmployerCode };
}
