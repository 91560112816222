import { transparentize } from "polished";
import Styled, { css } from "styled-components";
import { colors } from "../../../theme/theme";
import { Text } from "../../design-system/Slices/_shared/Text/Text";

export const PageFooter = Styled.section`
  width: 100%;
  background-color: ${colors.richBlack};
`;

export const ContentContainer = Styled.div`
  width: 100%;
  padding: 1.25rem;
  display: grid;
  grid-template-areas:
        "logo logo"
        "menu1 menu2"
        "social social"
        "form form";
  grid-template-columns: auto auto;
  gap: 1rem;

  ${(p) => p.theme.breakpoints.up("sm")} {
    grid-template-areas:
        "logo menu1 menu2 social"
        "logo form form form";
    grid-template-columns: auto 1fr 1fr 1fr;
    gap: 1rem 2.5rem;
  } 

  ${(p) => p.theme.breakpoints.up("md")} {
    grid-template-areas:
        "logo menu1 menu2 social"
        "logo menu1 form form";
    grid-template-columns: auto auto auto 1fr auto;
    align-items: start;
    gap: 4rem;
    padding: 3rem 2rem 2rem 2rem;
  }

  ${(p) => p.theme.breakpoints.up("lg")} {
    grid-template-areas: "logo menu1 menu2 form social";
    grid-template-columns: auto auto auto 1fr auto;
    align-items: start;
    gap: 4rem;
    padding: 3rem 2rem 2rem 2rem;
  }

`;

export const LogoContainer = Styled.div`
  grid-area: logo;
  margin-bottom: 1.25rem;
`;

const MenuStyles = css`
  padding: 0;
  li {
    list-style: none;
    a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      color: ${colors.white};
      text-decoration: none;
      padding-bottom: 0.5rem;
      font-weight: 300;
      &:hover {
        color: ${colors.white};
      }

      .icon {
        font-size: 1.5rem;
        margin-right: 5px;
      }
    }
    &.highlighted {
      a {
        color: ${colors.magenta};
        &:hover {
          color: ${colors.magenta};
        }
      }
    }
  }
`;

export const Menu1 = Styled.div`
  grid-area: menu1;
  ${MenuStyles};


`;

export const Menu2 = Styled.div`
  grid-area: menu2;
  ${MenuStyles};
`;

export const NewsLetterSignUpContainer = Styled.div`
  grid-area: form;
  width: 100%;
  height: 325px;
  display: flex;
  margin: 0 -1rem;

  ${(p) => p.theme.breakpoints.up("sm")} {
    margin: 0 0 0 -2.5rem;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: -2.5rem 0 0 0;
    max-width: 420px;
  }
`;

export const SocialMenu = Styled.div`
  grid-area: social;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${MenuStyles};

  li {
    flex-basis: 50%;
  }

  ${(p) => p.theme.breakpoints.up("sm")} {
    margin-top: 0;
    gap: 0;
    flex-direction: column;
    li {
      flex-basis: auto;
    }
  }
`;

export const CopyRightsContainer = Styled.div`
  width: 100%;
  padding: 4rem 1.25rem 5rem 1.25rem;
  border-top: 1px ${transparentize(0.8, colors.white)} solid;


  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 4rem 2rem 5rem 2rem;
  }

`;

export const CopyBlock = Styled(Text)`
  font-weight: 300;
  margin-bottom: 1.25rem;
  color: ${colors.white};
`;
