export enum GaEventNames {
  click = "click",
  virtualPageview = "virtual_pageview",
  navigationClick = "navigation_click",
  selectContent = "select_content",
  viewSearchResults = "view_search_results",
  popover = "popover",
}

interface trackGaPropertiesBase {
  event: GaEventNames;
  [key: string]: any;
}

export type trackGaProperties = trackGaPropertiesBase;

/**
 * Track google analytics events
 */
export function trackGa(properties: trackGaProperties) {
  if (process.env.NODE_ENV === "development") {
    if (properties.eventCallback || true) {
      console.log(JSON.stringify({ action: "track ga", properties }));
    } else {
      console.log({ action: "track ga", properties });
    }
  }

  if (window?.dataLayer) {
    window?.dataLayer?.push(properties);
    return;
  }

  // If the dataLayer hasn't loaded for any reason - then still call the callback:
  if (properties.eventCallback) {
    properties.eventCallback();
  }
}
