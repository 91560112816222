import React, { useState } from "react";
import { FeaturedContentCard } from "../../FeaturedContentCard/FeaturedContentCard";
import {
  FeaturedContentCarouselContent,
  ImageObjectFit,
} from "../../../_shared";
import {
  CollectionContainer,
  CollectionGrid,
} from "./InTheNewsCollection.styles";
import { Container, Padding } from "../../Container/Container";
import { Button } from "../../Buttons";
import CustomRichText from "../../../CustomRichText";
import { RichTextField } from "@prismicio/types";

export interface InTheNewsContent extends FeaturedContentCarouselContent {}

export interface InTheNewsCollectionProps {
  postsPerPage: number;
  showLoadMore: boolean;
  content: InTheNewsContent[];
  actions?: string | JSX.Element | React.ReactNode;
}

export function InTheNewsCollection({
  postsPerPage = -1,
  showLoadMore = false,
  content,
  actions,
}: InTheNewsCollectionProps) {
  if (content?.length === 0) return null;
  const [postsLoaded, setPostsLoaded] = useState<number>(postsPerPage);

  const handleLoadMore = () => {
    if (postsLoaded < content.length)
      setPostsLoaded(postsLoaded + postsPerPage);
  };

  return (
    <CollectionContainer fullWidth={false} padding={Padding.both}>
      <CollectionGrid>
        {content &&
          content.map((card, i) => {
            if (i < postsLoaded || postsLoaded === -1)
              return (
                <FeaturedContentCard
                  subTitle={card.subTitle}
                  withDivider={card.withDivider}
                  title={card.title}
                  content={card.content}
                  leftInfo={card.leftInfo}
                  rightInfo={card.rightInfo}
                  link={card.link}
                  target={card.target}
                  image={card.image}
                  imageObjectFit={card.imageObjectFit}
                />
              );
          })}
      </CollectionGrid>
      {actions && <>{actions}</>}
      {showLoadMore && postsPerPage > 0 && postsLoaded < content.length && (
        <Button onClick={handleLoadMore}>Load more</Button>
      )}
    </CollectionContainer>
  );
}
