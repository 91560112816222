import React from "react";
import { PureFeeCalculator } from "../../../design-system/Slices/SliceFeeCalculator/FeeCalculator";
import { PrismicRichText } from "@prismicio/react";

export interface FeeCalculatorProps {
  slice: any;
}

export function FeeCalculator({ slice, ...props }: FeeCalculatorProps) {
  return (
    <PureFeeCalculator
      layout={slice.primary.calculator_placement || "left"}
      {...props}
    >
      {slice.primary.content ? (
        <PrismicRichText field={slice.primary.content.richText} />
      ) : (
        <></>
      )}
    </PureFeeCalculator>
  );
}
