import styled, { css } from "styled-components";
import { Container } from "../../Container/Container";
import { sizes } from "../../../../theme/theme";

export const VideoAskContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
`;

const contentBaseStyles = css`
  width: 100%;
  max-width: ${sizes.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  order: 1;

  .block-img {
    img {
      width: auto;
    }
  }
`;

export const ContentContainer = styled.div`
  ${contentBaseStyles}
  align-items: center;
`;

export const ContentContainerColumn = styled.div`
  ${contentBaseStyles}
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    align-items: flex-start;
    text-align: left;
    width: 50%;
  }
`;

const baseIframe = css`
  width: 100%;
  height: auto;
  border: none;
  background: none;
`;

interface WideVideoAskPlayerProps {
  $rounded?: boolean;
  $shadows?: boolean;
  $minHeight: number;
  $Width: number;
}

export const WideVideoAskPlayer = styled.iframe<WideVideoAskPlayerProps>`
  ${baseIframe}
  order: 2;
  min-height: ${({ $minHeight }) => $minHeight}vh;
  width: 100%;

  box-shadow: ${({ $shadows, theme }) =>
    $shadows ? theme.shadows.extraSmallNeumorphic : "none"};
  border-radius: ${({ $rounded }) => ($rounded ? "12px" : "0")};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: ${({ $Width }) => $Width}%;
  }
`;

interface NarrowVideoAskContainerProps {
  $align: "left" | "right";
  $mobAlign: "top" | "bottom";
}

export const NarrowVideoAskContainer = styled.div<NarrowVideoAskContainerProps>`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  order: ${({ $mobAlign }) => ($mobAlign === "top" ? "0" : "2")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 50%;
    order: ${({ $align }) => ($align === "left" ? "0" : "2")};
  }
`;

interface NarrowVideoAskPlayerProps extends WideVideoAskPlayerProps {}

export const NarrowVideoAskPlayer = styled.iframe<NarrowVideoAskPlayerProps>`
  ${baseIframe}
  min-height: ${({ $minHeight }) => $minHeight}vh;
  width: 100%;

  box-shadow: ${({ $shadows, theme }) =>
    $shadows ? theme.shadows.extraSmallNeumorphic : "none"};
  border-radius: ${({ $rounded }) => ($rounded ? "12px" : "0")};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: ${({ $Width }) => $Width}%;
  }
`;

export const VideoAskPortraitContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    gap: 7.5rem;
    align-items: center;
    justify-content: space-between;
  }
`;
