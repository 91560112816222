import React from "react";
import styled from "styled-components";

type compactType = "onMobile" | "always" | "never";

function TillitIconSVG({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 850.39 566.93"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <polygon points="477.74 179.38 477.74 179.38 501.73 179.38 501.73 216.23 479.06 216.23 479.06 283.98 479.06 389.14 442.2 389.14 442.2 283.98 442.2 179.38 477.74 179.38" />
      <polygon points="372.66 179.38 372.66 179.38 348.66 179.38 348.66 216.23 371.34 216.23 371.34 283.98 371.34 389.14 408.19 389.14 408.19 283.98 408.19 179.38 372.66 179.38" />
    </svg>
  );
}

function TillitLogoSVG({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 850.39 566.93"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <polygon points="330.14 389.14 293.29 389.14 293.29 285.93 293.29 179.38 330.14 179.38 330.14 286.26 330.14 389.14" />
      <polygon points="223.74 179.38 223.74 179.38 199.75 179.38 199.75 216.23 222.42 216.23 222.42 283.98 222.42 389.14 259.27 389.14 259.27 283.98 259.27 179.38 223.74 179.38" />
      <polygon points="478.4 389.14 478.4 389.14 502.39 389.14 502.39 352.3 479.71 352.3 479.71 284.55 479.71 179.38 442.86 179.38 442.86 284.55 442.86 389.14 478.4 389.14" />
      <polygon points="373.97 389.14 373.97 389.14 349.98 389.14 349.98 352.3 372.65 352.3 372.65 284.55 372.65 179.38 409.51 179.38 409.51 284.55 409.51 389.14 373.97 389.14" />
      <polygon points="627.97 179.38 627.97 179.38 651.97 179.38 651.97 216.23 629.29 216.23 629.29 283.98 629.29 389.14 592.44 389.14 592.44 283.98 592.44 179.38 627.97 179.38" />
      <polygon points="559.08 389.14 522.23 389.14 522.23 285.93 522.23 179.38 559.08 179.38 559.08 286.26 559.08 389.14" />
    </svg>
  );
}

interface TillitLogoSVGCompactSvgProps {
  compact?: compactType;
}
const TillitLogoSVGCompactSvg = styled.svg<TillitLogoSVGCompactSvgProps>`
  ${({ compact }) => (compact === "never" ? "display: none;" : "")}

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ compact }) => (compact === "onMobile" ? "display: none;" : "")}
  }
`;

function TillitLogoSVGCompact({ ...props }) {
  return (
    <TillitLogoSVGCompactSvg
      width="29"
      height="39"
      viewBox="0 0 29 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H11V39H4.25806V6.79816H0V0Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29 0H18V39H24.7419V6.79816H29V0Z"
        />
      </g>
    </TillitLogoSVGCompactSvg>
  );
}

interface TillitLogoSVGnoPadSvgProps {
  compact?: compactType;
}
const TillitLogoSVGnoPadSvg = styled.svg<TillitLogoSVGnoPadSvgProps>`
  ${({ compact }) =>
    compact === "always" || compact === "onMobile" ? "display: none;" : ""}

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ compact }) => (compact === "onMobile" ? "display: block;" : "")}
  }
`;

function TillitLogoSVGnoPad({ ...props }) {
  return (
    <TillitLogoSVGnoPadSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 453 211"
      {...props}
    >
      {props.title && <title>{props.title}</title>}
      <polygon
        id="Path"
        points="131.14 210.14 94.29 210.14 94.29 106.93 94.29 0.38 131.14 0.38 131.14 107.26"
      ></polygon>
      <polygon
        id="Path"
        points="24.74 0.38 24.74 0.38 0.75 0.38 0.75 37.23 23.42 37.23 23.42 104.98 23.42 210.14 60.27 210.14 60.27 104.98 60.27 0.38"
      ></polygon>
      <polygon
        id="Path"
        points="279.4 210.14 279.4 210.14 303.39 210.14 303.39 173.3 280.71 173.3 280.71 105.55 280.71 0.38 243.86 0.38 243.86 105.55 243.86 210.14"
      ></polygon>
      <polygon
        id="Path"
        points="174.97 210.14 174.97 210.14 150.98 210.14 150.98 173.3 173.65 173.3 173.65 105.55 173.65 0.38 210.51 0.38 210.51 105.55 210.51 210.14"
      ></polygon>
      <polygon
        id="Path"
        points="428.97 0.38 428.97 0.38 452.97 0.38 452.97 37.23 430.29 37.23 430.29 104.98 430.29 210.14 393.44 210.14 393.44 104.98 393.44 0.38"
      ></polygon>
      <polygon
        id="Path"
        points="360.08 210.14 323.23 210.14 323.23 106.93 323.23 0.38 360.08 0.38 360.08 107.26"
      ></polygon>
    </TillitLogoSVGnoPadSvg>
  );
}

export interface LogoHeaderProps {
  size?: number;
  pad?: boolean;
  icon?: boolean;
}

export interface LogoProps {
  color?: string;
  size?: number;
  pad?: boolean;
  icon?: boolean;
  compact?: boolean;
  tooltip?: string;
}

/**
 * @depreacted - use TillitLogoV2 instead
 */
export function TillitLogo({
  color = "currentColor",
  size = 1,
  pad = false,
  icon = false,
  compact = false,
  tooltip,
}: LogoProps) {
  const defaultTitle = `TILLIT ${icon ? "Icon" : "Logo"}`;
  const props = {
    title: `${tooltip ? tooltip : defaultTitle}`,
    style: {
      maxWidth: size * 70,
      width: "100%",
      height: "auto",
      fill: color,
    },
    compact,
  };

  return icon ? (
    <TillitIconSVG {...props} />
  ) : pad ? (
    <TillitLogoSVG {...props} />
  ) : compact ? (
    <TillitLogoSVGCompact {...props} />
  ) : (
    <TillitLogoSVGnoPad {...props} />
  );
}

const TillitLogoEl = styled.div``;

export interface TillitLogoV2Props {
  color?: string;
  compact?: compactType;
  tooltip?: string;
}
export function TillitLogoV2({
  color = "currentColor",
  compact = "never",
  tooltip,
}: TillitLogoV2Props) {
  const defaultTitle = "TILLIT";
  const props = {
    title: `${tooltip ? tooltip : defaultTitle}`,
    style: {
      maxWidth: "100%",
      maxHeight: "100%",
      fill: color,
    },
    compact,
  };

  return (
    <TillitLogoEl>
      <TillitLogoSVGCompact {...props} />
      <TillitLogoSVGnoPad {...props} />
    </TillitLogoEl>
  );
}
