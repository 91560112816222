import React from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { useRef } from "react";
import {
  Wrapper,
  PositionTracker,
  Container,
  SlideImg,
  CopyContainer,
  Copy,
} from "./SliceUniversePerformance.styles";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import { useMediaQuery } from "usehooks-ts";

interface SliceUniversePerformanceProps {
  actions?: JSX.Element | React.ReactNode | string;
}

const threshold = 0.35;
const smoothScrollThreshold = [
  0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12,
  0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25,
  0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38,
  0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51,
  0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64,
  0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77,
  0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9,
  0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1,
];

export function SliceUniversePerformance({
  actions,
  ...props
}: SliceUniversePerformanceProps) {
  const isMdUp = useBreakpointMdUp();

  /**
   * If this is true then the image overlay with the text slowly moves across the screen as the user scrolls.
   *
   * This doesn't work on mobile or tablet on chrome or safari - it jitters and jumps around backwards and forewards.
   *
   * media hover: hover is used to detect if the user is on a mobile device.
   */
  const smoothScroll = useMediaQuery("(hover: hover)");

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: smoothScroll ? smoothScrollThreshold : threshold,
    freezeOnceVisible: false,
  });

  const intersectionRatio = entry?.intersectionRatio ?? 1;

  const maxSlidePercent = isMdUp ? 100 : 33.4;

  const opacity = smoothScroll
    ? intersectionRatio < 0.5
      ? 1
      : 0
    : intersectionRatio > 0.35
    ? 0
    : 1;

  const transform = smoothScroll
    ? `translateX(${
        entry?.intersectionRect.y === 0
          ? maxSlidePercent - intersectionRatio * maxSlidePercent
          : 0
      }%)`
    : `translateX(${intersectionRatio > 0.35 ? 0 : maxSlidePercent}%)`;

  return (
    <Wrapper {...props}>
      <PositionTracker ref={ref}></PositionTracker>
      <Container>
        <SlideImg src="/static/backgrounds/black-white-details-optimized.webp" />
        <SlideImg
          src="/static/backgrounds/white-black-details-optimized.webp"
          style={{
            opacity: opacity,
          }}
        />

        <CopyContainer
          displayedOver={
            intersectionRatio > 0.5 ? "black-white" : "white-black"
          }
          style={{
            transform,
          }}
        >
          <Copy>
            <h2>How have our funds performed?</h2>

            <p>
              <strong>At TILLIT, we aim to offer best-in-class funds</strong>{" "}
              across asset classes, regions and styles. Our Annual Performance
              Report 2023 is one of the ways we hold ourselves accountable to
              that mission.
            </p>

            <p>
              We analysed the performance of the TILLIT Universe over 5, 10 and
              20 years and found 93% of our active funds with a 20-year track
              record outperformed their benchmark after fees over the period.
            </p>

            {actions}
          </Copy>
        </CopyContainer>
      </Container>
    </Wrapper>
  );
}
