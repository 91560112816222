import React from "react";
import { PureFeaturedVideo } from "../../design-system/Slices/SliceFeaturedVideo/SliceFeaturedVideo";
import { RowSizeLookup, RowSizes } from "../../_shared/index";
import { graphql } from "gatsby";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import SliceFooterActions from "../SliceFooterActions";
import CustomRichText from "../../CustomRichText";
import { PureTextRow } from "../../design-system/Slices/SliceTextRow/SliceTextRow";
import { Padding } from "../../design-system/Container/Container";
import { PureColumnsText } from "../../design-system/Slices/SliceColumnsText/ColumnsText/ColumnsText";

/**
 * @typedef {import("@prismicio/client").Content.WebinarVideoSlice} WebinarVideoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<WebinarVideoSlice>} WebinarVideoProps
 * @param { WebinarVideoProps }
 */

export interface WebinarVideoProps {
  slice: any;
  context: any;
}

const WebinarVideo = ({ slice, context, ...props }: WebinarVideoProps) => {
  const vidId: string[] = context.webinar_id ? [context.webinar_id] : [];

  if (context.webinar_id_2) {
    vidId?.push(context.webinar_id_2);
  }

  if (context.webinar_id_3) {
    vidId?.push(context.webinar_id_3);
  }

  if (vidId.length <= 0) {
    return null;
  }

  const hasFooterOptions = slice.primary.cta_options.document;
  const rowSize = slice.primary.row_size
    ? RowSizeLookup[slice.primary.row_size as RowSizes]
    : RowSizeLookup["Normal"];

  const disclosureAndRiskWarning = (slice.primary.risk_warning ||
    slice.primary.below_cta_content?.richText.length > 0) && (
    <RiskWarningAndDisclosures
      content={slice.primary.below_cta_content?.richText || null}
      $pad="top"
      context={context}
    />
  );

  return vidId.length < 2 ? (
    <>
      <PureTextRow
        isFullWidth={true}
        padding={Padding.top}
        rowSize={rowSize}
        {...props}
      >
        {slice.primary.content && (
          <CustomRichText
            field={slice.primary.content.richText}
            context={context}
          />
        )}

        {hasFooterOptions &&
          slice.primary.cta_options.document.data.slices.length > 0 && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align="center"
              mdUpAlign="center"
              context={context}
            />
          )}

        {disclosureAndRiskWarning}
      </PureTextRow>

      <PureFeaturedVideo
        vidId={vidId[0]}
        rounded={slice.primary.rounded}
        shadows={slice.primary.shadows}
        fullWidth={slice.primary.full_width}
        marginTop={false}
      />
    </>
  ) : (
    <PureColumnsText
      dividers={true}
      isWide={true}
      header={
        <>
          {slice.primary.content && (
            <CustomRichText
              field={slice.primary.content.richText}
              context={context}
            />
          )}

          {hasFooterOptions &&
            slice.primary.cta_options.document.data.slices.length > 0 && (
              <SliceFooterActions
                slice={slice.primary.cta_options.document.data.slices[0]}
                align="center"
                mdUpAlign="center"
                context={context}
              />
            )}

          {disclosureAndRiskWarning}
        </>
      }
      columns={vidId.map((id: string, index: number) => (
        <PureFeaturedVideo
          key={`${index}-${id}`}
          vidId={id}
          rounded={slice.primary.rounded}
          shadows={slice.primary.shadows}
          fullWidth={slice.primary.full_width}
          marginTop={false}
          padding={Padding.none}
        />
      ))}
      columnsTextAlignment="center"
    />
  );
};

export default WebinarVideo;

export const query = graphql`
  fragment WebinarVideoDefault on PrismicWebinarVideoDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      full_width
      rounded
      shadows
      content {
        richText
      }
      row_size
      cta_options {
        document {
          ...callToActions
        }
        id
        uid
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
`;
