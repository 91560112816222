import React from "react";
import { graphql } from "gatsby";
import { Container, Padding } from "../../design-system/Container/Container";
import { PureSliceFaqs } from "../../design-system/Slices/SliceFAQs/SliceFaqs";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.FaqsSlice} FaqsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FaqsSlice>} FaqsProps
 * @param { FaqsProps }
 */

export interface FaqsProps {
  slice: any;
  context?: any;
}

const Faqs = ({ slice, context, ...props }: FaqsProps) => {
  const variant = slice.variation;
  const faqsArr = [];

  for (let singleFAQ of slice.items) {
    if (singleFAQ.featured_faq.document === null) {
      continue;
    }
    const faq = singleFAQ.featured_faq.document?.data;
    faqsArr.push({
      question: faq?.title,
      children: faq?.description?.richText ? (
        <CustomRichText field={faq.description.richText} context={context} />
      ) : (
        ""
      ),
    });
  }

  return (
    <Container padding={Padding.none} fullWidth {...props}>
      <PureSliceFaqs
        content={
          slice.primary?.content?.richText ? (
            <CustomRichText
              field={slice.primary.content.richText}
              context={context}
            />
          ) : (
            ""
          )
        }
        faqs={faqsArr}
        type={variant}
        {...props}
      />
    </Container>
  );
};

export default Faqs;

export const query = graphql`
  fragment FaqsDefault on PrismicFaqsDefault {
    id
    variation
    slice_label
    slice_type
    items {
      featured_faq {
        document {
          ... on PrismicFaq {
            id
            data {
              title
              description {
                richText
              }
              category {
                document {
                  ... on PrismicFaqCategory {
                    id
                    data {
                      category_label
                    }
                    uid
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
    primary {
      content {
        richText
      }
    }
  }

  fragment FaqsContained on PrismicFaqsContained {
    id
    variation
    slice_label
    slice_type
    items {
      featured_faq {
        document {
          ... on PrismicFaq {
            id
            data {
              title
              description {
                richText
              }
              category {
                document {
                  ... on PrismicFaqCategory {
                    id
                    data {
                      category_label
                    }
                    uid
                  }
                }
              }
            }
            uid
          }
        }
      }
    }
    primary {
      content {
        richText
      }
    }
  }
`;
