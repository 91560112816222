import styled from "styled-components";
import { colors, sizes } from "../../../../theme/theme";

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${sizes.md};
  margin: 0 auto;
  text-align: center;
`;

export const Nav = styled.nav`
  width: 100%;
  max-width: ${sizes.md};
  margin: 1.875rem auto 0 auto;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;

  li {
    list-style: none;
    padding: 0;
  }
`;

export const StyledLink = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.white : colors.purple};
  cursor: pointer;
  padding: 0.375rem 0;

  &:hover {
    text-decoration: underline;
  }
`;
