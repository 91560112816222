import React from "react";
import { PureFeaturedInsights } from "../../design-system/Slices/SliceFeaturedInsights/SliceFeaturedInsights";
import { InsightContent, ObjectFit } from "../../_shared";
import { graphql } from "gatsby";
import CustomRichText from "../../CustomRichText";
import {
  employerReferralHelper,
  employerTextHelper,
} from "../../../helpers/employerHelper";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedInsightsSlice} FeaturedInsightsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedInsightsSlice>} FeaturedInsightsProps
 * @param { FeaturedInsightsProps }
 */
export interface FeaturedInsights {
  slice: any;
  context?: any;
}

const FeaturedInsights = ({ slice, context }: FeaturedInsights) => {
  const insights: InsightContent[] = [];

  if (slice.items.length > 0) {
    slice.items.forEach((insight: any) => {
      insights.push({
        tags: insight.tags,
        title: employerTextHelper({ text: insight.title, context }),
        content: insight.insight_description.richText ? (
          <CustomRichText
            field={insight.insight_description.richText}
            components={{
              paragraph: ({ children }) => <p className="small">{children}</p>,
            }}
            context={context}
          />
        ) : (
          ""
        ),
        author: insight.author,
        readTime: insight.read_time,
        link: employerReferralHelper({ url: insight.insight_url.url, context }),
        imageObjectFit:
          insight.image_cover || insight.image_cover == null
            ? ObjectFit.cover
            : ObjectFit.contain,
        image: insight.image.localFile?.childImageSharp?.fixed?.src || null,
      });
    });
  }

  return (
    <PureFeaturedInsights insights={insights}>
      {slice.primary.content.richText && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}
    </PureFeaturedInsights>
  );
};

export default FeaturedInsights;

export const query = graphql`
  fragment FeaturedInsightsDefault on PrismicFeaturedInsightsDefault {
    id
    variation
    slice_type
    slice_label
    items {
      author
      title
      tags
      read_time
      image_cover
      image {
        alt
        url
        localFile {
          childImageSharp {
            fixed(
              width: 600
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              originalName
              src
            }
          }
        }
      }
      insight_description {
        richText
      }
      insight_url {
        url
        id
      }
    }
    primary {
      content {
        richText
      }
    }
  }
`;
