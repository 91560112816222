import React, { useRef, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { AiOutlineMenu } from "react-icons/ai";
import {
  useBreakpointMdUp,
  useBreakpointMdDown,
} from "../../../hooks/useBreakpoint";
import { TillitLogoV2 } from "../../design-system/TillitLogo/TillitLogo";
import { BodyOverflow } from "../../BodyOverFlow/BodyOverflow";
import { NavCloseIcon } from "../../design-system/CustomIcons/NavCloseIcon/NavCloseIcon";
import { Navigation } from "./Navigation";
import {
  ActionsContainer,
  HeaderContainer,
  RelativeHeaderContainer,
  HeaderInner,
  HeaderShimContainer,
  LogoContainer,
  MobileNavContainer,
  NavToggle,
} from "./Header.styles";
import { ContextualActions } from "./ContextualActions";

export function Header() {
  const mobNavRef = useRef(null);
  const [navDrawer, setNavDrawer] = useState(false);

  const isBreakpointMdUp = useBreakpointMdUp();

  const handleDrawer = (isOpen: boolean) => {
    setNavDrawer(isOpen);
  };

  const handleCloseMobNav = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target === mobNavRef.current) setNavDrawer(false);
  };

  const handleClickCloseMobNav = () => {
    setNavDrawer(false);
  };

  useEffect(() => {
    if (isBreakpointMdUp) setNavDrawer(false);
  }, [isBreakpointMdUp]);

  return (
    <>
      <BodyOverflow overflowHidden={navDrawer} />
      <HeaderContainer $navDrawerOpen={navDrawer} className={"app--header"}>
        <HeaderInner>
          <LogoContainer href="/">
            <TillitLogoV2 compact="onMobile" tooltip="Back to home" />
          </LogoContainer>

          <MobileNavContainer
            $open={navDrawer}
            ref={mobNavRef}
            onClick={handleCloseMobNav}
          >
            <Navigation />
            <NavCloseIcon onClick={handleClickCloseMobNav} />
          </MobileNavContainer>

          <ActionsContainer>
            <ContextualActions />
          </ActionsContainer>

          <NavToggle
            color="inherit"
            aria-label="Open main navigation"
            onClick={() => handleDrawer(true)}
          >
            <AiOutlineMenu />
          </NavToggle>
        </HeaderInner>
      </HeaderContainer>
    </>
  );
}

export function RelativeHeader() {
  const mobNavRef = useRef(null);
  const [navDrawer, setNavDrawer] = useState(false);
  const isSmall = useBreakpointMdDown();
  const aboveLaptop = useBreakpointMdUp();

  const handleDrawer = (isOpen: boolean) => {
    setNavDrawer(isOpen);
  };

  const handleCloseMobNav = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target === mobNavRef.current) setNavDrawer(false);
  };

  const handleClickCloseMobNav = () => {
    setNavDrawer(false);
  };

  useEffect(() => {
    if (aboveLaptop) setNavDrawer(false);
  }, [aboveLaptop]);

  return (
    <>
      <BodyOverflow overflowHidden={navDrawer} />
      <RelativeHeaderContainer className={`app--header`}>
        <HeaderInner>
          <LogoContainer href="/">
            <TillitLogoV2 compact="onMobile" tooltip="Back to home" />
          </LogoContainer>

          {aboveLaptop && (
            <>
              <Navigation />
            </>
          )}

          <ActionsContainer>
            <ContextualActions />
          </ActionsContainer>

          {!aboveLaptop && (
            <IconButton
              color="inherit"
              aria-label="Open main navigation"
              onClick={() => handleDrawer(true)}
            >
              <AiOutlineMenu />
            </IconButton>
          )}
        </HeaderInner>
      </RelativeHeaderContainer>

      {!aboveLaptop && (
        <MobileNavContainer
          $open={navDrawer}
          ref={mobNavRef}
          onClick={handleCloseMobNav}
        >
          <Navigation extraClass="header--mobileNav" />
          <NavCloseIcon onClick={handleClickCloseMobNav} />
        </MobileNavContainer>
      )}
    </>
  );
}

export function HeaderShim() {
  return (
    <HeaderShimContainer className={`app--header-shim`}>
      <span></span>
    </HeaderShimContainer>
  );
}
