import styled from "styled-components";
import { TextAlignment } from "../../../_shared";
import { Container } from "../../Container/Container";

export interface StyledContainerProps {
  $align: TextAlignment.left | TextAlignment.center;
}

export const StyledContainer = styled(Container)<StyledContainerProps>`
  text-align: ${({ $align }) => $align};

  iframe {
    border-radius: 0 !important;
    font-family: "IBM Plex Sans";
    z-index: 0;
  }
`;
