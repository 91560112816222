import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { useTheme } from "styled-components";
import * as paths from "../../../paths";
import { Container, ContentWrapper } from "./SliceFooterActions.styles";
import { Button, ButtonWrapper } from "../../design-system/Buttons";
import { Actions, Action, ContentAlignments } from "../../_shared";
import { GaEventNames, trackGa } from "../../../helpers/track";
import { employerReferralHelper } from "../../../helpers/employerHelper";

/**
 * @typedef {import("@prismicio/client").Content.SliceFooterActionsSlice} SliceFooterActionsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<SliceFooterActionsSlice>} SliceFooterActionsProps
 * @param { SliceFooterActionsProps }
 */

interface PredefinedActionProps {
  action: Action;
  label?: string;
}

/**
 * This can also be mapped to have multiple actions as, sign up - sign in
 */
const PredefinedAction = ({ action, label }: PredefinedActionProps) => {
  const theme = useTheme();

  if (action === Actions.startInvesting)
    return (
      <Button
        as="a"
        href={paths.signUpPath}
        $color={"primary"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "CTA button",
            item_id: label || "Start Investing",
          });
        }}
      >
        {label || "Start Investing"}
      </Button>
    );
  if (action === Actions.transfer)
    return (
      <Button
        as="a"
        href={paths.startTransferPath}
        $color={"purple"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "CTA button",
            item_id: label || "Transfer to TILLIT",
          });
        }}
      >
        {label || "Transfer to TILLIT"}
      </Button>
    );
  if (action === Actions.signIn)
    return (
      <Button
        as="a"
        href={paths.signInPath}
        $color={"primary"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "CTA button",
            item_id: label || "Sign in",
          });
        }}
      >
        {label || "Sign in"}
      </Button>
    );
  if (action === Actions.talkToTILLIT)
    return (
      <Button
        as="a"
        href={paths.tillitContactPath}
        $color={theme.theme.page === "dark" ? "white" : "richBlack"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "CTA button",
            item_id: label || "Talk To TILLIT",
          });
        }}
      >
        {label || "Talk To TILLIT"}
      </Button>
    );
  return <></>;
};

interface SliceFooterActionsProps {
  slice: any;
  context?: any;
  align?: ContentAlignments;
  mdUpAlign?: ContentAlignments;
}

const SliceFooterActions = ({
  slice,
  align = "center",
  mdUpAlign,
  context,
  ...props
}: SliceFooterActionsProps) => {
  const variation = slice.variation;
  const label = slice?.primary?.overwrite_label || null;

  return (
    <Container $align={align} $mdUpAlign={mdUpAlign} {...props}>
      {slice.primary.content && (
        <ContentWrapper>
          <PrismicRichText field={slice.primary.content.richText} />
        </ContentWrapper>
      )}

      {variation && (
        <ButtonWrapper $align={align} $mdUpAlign={mdUpAlign}>
          {variation === "default" ? (
            <PredefinedAction
              action={slice.primary.action as Action}
              label={label || undefined}
            />
          ) : (
            <>
              {slice.items.map((button: any) => (
                <Button
                  key={button.label}
                  as="a"
                  href={employerReferralHelper({
                    url: button.href.url,
                    context,
                  })}
                  $color={button.variant}
                  variant={button.type || "contained"}
                  target={button.open_link_in_new_tab ? "_blank" : "_self"}
                  onClick={() => {
                    trackGa({
                      event: GaEventNames.selectContent,
                      content_type: "CTA button",
                      item_id: button.label,
                    });
                  }}
                >
                  {button.label}
                </Button>
              ))}
            </>
          )}
        </ButtonWrapper>
      )}
    </Container>
  );
};

export default SliceFooterActions;
