import styled from "styled-components";
import { Text } from "../Slices/_shared/Text/Text";

import { colors } from "../../../theme/theme";

export const Container = styled.a`
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows.small};
  padding: 1.5rem 1.5rem 0 1.5rem;
  height: 100%;
  text-decoration: none;
  color: inherit;
  transition: transform 0.25s ease-in-out;
  border-radius: 10px;
  overflow: hidden;
  &:hover {
    transform: translateY(-0.5rem);
  }
`;

export const Image = styled.div`
  margin: -1.5rem -1.5rem 0 -1.5rem;
  width: calc(100% + 3rem);
  height: 195px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const SubTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 2.25rem;
  margin: 1rem 0;
`;

export const SubTitle = styled.p`
  color: ${colors.magentaSafe};
  font-weight: 500;
  margin: 0;
`;

export const Hr = styled.hr`
  background-color: #f2f2f2;
  height: 1px;
  border: 0;
  width: 100%;
  margin: 0;
`;

export const Title = styled.h6`
  font-weight: 400;
  margin: 1rem 0 0.75rem 0;
`;

export const Content = styled(Text)`
  position: relative;
  min-height: 4rem;
`;

export interface FooterProps {
  multiCol: boolean;
}

export const Footer = styled.footer<FooterProps>`
  display: grid;
  grid-template-columns: ${({ multiCol }) =>
    multiCol ? `calc(50% - 1rem) calc(50% + 1rem)` : `100%`};
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.white : colors.richBlue};
  margin-top: auto;
`;

export const Info1 = styled.p`
  margin-top: 2rem;
  margin-bottom: 1rem;
  b {
    font-weight: 500;
  }
`;

export const Info2 = styled.p`
  border-left: solid 1px #eeeeee;
  padding-left: 1rem;
  margin-left: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 400;
`;
