import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { StyledVideo, VideoWrapper } from "./Video.styles";

export interface VimeoVideoProps {
  vidId: string;
  rounded?: boolean;
  shadows?: boolean;
  marginTop?: boolean;
}

export function VimeoVideo({
  vidId,
  rounded = true,
  shadows = true,
  marginTop = true,
  ...props
}: VimeoVideoProps) {
  const [allowTracking, setAllowTracking] = useState(false);
  const consent = Cookies.get("cookieconsent_status_v2");

  useEffect(() => {
    if (consent) {
      const consentObj = JSON.parse(consent);
      setAllowTracking(consentObj.allowTrackingCookies);
    } else {
      setAllowTracking(false);
    }
  }, []);

  return (
    <VideoWrapper
      $rounded={rounded}
      $shadows={shadows}
      $margin={marginTop}
      {...props}
    >
      <iframe
        id={vidId}
        className="vimeo--video"
        src={`https://player.vimeo.com/video/${vidId}?dnt=${allowTracking}`}
        width="640"
        height="360"
        frameBorder="0"
        allow="fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
}

interface VideoPlayerProps {
  videoUrl?: string;
  videoPoster?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  withShadows?: boolean;
  roundedBorders?: boolean;
  maxHeight?: number;
}

export function VideoPlayer({
  videoUrl,
  videoPoster,
  autoPlay = true,
  loop = true,
  muted = true,
  withShadows = false,
  roundedBorders = true,
  maxHeight,
}: VideoPlayerProps) {
  return (
    <>
      <StyledVideo
        muted={muted}
        playsInline
        autoPlay={autoPlay}
        loop={loop}
        poster={videoPoster}
        $shadows={withShadows}
        controls={!autoPlay}
        $rounded={roundedBorders}
        $maxHeight={maxHeight ? `${maxHeight}px` : "100%"}
      >
        <source src={videoUrl} type="video/mp4" />
      </StyledVideo>
    </>
  );
}
