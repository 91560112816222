import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

interface ogImage {
  src: string;
  width: number;
  height: number;
}

export interface SeoProps {
  noIndex?: boolean;
  title: string;
  description: string;
  url: string;
  image?: ogImage;
}

export const Seo = ({
  noIndex = false,
  title,
  description,
  url,
  image,
}: SeoProps) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    siteUrl,
  } = useSiteMetadata();
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${url}` || siteUrl,
  };

  return (
    <>
      <title>{seo.title}</title>
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="TILLIT" />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:locale:alternate" content="en_GB" />
      {image && (
        <>
          <meta property="og:image" content={`${siteUrl}${image.src}`} />
          <meta property="og:image:width" content={`${image.width}`} />
          <meta property="og:image:height" content={`${image.height}`} />
        </>
      )}{" "}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      {image && (
        <meta name="twitter:image" content={`${siteUrl}${image.src}`} />
      )}
    </>
  );
};
