import styled from "styled-components";
import { colors } from "../../../../../theme/theme";
import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { QuoteTextSize, QuoteTextSizes } from "../../../../_shared";

export interface TextProps {
  color?: string;
}

/**
 * Text
 *
 * This wrapper should be used for all Text coming from prismic.
 *
 * It takes the top padding off the first element, and bottom padding off the last element - this
 * means all vertical padding is controlled in the css. It also sets the colour of text based on
 * the theme.
 *
 * @todo - rename this component. We use the name 'Text' in other repos for other purposes.
 */
export const Text = styled.div<TextProps>`
  p {
    margin: 1rem 0;
    color: ${({ theme, color }) =>
      color || theme.theme.page === "dark" ? colors.white : colors.richBlack};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5rem 0;
    color: ${({ theme, color }) =>
      color || theme.theme.page === "dark" ? colors.white : colors.richBlue};
  }

  h2 + h1,
  h3 + h1 {
    margin-top: -1rem;
  }

  & *:first-child {
    margin-top: 0;
  }
  & *:last-child {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }
`;

const QuoteText = styled(Text)`
  & p:first-child::before {
    content: '"';
  }
  & p:last-child::after {
    content: '"';
  }
`;

type QuotePrismicRichTextProps = Parameters<typeof PrismicRichText>[0] & {
  paragraphClassName?: QuoteTextSizes;
};

export const QuotePrismicRichText = ({
  paragraphClassName = QuoteTextSize.quote,
  components,
  ...props
}: QuotePrismicRichTextProps) => {
  return (
    <QuoteText>
      <PrismicRichText
        {...props}
        components={{
          paragraph: ({ children }) => (
            <p className={paragraphClassName}>{children}</p>
          ),
          ...components,
        }}
      />
    </QuoteText>
  );
};
