import { FiX, FiCheck } from "react-icons/fi";
import styled from "styled-components";
import { colors } from "../../../../theme/theme";

export const ColHeadWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    align-items: center;
    gap: 0 10px;
  }

  p {
    display: flex;
    gap: 0 5px;
    margin: 0;
  }
`;

export const Yes = styled(FiCheck)`
  font-size: 1.5rem;
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.purple : colors.magenta};
`;

export const No = styled(FiX)`
  font-size: 1.5rem;
`;
