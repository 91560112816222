import React from "react";
import { sizes } from "../../theme/theme";
import { RichTextField } from "@prismicio/types";

export interface EmployerContext {
  noIndex?: boolean;
  title?: string;
  description?: string;
  url?: string;
  og_image?: {
    localfile: {
      publicURL: string;
      gatsbyImageData: any;
    };
  };
  employer_display_name?: string;
  referral_code?: string;
  employer_logo?: {
    localfile: {
      publicURL: string;
      gatsbyImageData: any;
    };
  };
  join_date?: string;
  template?: {
    id: string;
    uid: string;
  };
}

export interface InsightContent {
  tags: string;
  title: string;
  content: string | JSX.Element | React.ReactNode;
  author: string;
  readTime: number;
  link: string;
  image?: string;
  imageObjectFit?: ImageObjectFit;
}

export interface FeaturedFundsContent {
  fundName: string;
  title: string;
  content: string | JSX.Element | React.ReactNode;
  fundManager: string | JSX.Element | React.ReactNode;
  ocf: number;
  link: string;
  image?: string;
  imageObjectFit?: ImageObjectFit;
}

export interface FeaturedContentCarouselContent {
  subTitle?: string;
  withDivider?: boolean;
  title: string;
  content: string | JSX.Element | React.ReactNode;
  link?: string;
  target?: LinkTargets;
  image?: string;
  imageObjectFit?: ImageObjectFit;
  leftInfo?: string | JSX.Element | React.ReactNode;
  rightInfo?: string | JSX.Element | React.ReactNode;
}

export enum ButtonColours {
  primary = "primary",
  secondary = "secondary",
  magenta = "magenta",
  richBlue = "richBlue",
  seaBlue = "seaBlue",
  purple = "purple",
  mauve = "mauve",
  richBlack = "richBlack",
  white = "white",
  light = "light",
}

export interface ImageType {
  alt?: string;
  url?: string;
}

export interface UrlType {
  url?: string;
  target?: LinkTargets;
}

export interface InTheNewsContent {
  featured_image?: ImageType;
  imageCover: boolean;
  url?: UrlType;
  title?: string;
  description?: any;
  left_info?: string | JSX.Element | React.ReactNode;
  right_info?: string | JSX.Element | React.ReactNode;
}
export interface InTheNewsDataContent {
  data?: InTheNewsContent;
}

export type ButtonColour = keyof typeof ButtonColours;

export enum Actions {
  startInvesting = "startInvesting",
  transfer = "transfer",
  signIn = "signIn",
  talkToTILLIT = "talkToTILLIT",
}

export type Action = keyof typeof Actions;

export enum ContentAlignment {
  left = "left",
  center = "center",
}

export type ContentAlignments = keyof typeof ContentAlignment;

export enum VerticalAlignment {
  start = "flex-start",
  center = "center",
  end = "flex-end",
}

export type VerticalAlignments = keyof typeof VerticalAlignment;

export enum TextAlignment {
  left = "left",
  right = "right",
  center = "center",
  justify = "justify",
}

export type TextAlignments = keyof typeof TextAlignment;

export const RowSizeLookup = {
  Normal: sizes.lg,
  Medium: sizes.md,
  Small: "680px",
};

export type RowSizes = keyof typeof RowSizeLookup;

export enum SpacerSizing {
  normal = "normal",
  double = "double",
  none = "none",
}

export type SpacerSizings = keyof typeof SpacerSizing;

export enum TextImageLayout {
  textImage = "textImage",
  imageText = "imageText",
}

export type TextImageLayouts = keyof typeof TextImageLayout;

export enum TextVideoLayout {
  textVideo = "textVideo",
  videoText = "videoText",
}

export type TextVideoLayouts = keyof typeof TextVideoLayout;

export enum ImagePosition {
  left = "left",
  right = "right",
}

export type ImagePositions = keyof typeof ImagePosition;

export enum tableCellAlign {
  left = "left",
  center = "center",
  right = "right",
}

export interface TableColumnDefinition<T> {
  name: string;
  key: string;
  align?: keyof typeof tableCellAlign;
  headAlign?: keyof typeof tableCellAlign;
  width?: string;
}

export enum ColumnsLayout {
  single = "single",
  double = "double",
  default = "default",
}

export type ColumnsLayouts = keyof typeof ColumnsLayout;

export interface TestimonialProps {
  id: string;
  testimonial: JSX.Element | React.ReactNode | string;
  name: string;
  tag?: string;
  rating?: number;
}

export enum QuoteTextSize {
  smallQuote = "quote-small",
  quote = "quote",
  largeQuote = "quote-large",
}

export type QuoteTextSizes = keyof typeof QuoteTextSize;

export enum ImageOrder {
  top = "top",
  bottom = "bottom",
}

export type ImageOrdering = keyof typeof ImageOrder;

export const RiskWarningCopy = {
  Default: "Capital at risk.",
  "ISA risk warning":
    "Capital at risk. Tax treatment depends on your individual circumstances and may be subject to change.",
};

export enum ObjectFit {
  cover = "cover",
  contain = "contain",
}

export type ImageObjectFit = keyof typeof ObjectFit;

export enum linkTarget {
  _blank = "_blank",
  _self = "_self",
}

export type LinkTargets = keyof typeof linkTarget;
