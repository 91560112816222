import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import {
  LinkProps,
  PureInPageNavigation,
} from "../../design-system/Slices/SliceInPageNavigation/SliceInPageNavigation";

/**
 * @typedef {import("@prismicio/client").Content.InPageNavigationSlice} InPageNavigationSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<InPageNavigationSlice>} InPageNavigationProps
 * @param { InPageNavigationProps }
 */
export interface InPageNavigationProps {
  slice: any;
}

const InPageNavigation = ({ slice }: InPageNavigationProps) => {
  const links: LinkProps[] = [];

  if (slice.items.length > 0) {
    slice.items.forEach((link: any, i: number) => {
      links.push({
        id: link.link_url?.id || `${i}`,
        label: link.link_label || "",
        url: link.link_url?.url || "#0",
      });
    });
  }

  return (
    <PureInPageNavigation links={links}>
      {slice.primary.content && (
        <PrismicRichText field={slice.primary.content.richText} />
      )}
    </PureInPageNavigation>
  );
};

export default InPageNavigation;

export const query = graphql`
  fragment InPageNavigationDefault on PrismicInPageNavigationDefault {
    id
    slice_label
    slice_type
    variation
    items {
      link_label
      link_url {
        id
        url
      }
    }
    primary {
      content {
        richText
      }
    }
  }
`;
