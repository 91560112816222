import React from "react";
import { CloseIcon } from "./NavCloseIcon.style";

interface NavCloseIconProps {
  onClick: () => void;
}

export function NavCloseIcon({ onClick }: NavCloseIconProps) {
  return (
    <CloseIcon
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <circle cx="28" cy="28" r="28" fill="white" fillOpacity="0.14" />
      <circle cx="28" cy="28" r="27.5" stroke="white" strokeOpacity="0.39" />
      <path
        d="M23 33L33 23M23 23L33 33"
        stroke="white"
        strokeOpacity="0.75"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CloseIcon>
  );
}
