import styled from "styled-components";
import { Container } from "../../Container/Container";
import { colors, sizes } from "../../../../theme/theme";
import { transparentize } from "polished";
import { Text as TextWrapper } from "../_shared/Text/Text";

export const SliderTestimonialContainer = styled(Container)`
  overflow: hidden;
`;

export const SliderTestimonialInner = styled(Container)`
  .swiper {
    padding-bottom: 2.5rem;
    overflow: visible;

    .swiper-pagination {
      bottom: -5px;
      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background-color: ${({ theme }) =>
          theme.theme.page === "dark"
            ? colors.white
            : colors.richBlue} !important;
        opacity: 1 !important;
        &.swiper-pagination-bullet-active {
          background-color: ${colors.magenta} !important;
        }
      }
    }

    .swiper-button-prev {
      left: -2.5rem;
      color: ${({ theme }) =>
        theme.theme.page === "dark" ? colors.white : colors.richBlue};
      margin-top: calc(0px - (var(--swiper-navigation-size)));
    }
    .swiper-button-next {
      right: -2.5rem;
      color: ${({ theme }) =>
        theme.theme.page === "dark" ? colors.white : colors.richBlue};
      margin-top: calc(0px - (var(--swiper-navigation-size)));
    }

    .swiper-wrapper {
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        background-color: ${({ theme }) =>
          theme.theme.page === "dark"
            ? transparentize(0.95, colors.white)
            : colors.white};
        border-radius: 20px;
        padding: 2rem;
        box-shadow: ${({ theme }) => theme.shadows.medium};
        border: ${({ theme }) =>
            theme.theme.page === "dark"
              ? transparentize(0.95, colors.clouds)
              : colors.clouds}
          solid 1px;
        opacity: 0;
        transition: opacity 1.5s ease-in-out;

        &.swiper-slide-active,
        &.swiper-slide-prev,
        &.swiper-slide-next {
          opacity: 1;
        }
      }
    }
  }
`;

export const Testimonial = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 2.5rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1rem;
    width: 26px;
    height: 1px;
    background-color: ${({ theme }) =>
      theme.theme.page === "dark"
        ? colors.white
        : transparentize(0.75, colors.magenta)};
  }
`;

export const SliderTestimonialContent = styled(TextWrapper)`
  width: 100%;
  max-width: ${sizes.md};
  margin: 0 auto 2rem auto;
  text-align: center;
`;

export const TestimonialContainer = styled.div`
  position: relative;
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  border-radius: 20px;
  padding: 1.25em;
  text-align: center;
  border: ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.95, colors.clouds)
        : colors.clouds}
    solid 1px;
  background-color: ${({ theme }) =>
    theme.theme.page === "dark"
      ? transparentize(0.95, colors.white)
      : colors.white};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: calc(50% - 5rem);
    padding: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Text = styled.div`
  position: relative;
  text-align: center;
  max-width: 900px;
  strong {
    font-size: 0.75rem;
  }
`;

export const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.25rem;
  padding: 0;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 2.5rem;
  }
`;

export const Person = styled(TextWrapper)`
  margin-top: auto;
  margin-bottom: 0;
`;
