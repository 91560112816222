import styled from "styled-components";
import { HeroBackground } from "./TubeHeroBackground";
import { colors } from "../../../theme/theme";

export const Background = styled(HeroBackground)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

export const ContentContainer = styled.div`
  width: calc(100% - 70px);
  max-width: 990px;
  margin: 0 auto;
  height: auto;
  padding: 2.5rem;
  background-color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.richBlack : colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 5rem 3.5rem 3.75rem 3.5rem;
  }
`;

export const Title = styled.div`
  margin: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 3.75rem;
    line-height: auto;
  }

  h1 {
    font-size: 2.5rem;
    margin: 0;
    line-height: 3rem;
  }

  b {
    font-weight: 500;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${colors.magenta};
    }
  }
`;

export const Content = styled.div`
  text-align: center;
  max-width: 480px;
  margin: 1.625rem 0 0 0;

  span {
    color: ${colors.magenta};
  }
`;
