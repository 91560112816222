import React from "react";
import { Text } from "../Typography/Typography";
import {
  Container,
  Content,
  Footer,
  Hr,
  Image,
  ReadTime,
  Tags,
  TagsWrapper,
  Author,
  Title,
} from "./InsightCard.styles";
import { ImageObjectFit, ObjectFit } from "../../_shared";

interface InsightCardProps {
  tags: string;
  title: string;
  content: string | JSX.Element | React.ReactNode;
  author: string;
  readTime: number;
  link: string;
  image?: string;
  imageFit?: ImageObjectFit;
}

export function InsightCard({
  tags,
  title,
  content,
  author,
  readTime,
  link,
  image,
  imageFit = ObjectFit.cover,
  ...props
}: InsightCardProps) {
  return (
    <Container href={link} {...props}>
      {image && (
        <Image
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: `${imageFit}`,
          }}
        />
      )}
      <TagsWrapper>
        <Tags className="xSmall">{tags}</Tags>
      </TagsWrapper>
      <Hr />
      <Title>{title}</Title>
      <Content>{content}</Content>
      <Footer>
        <Author className="small">
          <b>By {author}</b>
        </Author>
        <ReadTime className="small">{readTime} min read</ReadTime>
      </Footer>
    </Container>
  );
}
