import React from "react";
import { graphql } from "gatsby";
import { TextAlignment } from "../../_shared";
import { colors } from "../../../theme/theme";
import {
  PureFullWidthTextOnImage,
  PureSimpleTextOnImage,
} from "../../design-system/Slices/SliceTextOnImage/SliceTextOnImage";
import SliceFooterActions from "../SliceFooterActions";
import { useBreakpointMdDown } from "../../../hooks/useBreakpoint";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.CallToActionSlice} CallToActionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<CallToActionSlice>} CallToActionProps
 * @param { TextOnImageProps }
 */
export interface TextOnImageProps {
  slice: any;
  context?: any;
}

const TextOnImage = ({ slice, context, ...props }: TextOnImageProps) => {
  const isMdDown = useBreakpointMdDown();
  const variant = slice.variation;
  const minHeight = slice.primary.minimum_height;
  const hasFooterOptions = slice.primary.cta_options?.document;
  const contentAlign =
    slice.primary?.content_alignment || isMdDown
      ? TextAlignment.center
      : TextAlignment.left;
  const content = slice.primary.content ? (
    <CustomRichText field={slice.primary.content.richText} context={context} />
  ) : (
    <></>
  );
  const actions =
    hasFooterOptions &&
    slice.primary.cta_options.document.data.slices.length > 0 ? (
      <SliceFooterActions
        slice={slice.primary.cta_options.document.data.slices[0]}
        align={contentAlign}
        context={context}
      />
    ) : (
      <></>
    );
  const bgColor = slice.primary.background_colour
    ? (colors as { [index: string]: string })[slice.primary.background_colour]
    : colors.richBlack;
  const belowCta = (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning}
      content={slice.primary.below_cta_content?.richText || null}
      $pad="top"
      context={context}
    />
  );
  const darkBackground = slice.primary.dark_background;
  const bgImage =
    slice.primary?.large_background_image?.localFile?.childImageSharp?.fixed
      ?.src || "";
  const mobBgImage =
    slice.primary?.small_background_image?.localFile?.childImageSharp?.fixed
      ?.src || "";
  const CTAProps = {
    content,
    actions,
    belowCta,
    bgColor,
    darkBackground,
    bgImage,
    mobBgImage,
    ...props,
  };

  if (variant === "fullWidth") {
    return (
      <PureFullWidthTextOnImage
        $minHeight={minHeight}
        $align={contentAlign}
        contentWidth={slice.primary.content_width || "narrow"}
        {...CTAProps}
      />
    );
  } else {
    return (
      <PureSimpleTextOnImage
        $minHeight={minHeight}
        $align={contentAlign}
        {...CTAProps}
      />
    );
  }
};

export default TextOnImage;

export const query = graphql`
  fragment TextOnImageDefault on PrismicTextOnImageDefault {
    id
    variation
    slice_type
    slice_label
    primary {
      minimum_height
      background_colour
      content_alignment
      dark_background
      content {
        richText
      }
      large_background_image {
        url
        localFile {
          childImageSharp {
            fixed(
              width: 1920
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              srcWebp
              originalName
              src
            }
          }
        }
      }
      small_background_image {
        url
        localFile {
          childImageSharp {
            fixed(
              width: 960
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              srcWebp
              originalName
              src
            }
          }
        }
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment TextOnImageFullWidth on PrismicTextOnImageFullWidth {
    id
    variation
    slice_type
    slice_label
    primary {
      minimum_height
      background_colour
      content_width
      content_alignment
      dark_background
      content {
        richText
      }
      large_background_image {
        localFile {
          childImageSharp {
            fixed(
              width: 1920
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              srcWebp
              originalName
              src
            }
          }
        }
      }
      small_background_image {
        localFile {
          childImageSharp {
            fixed(
              width: 960
              quality: 80
              jpegQuality: 80
              pngQuality: 80
              webpQuality: 95
              toFormat: AUTO
            ) {
              aspectRatio
              srcWebp
              originalName
              src
            }
          }
        }
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
`;
