import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useIsClient, useSessionStorage } from "usehooks-ts";
import {
  DismissButton,
  ResumeOnboardingNudgeContainer,
  ResumeOnboardingNudgeCopy,
  ResumeOnboardingNudgeHead,
  ResumeOnboardingNudgeHeading,
} from "./ResumeOnboardingNudge.styles";
import { GaEventNames, trackGa } from "../../helpers/track";
import { AccountType, useOnboardingStatus } from "../../hooks/useOnboarding";
import { getNameForAccountType } from "../../hooks/accountHelper";
import { CustomButton } from "../design-system/Buttons/Button";
import { useIsUserAuth } from "../../hooks/useIsUserAuth";
import { signInPath, signUpPath } from "../../paths";
import { Button } from "../design-system/Buttons";

export function ResumeOnboardingNudge() {
  const isClient = useIsClient();
  const signedIn = useIsUserAuth();
  const [dismissed, setDismissed] = useSessionStorage(
    "dismissed_resume_onboarding_nudge",
    false
  );
  const { onboardingStatus, onboardingAccountType } = useOnboardingStatus();

  // Ensure the initial render is the same on the server and client:
  if (!isClient) {
    return null;
  }

  const initialAccountName = getNameForAccountType(
    onboardingAccountType as AccountType
  );

  const handleDismiss = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: "cta button",
      item_id: "finish setup dismissed - nudge",
    });
    setDismissed(true);
  };

  const handleResume = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: "cta button",
      item_id: "finish setup - nudge",
    });
    setDismissed(true);
  };

  return onboardingStatus && !dismissed ? (
    <ResumeOnboardingNudgeContainer className="dark-background">
      <ResumeOnboardingNudgeHead>
        <ResumeOnboardingNudgeHeading>
          Finish setting up your account
        </ResumeOnboardingNudgeHeading>
        <DismissButton aria-label="Close dialog" onClick={handleDismiss}>
          <AiOutlineClose />
        </DismissButton>
      </ResumeOnboardingNudgeHead>

      <ResumeOnboardingNudgeCopy>
        To start investing, browse more TILLIT Views or watch our exclusive Meet
        the Manager video interviews, please finish setting up your{" "}
        {initialAccountName}.
      </ResumeOnboardingNudgeCopy>
      <Button
        as="a"
        href={signUpPath}
        $color="primary"
        variant="contained"
        target="_self"
        onClick={handleResume}
      >
        Resume
      </Button>
    </ResumeOnboardingNudgeContainer>
  ) : null;
}
