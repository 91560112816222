import React from "react";
import { Text } from "../Typography/Typography";
import {
  Container,
  Content,
  Footer,
  Hr,
  Image,
  SubTitle,
  Title,
  SubTitleWrapper,
  Info1,
  Info2,
} from "./FeaturedContentCard.styles";
import { FeaturedContentCarouselContent, ObjectFit } from "../../_shared";
import numeral from "numeral";

interface FeaturedContentCardProps extends FeaturedContentCarouselContent {}

export function FeaturedContentCard({
  subTitle,
  withDivider = true,
  title,
  content,
  link,
  target,
  image,
  leftInfo,
  rightInfo,
  imageObjectFit = ObjectFit.cover,
  ...props
}: FeaturedContentCardProps) {
  return (
    <Container href={link} target={target} {...props}>
      {image && (
        <Image
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: `${imageObjectFit}`,
          }}
        />
      )}
      {subTitle && (
        <SubTitleWrapper>
          <SubTitle className="small">{subTitle}</SubTitle>
        </SubTitleWrapper>
      )}
      {withDivider && <Hr />}
      <Title>{title}</Title>
      {content && <Content>{content}</Content>}
      <Footer multiCol={leftInfo && rightInfo ? true : false}>
        {leftInfo && <Info1 className="small">{leftInfo}</Info1>}
        {rightInfo && <Info2 className="small">{rightInfo}</Info2>}
      </Footer>
    </Container>
  );
}
