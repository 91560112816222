import styled from "styled-components";
import { colors } from "../../../../theme/theme";

export const SliceContainer = styled.div`
  max-width: 100%;
  background: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.richBlack : colors.white};
  > section {
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;
