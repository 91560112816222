import styled from "styled-components";
import { SpacerSizing, SpacerSizings } from "../../../_shared";

export interface StyledSpacerProps {
  $size: SpacerSizings;
  $mobSize: SpacerSizings;
}

const handleSpacingSmall = (spacing: SpacerSizings) => {
  switch (spacing) {
    case SpacerSizing.none:
      return "0";
    case SpacerSizing.double:
      return "60px";
    default:
      return "30px";
  }
};

const handleSpacingMed = (spacing: SpacerSizings) => {
  switch (spacing) {
    case SpacerSizing.none:
      return "0";
    case SpacerSizing.double:
      return "80px";
    default:
      return "40px";
  }
};

const handleSpacingLarge = (spacing: SpacerSizings) => {
  switch (spacing) {
    case SpacerSizing.none:
      return "0";
    case SpacerSizing.double:
      return "96px";
    default:
      return "48px";
  }
};

export const StyledSpacer = styled.div<StyledSpacerProps>`
  position: relative;
  width: 100%;
  height: ${({ $mobSize }) => handleSpacingSmall($mobSize)};

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: ${({ $size }) => handleSpacingMed($size)};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    height: ${({ $size }) => handleSpacingLarge($size)};
  }
`;
