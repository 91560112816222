import React, { useMemo } from "react";
import { Container } from "../Container/Container";
import { Box } from "../Box/Box";
import { StyledTable, Head, Row, TH, TD } from "./Table.styles";
import { TableColumnDefinition } from "../../_shared";
import { get, uniqueId } from "lodash";

export interface TableProps<T> {
  columns: Array<TableColumnDefinition<T>>;
  data: Array<T>;
}

export function Table<T>({ data, columns, ...props }: TableProps<T>) {
  const rows: Array<T & { uniqueId: string }> = useMemo(
    () => data.map((d) => ({ ...d, uniqueId: uniqueId() })),
    [data]
  );

  return (
    <StyledTable>
      <Head>
        {columns.map((col) => (
          <TH
            key={col.name}
            $align={col.headAlign ?? col.align ?? "right"}
            $width={col.width ?? "auto"}
          >
            {col.name}
          </TH>
        ))}
      </Head>
      <tbody>
        {rows &&
          rows.map((row) => (
            <Row key={row.uniqueId}>
              {columns.map((col) => (
                <TD key={col.name} $align={col.align ?? "right"}>
                  {get(row, col.key)}
                </TD>
              ))}
            </Row>
          ))}
      </tbody>
    </StyledTable>
  );
}
