import React from "react";
import { Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { InsightCard } from "../../InsightCard/InsightCard";
import { Container } from "../../Container/Container";
import {
  SliceInsightsContainer,
  InsightWrapper,
  ContentContainer,
} from "./SliceFeaturedInsights.styles";
import { InsightContent } from "../../../_shared";

interface PureFeaturedInsightsProps {
  insights: InsightContent[];
  children: JSX.Element | React.ReactNode | string;
}

export function PureFeaturedInsights({
  insights,
  children,
  ...props
}: PureFeaturedInsightsProps) {
  return (
    <Container fullWidth={false} {...props}>
      <SliceInsightsContainer>
        {children && <ContentContainer>{children}</ContentContainer>}
        <InsightWrapper>
          <Swiper
            watchOverflow
            spaceBetween={32}
            slidesPerView="auto"
            loop
            breakpoints={{
              200: { slidesPerView: 1 },
              960: { slidesPerView: 2 },
              1280: { slidesPerView: 3 },
            }}
            pagination={{ clickable: true }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, Keyboard, Pagination]}
          >
            {insights.map((insight) => (
              <SwiperSlide key={insight.title}>
                <InsightCard
                  tags={insight.tags}
                  title={insight.title}
                  content={insight.content}
                  author={insight.author}
                  readTime={insight.readTime}
                  link={insight.link}
                  image={insight.image}
                  imageFit={insight.imageObjectFit}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </InsightWrapper>
      </SliceInsightsContainer>
    </Container>
  );
}
