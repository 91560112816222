import React, { useState } from "react";
import { RangeInput } from "./RangeField.styles";

export interface RangeFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  min?: number;
  max?: number;
  val?: number;
  step?: number;
  onValChange: (val: string) => void;
}

export function RangeField({
  min = 100,
  max = 100000,
  val = max / 2,
  step = min,
  onValChange,
  ...props
}: RangeFieldProps) {
  const calculatePercentage = (
    value: number,
    minVal: number,
    maxVal: number
  ) => {
    return ((value - minVal) * 100) / (maxVal - minVal);
  };

  const [value, setValue] = useState(val.toString());
  const [bgSize, setBgSize] = useState(
    calculatePercentage(val, min, max) + "0% 100%"
  );

  const handleChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const currentVal = e.currentTarget.value;
    setValue(currentVal);
    setBgSize(calculatePercentage(parseInt(currentVal), min, max) + "% 100%");
    onValChange(currentVal);
  };

  return (
    <RangeInput
      type="range"
      min={min.toString()}
      max={max.toString()}
      step={step.toString()}
      value={value}
      onChange={handleChangeValue}
      style={{ backgroundSize: bgSize }}
      {...props}
    />
  );
}
