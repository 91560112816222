import { colors } from "../../../theme/theme";
import IconButton from "@mui/material/IconButton";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

const FixedHeaderStyles = css`
  position: fixed;
  top: 0;
  left: 0;
`;
const RelativeHeaderStyles = css`
  position: relative;
`;

const baseHeaderStyling = css`
  width: 100%;
  height: ${({ theme }) => theme.header.height};
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
`;

const baseShadow = css`
  ${({ theme }) =>
    theme.theme.page === "light" &&
    css`
      box-shadow: ${theme.shadows.navBar};
    `}
`;

export const CloseIcon = styled.svg`
  margin-top: auto;
  width: 100%;
  height: 5rem;
  padding-top: 2rem;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

export const HeaderShimContainer = styled.div`
  ${baseHeaderStyling};
  span {
    width: 100%;
    display: block;
  }
`;

interface HeaderContainerProps {
  $navDrawerOpen: boolean;
}
export const HeaderContainer = styled.div<HeaderContainerProps>`
  ${FixedHeaderStyles};
  opacity: 1;
  transform: ${({ $navDrawerOpen }) =>
    $navDrawerOpen ? "none" : "translateY(0)"};

  background-color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.richBlack : colors.white};
  z-index: ${({ theme }) => theme.zIndex.topBar};

  transition: ${({ $navDrawerOpen }) =>
    $navDrawerOpen
      ? "none"
      : "transform 0.25s ease-in-out 0s, opacity 0.25s ease-in-out 0s;"};

  ${baseHeaderStyling}
  ${baseShadow}

    ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 0 1.875rem;
  }
`;

export const RelativeHeaderContainer = styled.div`
  ${RelativeHeaderStyles};
  background-color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.richBlack : colors.white};
  ${baseHeaderStyling}
  ${baseShadow}

    ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 1.5rem 1.875rem;
  }

  &.scroll-down {
    transform: translateY(-100%);
  }
`;

export interface CustomHeaderContainerProps {
  $isFixed: boolean;
}

export const CustomHeaderContainer = styled.div<CustomHeaderContainerProps>`
  ${({ $isFixed }) => ($isFixed ? FixedHeaderStyles : RelativeHeaderStyles)}
  background-color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.richBlack : colors.white};
  ${baseHeaderStyling}
  ${baseShadow}
    z-index: 9;

  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 1.5rem 1.875rem;
  }
`;

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 10px;

  ${({ theme }) => theme.breakpoints.up("md")} {
    gap: 0 30px;
  }
`;

export const LogoContainer = styled.a`
  margin-right: auto;
  width: 5.25rem;
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.white : colors.richBlack}; ;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 8px;

  // TODO: potentially move this style to the main button styles as website buttons are now all like this
  button.small,
  a.small {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 0 !important;
    font-size: 0.625rem;
    ${({ theme }) => theme.breakpoints.up("sm")} {
      padding: 0.25rem 1.875rem;
      min-width: 160px;
      font-size: 0.875rem;
    }
  }
`;

export const NavToggle = styled(IconButton)`
  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;

export interface MobileNavContainerProps {
  $open: boolean;
}

export const MobileNavContainer = styled.div<MobileNavContainerProps>`
  display: contents;
  opacity: 0;

  ${({ theme }) => theme.breakpoints.down("md")} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7rem 1.5rem 5.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    background-color: ${transparentize(0.5, colors.richBlack)};
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s ease-in-out;
    z-index: ${({ theme }) => theme.zIndex.topBar + 1};

    ${({ theme }) => theme.breakpoints.up("sm")} {
      padding: 8rem 1.875rem 5.5rem 1.875rem;
    }

    ${({ $open }) =>
      $open &&
      css`
        opacity: 1;
        pointer-events: auto;
      `}
  }
`;

MobileNavContainer.defaultProps = {
  $open: false,
};
