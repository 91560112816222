import styled from "styled-components";
import { colors } from "../../../theme/theme";
import { Box } from "../Box/Box";
import { transparentize } from "polished";

export const CalculatorBox = styled(Box)`
  padding: 3rem 2.5rem;
`;

export const RangeContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1.375rem;
`;

export const CalculatorInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Legend = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-bottom: -0.5rem;
  color: ${transparentize(0.25, colors.richBlack)};
`;

export const Label = styled.label`
  display: block;
  color: ${colors.richBlue};
  font-weight: 500;
  font-size: 1rem;
  span {
    display: block;
    margin-top: -0.125rem;
    font-weight: 400;
    color: ${colors.richBlack};
    font-size: 0.75rem;
    font-size: 0.625rem;

    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
`;

export const Fee = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 1.375rem;
  color: ${colors.richBlue};
  margin-bottom: 0.5rem;
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -0.125rem;
    width: 20px;
    height: 1px;
    background-color: ${colors.magenta};
    margin-left: -10px;
  }
`;

export const Year = styled.div`
  font-size: 0.75rem;
  color: ${colors.richBlue};
  font-weight: 400;
`;
