import styled from "styled-components";
import { H6 } from "../../design-system/Typography/headings/Headings";
import { colors } from "../../../theme/theme";
import { Text } from "../../design-system/Slices/_shared/Text/Text";
import { TextAlignments } from "../../_shared/index";

export interface ContainerProps {
  $align: TextAlignments;
  $mdUpAlign?: TextAlignments;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  width: 100%;
  text-align: ${({ $align }) => $align};
  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ $mdUpAlign }) => ($mdUpAlign ? `text-align: ${$mdUpAlign}` : "")};
  }
`;

Container.defaultProps = {
  $align: "center",
};

export const ContentWrapper = styled(Text)`
  margin-top: 0;
  margin-bottom: 10px;
`;
