import React from "react";
import { useTheme } from "styled-components";
import { TextRowContainer, TextRowInner } from "./SliceTextRow.styles";
import { Padding } from "../../Container/Container";
import {
  ContentAlignment,
  ContentAlignments,
  RowSizeLookup,
} from "../../../_shared";

export interface PureTextRowProps {
  isFullWidth: boolean;
  mdUpAlign?: ContentAlignments;
  alignment?: ContentAlignments;
  rowSize?: string;
  withBorder?: boolean;
  padding?: Padding;
  children: JSX.Element | React.ReactNode | string;
}

export function PureTextRow({
  isFullWidth,
  mdUpAlign,
  alignment = ContentAlignment.center,
  rowSize = RowSizeLookup.Normal,
  withBorder = false,
  padding = Padding.both,
  children,
  ...props
}: PureTextRowProps) {
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <TextRowContainer
      fullWidth={isFullWidth}
      padding={padding}
      $align={alignment}
      $mdUpAlign={mdUpAlign}
      $rowSize={rowSize}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <TextRowInner $border={withBorder} className={`align-${alignment}`}>
        {children}
      </TextRowInner>
    </TextRowContainer>
  );
}
