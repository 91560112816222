import React, { useRef, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { AiOutlineMenu } from "react-icons/ai";
import { useBreakpointMdUp } from "../../../hooks/useBreakpoint";
import { TillitLogoV2 } from "../../design-system/TillitLogo/TillitLogo";
import { BodyOverflow } from "../../BodyOverFlow/BodyOverflow";
import { NavCloseIcon } from "../../design-system/CustomIcons/NavCloseIcon/NavCloseIcon";
import { CustomNavProps, CustomNavigation } from "./Navigation";
import {
  ActionsContainer,
  CustomHeaderContainer,
  HeaderInner,
  LogoContainer,
  MobileNavContainer,
} from "./Header.styles";
import {
  ContextualActions,
  SignUpAction,
  StaticActions,
} from "./ContextualActions";

export enum HeaderActions {
  signUp = "signUp",
  static = "static",
  dynamic = "dynamic",
}

export enum HeaderPosition {
  Relative = "Relative",
  Fixed = "Fixed",
}

export interface HeaderCustomNavProps {
  position: HeaderPosition;
  actions?: keyof typeof HeaderActions;
  nav?: CustomNavProps[];
}

export function HeaderCustomNav({
  position = HeaderPosition.Fixed,
  actions,
  nav,
  ...props
}: HeaderCustomNavProps) {
  const mobNavRef = useRef(null);
  const [navDrawer, setNavDrawer] = useState(false);
  const aboveLaptop = useBreakpointMdUp();

  const handleDrawer = (isOpen: boolean) => {
    setNavDrawer(isOpen);
  };

  const handleCloseMobNav = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target === mobNavRef.current) setNavDrawer(false);
  };

  const handleClickCloseMobNav = () => {
    setNavDrawer(false);
  };

  useEffect(() => {
    if (aboveLaptop) setNavDrawer(false);
  }, [aboveLaptop]);

  return (
    <>
      <BodyOverflow overflowHidden={navDrawer} />
      <CustomHeaderContainer
        $isFixed={position === HeaderPosition.Fixed}
        className={`app--header`}
        {...props}
      >
        <HeaderInner>
          <LogoContainer href="/">
            <TillitLogoV2 compact="onMobile" tooltip="Back to home" />
          </LogoContainer>

          {aboveLaptop && nav && (
            <>
              <CustomNavigation nav={nav} />
            </>
          )}

          {actions && (
            <ActionsContainer>
              {actions === HeaderActions.dynamic && <ContextualActions />}
              {actions === HeaderActions.signUp && <SignUpAction />}
              {actions === HeaderActions.static && <StaticActions />}
            </ActionsContainer>
          )}

          {!aboveLaptop && nav?.length && (
            <IconButton
              color="inherit"
              aria-label="Open main navigation"
              onClick={() => handleDrawer(true)}
            >
              <AiOutlineMenu />
            </IconButton>
          )}
        </HeaderInner>
      </CustomHeaderContainer>

      {!aboveLaptop && nav?.length && (
        <MobileNavContainer
          $open={navDrawer}
          ref={mobNavRef}
          onClick={handleCloseMobNav}
        >
          <CustomNavigation extraClass="header--mobileNav" nav={nav} />
          <NavCloseIcon onClick={handleClickCloseMobNav} />
        </MobileNavContainer>
      )}
    </>
  );
}
