import React from "react";
import { HiOutlineStar, HiStar } from "react-icons/hi";
import { ImStarEmpty, ImStarHalf, ImStarFull } from "react-icons/im";
import { RatingContainer } from "./Rating.styles";

export interface RatingProps {
  rating: number;
}

export function Rating({ rating = 0, ...props }: RatingProps) {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    const star =
      i <= rating ? (
        <ImStarFull key={i} />
      ) : i - rating === 0.5 ? (
        <ImStarHalf key={i} />
      ) : (
        <ImStarEmpty key={i} />
      );
    stars.push(star);
  }

  return (
    <RatingContainer {...props}>{stars.map((star) => star)}</RatingContainer>
  );
}
