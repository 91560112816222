import React from "react";
import FeaturedSlices from "../FeaturedSlices";
import { graphql } from "gatsby";

/**
 * @typedef {import("@prismicio/client").Content.FeaturedContentSelectorSlice} FeaturedContentSelectorSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FeaturedContentSelectorSlice>} FeaturedContentSelectorProps
 * @param { FeaturedContentSelectorProps }
 */
export interface FeaturedContentSelectorProps {
  slice: any;
}

const FeaturedContentSelector = ({
  slice,
  ...props
}: FeaturedContentSelectorProps) => {
  const hasFeaturedSlice = slice.primary.featured_slice.document;
  const sliceHasContent =
    hasFeaturedSlice &&
    slice.primary.featured_slice.document.data.slices.length > 0;

  if (sliceHasContent) {
    return (
      <FeaturedSlices
        slice={slice.primary.featured_slice.document.data.slices[0]}
        {...props}
      />
    );
  } else {
    return <></>;
  }
};

export default FeaturedContentSelector;

export const query = graphql`
  fragment FeaturedContentSelectorDefault on PrismicFeaturedContentSelectorDefault {
    id
    slice_type
    slice_label
    variation
    primary {
      featured_slice {
        id
        document {
          ... on PrismicFeaturedContent {
            id
            type
            data {
              slices {
                ... on PrismicFeaturedSlicesFscs {
                  id
                  slice_label
                  slice_type
                  variation
                  primary {
                    content {
                      richText
                    }
                  }
                }
                ... on PrismicFeaturedSlicesFeeCalculator {
                  id
                  slice_label
                  slice_type
                  variation
                  primary {
                    calculator_placement
                    content {
                      richText
                    }
                  }
                }
                ... on PrismicFeaturedSlicesDarkVsTillitUniverseTable {
                  id
                  slice_label
                  slice_type
                  variation
                  items {
                    dark_universe_assets
                    row_title
                    tillit_universe_assets
                  }
                }
                ... on PrismicFeaturedSlicesAssetEligibilityTable {
                  id
                  slice_label
                  slice_type
                  variation
                  items {
                    asset_eligibility
                    row_title
                  }
                }
                ... on PrismicFeaturedSlicesUniversePerformance {
                  id
                  slice_label
                  slice_type
                  variation
                  primary {
                    below_cta_content {
                      richText
                    }
                    cta_options {
                      id
                      uid
                      document {
                        ...callToActions
                      }
                    }
                  }
                }
                ... on PrismicFeaturedSlicesTransferConcierge {
                  id
                  slice_label
                  slice_type
                  variation
                  primary {
                    content {
                      richText
                    }
                    cta_options {
                      id
                      uid
                      document {
                        ...callToActions
                      }
                    }
                    risk_warning
                    below_cta_content {
                      richText
                    }
                  }
                  items {
                    testimonials {
                      id
                      uid
                      document {
                        ...testimonialType
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
