import React from "react";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import { PureTimeline } from "../../design-system/Slices/SliceTimeline/SliceTimeline";
import SliceFooterActions from "../SliceFooterActions";
import { graphql } from "gatsby";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.TimeLineSlice} TimeLineSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TimeLineSlice>} TimeLineProps
 * @param { TimeLineProps }
 */

export interface TimeLineProps {
  slice: any;
  context?: any;
}

const TimeLine = ({ slice, context }: TimeLineProps) => {
  const hasFooterOptions = slice.primary.cta_options.document;

  const disclosureAndRiskWarning = (slice.primary.risk_warning ||
    slice.primary.below_cta_content?.richText.length > 0) && (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning}
      content={slice.primary.below_cta_content?.richText || null}
      $pad="top"
      context={context}
    />
  );

  const callToActionContent = (
    <>
      {hasFooterOptions &&
        slice.primary.cta_options.document.data.slices.length > 0 && (
          <SliceFooterActions
            slice={slice.primary.cta_options.document.data.slices[0]}
            align="center"
            mdUpAlign="center"
            context={context}
          />
        )}

      {disclosureAndRiskWarning}
    </>
  );

  return (
    <PureTimeline
      rows={slice.items.map((element: any, index: number) => {
        return (
          <CustomRichText
            key={index}
            field={element.timeline_content.richText}
            context={context}
          />
        );
      })}
      callToActions={callToActionContent}
    >
      {slice.primary.description && (
        <CustomRichText
          field={slice.primary.description.richText}
          context={context}
        />
      )}
    </PureTimeline>
  );
};

export default TimeLine;

export const query = graphql`
  fragment TimelineDefault on PrismicTimeLineDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      risk_warning
      below_cta_content {
        richText
      }
      description {
        richText
      }
      cta_options {
        document {
          ...callToActions
        }
        id
        uid
      }
    }
    items {
      timeline_content {
        richText
      }
    }
  }
`;
