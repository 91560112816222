import React from "react";
import { graphql } from "gatsby";
import { AllFeaturedInTheNewsSkipLatestSlice } from "./AllFeaturedInTheNewsSkipLatestSlice";
import { FeaturedInTheNewsSlice } from "./FeaturedInTheNewsSlice";
import { AllFeaturedInTheNewsSlice } from "./AllFeaturedInTheNews";

/**
 * @typedef {import("@prismicio/client").Content.InTheNewsSliceSlice} InTheNewsSliceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<InTheNewsSliceSlice>} InTheNewsSliceProps
 * @param { InTheNewsSliceProps }
 */

export interface InTheNewsSliceProps {
  slice: any;
}

function InTheNewsSlice({ slice }: InTheNewsSliceProps) {
  const variation = slice.variation || "default";
  const skipLatest =
    slice.variation === "default" && slice.primary.hide_latest ? 1 : 0;
  if (variation === "featured") {
    return <FeaturedInTheNewsSlice slice={slice} />;
  }

  if (variation === "default" && skipLatest > 0) {
    return <AllFeaturedInTheNewsSkipLatestSlice slice={slice} />;
  }

  return <AllFeaturedInTheNewsSlice slice={slice} />;
}

export default InTheNewsSlice;

export const query = graphql`
  fragment InTheNewsSliceDefault on PrismicInTheNewsSliceDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      posts_per_page
      hide_latest
      show_divider
      use_images_as_cover
      show_load_more_button
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
    }
  }

  fragment InTheNewsSliceFeatured on PrismicInTheNewsSliceFeatured {
    id
    slice_label
    slice_type
    variation
    primary {
      featured_post {
        id
        uid
        document {
          ...inTheNewsType
        }
      }
    }
  }
`;
