import React from "react";
import { Keyboard, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { InsightCard } from "../../InsightCard/InsightCard";
import { Container } from "../../Container/Container";
import {
  SliceInsightsContainer,
  InsightWrapper,
  ContentContainer,
} from "./SliceFeaturedFunds.styles";
import { FeaturedFundsContent } from "../../../_shared";
import { FundCard } from "../../FundCard/FundCard";

interface PureFeaturedFundsProps {
  funds: FeaturedFundsContent[];
  children: JSX.Element | React.ReactNode | string;
}

export function PureFeaturedFunds({
  funds,
  children,
  ...props
}: PureFeaturedFundsProps) {
  return (
    <Container fullWidth={false} {...props}>
      <SliceInsightsContainer>
        {children && <ContentContainer>{children}</ContentContainer>}
        <InsightWrapper>
          <Swiper
            watchOverflow
            spaceBetween={32}
            slidesPerView="auto"
            loop
            breakpoints={{
              200: { slidesPerView: 1 },
              960: { slidesPerView: 2 },
              1280: { slidesPerView: 3 },
            }}
            pagination={{ clickable: true }}
            keyboard={{
              enabled: true,
            }}
            modules={[Navigation, Keyboard, Pagination]}
          >
            {funds.map((fund) => (
              <SwiperSlide key={fund.title}>
                <FundCard
                  fundName={fund.fundName}
                  title={fund.title}
                  content={fund.content}
                  fundManager={fund.fundManager}
                  ocf={fund.ocf}
                  link={fund.link}
                  image={fund.image}
                  imageObjectFit={fund.imageObjectFit}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </InsightWrapper>
      </SliceInsightsContainer>
    </Container>
  );
}
