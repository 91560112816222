import { graphql, useStaticQuery } from "gatsby";
import { InTheNewsSliceProps } from "./index";
import React from "react";
import { Image as StyledImage } from "../../design-system/Image/Image";
import { FeaturedInTheNews } from "../../design-system/Slices/SliceFeaturedInTheNews/FeaturedInTheNews";
import CustomRichText from "../../CustomRichText";

interface FeaturedInTheNewsSliceProps extends InTheNewsSliceProps {}

export function FeaturedInTheNewsSlice({ slice }: FeaturedInTheNewsSliceProps) {
  const hasSelectedArticle = slice.primary.featured_post.document;
  let article;
  if (hasSelectedArticle) {
    article = hasSelectedArticle.data;
  } else {
    const articleQuery = useStaticQuery(graphql`
      {
        allPrismicInTheNews(
          sort: { order: DESC, fields: data___published_date }
          limit: 1
        ) {
          nodes {
            data {
              title
              url {
                url
                target
              }
              featured_image {
                alt
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 492
                      formats: [AUTO, WEBP, AVIF]
                      quality: 80
                      webpOptions: { quality: 95 }
                      jpgOptions: { quality: 80 }
                    )
                  }
                }
              }
              description {
                richText
              }
              published_date
            }
          }
        }
      }
    `);
    article = articleQuery.allPrismicInTheNews.nodes[0].data;
  }

  if (!article) return null;
  return (
    <FeaturedInTheNews
      children={
        <>
          {article.title && <h3>{article.title}</h3>}
          {article.description.richText && (
            <CustomRichText field={article.description.richText} />
          )}
        </>
      }
      mobImgOrder="bottom"
      image={
        article.featured_image.localFile?.childImageSharp ? (
          <StyledImage
            image={article.featured_image.localFile?.childImageSharp}
            withShadows={true}
            rounded={true}
          />
        ) : (
          <></>
        )
      }
      url={article.url.url}
      openInNewTab={article.url.article || "_blank"}
      layout="imageText"
    />
  );
}
