import styled from "styled-components";
import { ContentAlignments } from "../../_shared";

export interface ButtonWrapperProps {
  $align?: ContentAlignments;
  $mdUpAlign?: ContentAlignments;
}
export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: ${({ $align }) => $align || "center"};
  flex-direction: column;
  margin: 2.5rem 0 0.75rem 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    justify-content: ${({ $align, $mdUpAlign }) =>
      $mdUpAlign ? $mdUpAlign : $align || "center"};
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
  }
`;
