import styled, { css } from "styled-components";
import { FiChevronDown } from "react-icons/fi";
import { HiOutlinePlusSm, HiMinusSm } from "react-icons/hi";
import { colors } from "../../../theme/theme";
import { transparentize } from "polished";

const IconArea = "30px";

interface collapseProps {
  collapsed: boolean;
}

export const AccordionWrapper = styled.div`
  border-bottom: solid 1px
    ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.85, colors.white)
        : colors.clouds};
  padding: 0;
`;

export const Question = styled.div`
  margin: 0;
  display: grid;
  grid-template-columns: 1fr ${IconArea};
  column-gap: 5px;
  align-items: center;
  padding: 1.25rem 0;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.up("md")} {
    padding: 1.5rem 0;
  }
  > * {
    color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.white : colors.richBlue};
  }
`;

interface IconProps extends collapseProps {}

export const Chevron = styled(FiChevronDown)<IconProps>`
  transform: ${({ collapsed }) =>
    collapsed ? "rotate(0deg)" : "rotate(180deg)"};

  ${({ theme }) =>
    theme.theme.page === "dark" &&
    css`
      stroke: ${theme.colors.white};
    `}

  ${({ theme, collapsed }) =>
    theme.theme.page === "light" &&
    css`
      stroke: ${collapsed ? theme.colors.richBlue : theme.colors.magenta};
    `}
    
    transition: transform 0.5s, stroke 0.5s;
  justify-self: end;
  align-self: center;
  margin-top: 2px;
  font-size: 24px;
  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: 28px;
    margin-top: 0;
  }
`;

export const Plus = styled.div<IconProps>`
  position: relative;
  width: 16px;
  height: 16px;

  &:before,
  &:after {
    position: absolute;
    top: 7px;
    left: 0;
    content: "";
    width: 16px;
    height: 2px;
    border-radius: 2px;
    background-color: ${({ theme }) =>
      theme.theme.page === "dark" ? colors.white : colors.richBlack};
    transition: transform 0.25s linear;
  }
  &:before {
    transform: ${({ collapsed }) =>
      collapsed ? "rotate(180deg)" : "rotate(0deg)"};
  }
  &:after {
    transform: ${({ collapsed }) =>
      collapsed ? "rotate(270deg)" : "rotate(0deg)"};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    width: 20px;
    height: 20px;

    &:before,
    &:after {
      top: 8px;
      width: 20px;
      height: 3px;
    }
  }
`;

interface AnswerProps extends collapseProps {}

export const Answer = styled.div<AnswerProps>`
  max-height: ${({ collapsed }) => (collapsed ? "0px" : "2000px")};
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  transition: all 0.5s ease-in-out;
  padding-right: ${IconArea};
  overflow: hidden;
  & p {
    margin: 0 0 1rem 0;
  }
  & p:last-child {
    margin: 0 0 2rem 0;
  }
`;
