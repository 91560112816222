import React from "react";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import { ColHeadWrapper, No, Yes } from "../_shared/FeaturedSlides.styles";
import { TableColumnDefinition } from "../../../_shared";
import { PureFeaturedTable } from "../../../design-system/Slices/SliceFeaturedTable/SliceFeaturedTable";

export interface AssetEligibilityTableProps {
  slice: any;
}

export function AssetEligibilityTable({
  slice,
  ...props
}: AssetEligibilityTableProps) {
  const isMdUp = useBreakpointMdUp();

  const cols = [
    {
      name: "Asset type",
      key: "title",
      align: "left",
      width: isMdUp ? "80%" : "50%",
    },
    {
      name: (
        <ColHeadWrapper>
          <p>
            Accepted <Yes />
          </p>
          <p>
            Rejected <No />
          </p>
        </ColHeadWrapper>
      ),
      key: "acceptReject",
      align: isMdUp ? "center" : "right",
      headAlign: "center",
    },
  ] as Array<TableColumnDefinition<any>>;

  const rows = [];

  if (slice.items.length > 0) {
    slice.items.forEach((row: any) => {
      rows.push({
        title: row.row_title || "",
        acceptReject: row.asset_eligibility === "yes" ? <Yes /> : <No />,
      });
    });
  } else {
    rows.push({});
  }

  return <PureFeaturedTable columns={cols} data={rows} {...props} />;
}
