import styled from "styled-components";

export const ContainerInner = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 1.75rem 0;
  align-items: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: start;
    justify-items: start;
    gap: 0 7.5rem;
  }
`;

export const CalculatorContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > * {
    min-width: 320px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    order: 2;
  }
`;

export interface ContentContainerProps {
  $order: 1 | 3;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  position: relative;
  width: 100%;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: left;
    order: ${({ $order }) => $order};
  }
`;

ContentContainer.defaultProps = {
  $order: 1,
};
