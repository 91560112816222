import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { PureTransferConcierge } from "../../../design-system/Slices/SliceTransferConcierge/SliceTransferConcierge";
import SliceFooterActions from "../../SliceFooterActions";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import {
  QuoteTextSize,
  QuoteTextSizes,
  TestimonialProps,
} from "../../../_shared";
import { QuotePrismicRichText } from "../../../design-system/Slices/_shared/Text/Text";
import { RiskWarningAndDisclosures } from "../../../design-system/Slices/Helpers/RiskWarningAndDisclosures";

export interface TransferConciergeProps {
  slice: any;
  context?: any;
}

export function TransferConcierge({
  slice,
  context,
  ...props
}: TransferConciergeProps) {
  const isMdUp = useBreakpointMdUp();
  const hasActions =
    slice.primary.cta_options.document &&
    slice.primary.cta_options.document.data.slices.length > 0;

  const testimonials: TestimonialProps[] = slice.items.map(
    ({ testimonials: testimonial }) => {
      const testimonialData = testimonial?.document?.data || null;

      const testimonialRender: TestimonialProps = {
        id: testimonial.id,
        name: testimonialData.full_name,
        tag: testimonialData.tag,
        testimonial: testimonialData.testimonial && (
          <QuotePrismicRichText
            paragraphClassName={QuoteTextSize.smallQuote as QuoteTextSizes}
            field={testimonialData.testimonial.richText}
          />
        ),
        rating: testimonialData.testimonial_rating,
      };
      return testimonialRender;
    }
  );

  return (
    <PureTransferConcierge testimonials={testimonials} {...props}>
      {slice.primary.content ? (
        <PrismicRichText field={slice.primary.content.richText} />
      ) : (
        <></>
      )}
      {hasActions && (
        <SliceFooterActions
          slice={slice.primary.cta_options.document.data.slices[0]}
          align={isMdUp ? "left" : "center"}
          context={context}
        />
      )}
      <RiskWarningAndDisclosures
        riskWarning={slice.primary.risk_warning}
        content={slice.primary.below_cta_content?.richText || null}
        context={context}
      />
    </PureTransferConcierge>
  );
}
