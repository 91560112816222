import { colors } from "../../../../theme/theme";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

interface HeadingsProps {
  darkTheme?: boolean;
  center?: boolean;
}

export const underlineCenter = css`
  &::after {
    left: 50%;
    margin-left: -20px;
  }
`;

export const underline = css`
  position: relative;
  &.center {
    ${underlineCenter}
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 1px;
    background-color: ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.75, colors.white)
        : colors.magenta};
    ${({ theme }) => theme.breakpoints.up("md")} {
      left: 0;
      margin-left: 0;
    }
  }
`;

export const H1Xl = styled.h1.attrs((props: any) => ({
  className: `xl-heading ${props.center && "center"}`,
}))<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: ${({ center }) => (center ? "center" : "start")};
  }
`;

export const H1Large = styled.h1.attrs((props: any) => ({
  className: `large-heading ${props.center && "center"}`,
}))<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: center;
  ${({ theme }) => theme.breakpoints.up("md")} {
    text-align: ${({ center }) => (center ? "center" : "start")};
  }
`;

export const H1 = styled.h1<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;

export const H2 = styled.h2<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;

export const H3 = styled.h3<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;

export const H4 = styled.h4<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;

export const H5 = styled.h5<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;

export const H6 = styled.h6<HeadingsProps>`
  ${({ darkTheme }) =>
    darkTheme &&
    css`
      color: ${colors.white};
    `};
  text-align: ${({ center }) => (center ? "center" : "start")};
`;
