import * as paths from "../paths/index";
import Cookies from "js-cookie";

export async function userLogout() {
  const antiforgery: string | undefined = Cookies.get(
    process.env.GATSBY_ANTIFORGERY_COOKIE_NAME || ""
  );

  await fetch(paths.logoutPath, {
    credentials: "include",
    method: "POST",
    headers: {
      "X-CSRF-TOKEN": antiforgery ? antiforgery : "",
    },
  })
    .then((data) => data.json())
    .then((payload) => {
      window.location.href = payload.redirectUrl;
    });
}
