import { graphql } from "gatsby";
import React from "react";
import { PureTextVideo } from "../../design-system/Slices/SliceTextVideo/SliceTextVideo";
import CustomRichText from "../../CustomRichText";
import SliceFooterActions from "../SliceFooterActions";
import { useBreakpointMdUp } from "../../../hooks/useBreakpoint";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";

/**
 * @typedef {import("@prismicio/client").Content.TextVideoSlice} TextVideoSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextVideoSlice>} TextVideoProps
 * @param { TextVideoProps }
 */

export interface TextVideoProps {
  slice: any;
  context?: any;
}

const TextVideo = ({ slice, context, ...props }: TextVideoProps) => {
  const isLarge = useBreakpointMdUp();
  const hasFooterOptions = slice.primary.cta_options.document;
  const withShadows = slice.primary.shadows ? true : false;
  const mobImgOrder = slice.primary.mobile_image_order;

  return (
    <PureTextVideo
      layout={slice.variation}
      videoUrl={slice.primary.featured_video.url}
      videoPoster={
        slice.primary.video_poster.localFile?.childImageSharp.gatsbyImageData
          .images.fallback.src || null
      }
      autoPlay={slice.primary.autoplay_video}
      loop={slice.primary.loop_video}
      muted={slice.primary.muted_video}
      mobVideoOrder={mobImgOrder}
      withShadows={withShadows}
      {...props}
    >
      {slice.primary.content && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}

      {hasFooterOptions &&
        slice.primary.cta_options.document.data.slices.length > 0 && (
          <SliceFooterActions
            slice={slice.primary.cta_options.document.data.slices[0]}
            align={isLarge ? "left" : "center"}
            context={context}
          />
        )}

      <RiskWarningAndDisclosures
        riskWarning={slice.primary.risk_warning}
        content={slice.primary.below_cta_content?.richText || null}
        context={context}
      />
    </PureTextVideo>
  );
};

export default TextVideo;

export const query = graphql`
  fragment TextVideoVideoText on PrismicTextVideoVideoText {
    id
    slice_type
    slice_label
    variation
    primary {
      autoplay_video
      loop_video
      mobile_image_order
      muted_video
      risk_warning
      shadows
      video_poster {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 492
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
            )
          }
        }
        url
      }
      below_cta_content {
        richText
      }
      content {
        richText
      }
      featured_video {
        url
        id
        type
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
    }
  }

  fragment TextVideoTextVideo on PrismicTextVideoTextVideo {
    id
    slice_type
    slice_label
    variation
    primary {
      autoplay_video
      loop_video
      mobile_image_order
      muted_video
      risk_warning
      shadows
      video_poster {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 492
              formats: [AUTO, WEBP, AVIF]
              quality: 80
              webpOptions: { quality: 95 }
              jpgOptions: { quality: 80 }
            )
          }
        }
        url
      }
      below_cta_content {
        richText
      }
      content {
        richText
      }
      featured_video {
        url
        id
        type
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
    }
  }
`;
