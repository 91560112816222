import styled, { css } from "styled-components";
import { Container } from "../../../Container/Container";
import { sizes } from "../../../../../theme/theme";
import { ColumnsLayout, ColumnsLayouts } from "../../../../_shared";

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ContentContainer = styled.div`
  width: 100%;
  max-width: ${sizes.sm};

  p:last-child {
    margin-bottom: 4rem;
  }
`;

export interface ColumnContainerProps {
  $divider: boolean;
  $align: string;
  $layout: ColumnsLayouts;
}

const singleLayout = css`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: ${sizes.sm};
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: ${sizes.md};
  }
`;

const doubleLayout = css<Pick<ColumnContainerProps, "$divider">>`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: calc(50% - 5rem);

    ${({ $divider }) =>
      $divider &&
      css`
        :after {
          content: "";
          position: absolute;
          right: -2.5rem;
          top: 3rem;
          bottom: 3rem;
          width: 1px;
          background-color: #e8e8e8;
        }
        &:last-child,
        &:nth-child(2n) {
          :after {
            width: 0;
          }
        }
      `}
  }
`;

const defaultLayout = css<Pick<ColumnContainerProps, "$divider">>`
  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-basis: calc(50% - 3rem);

    ${({ $divider }) =>
      $divider &&
      css`
        :after {
          content: "";
          position: absolute;
          right: -1.5rem;
          top: 3rem;
          bottom: 3rem;
          width: 1px;
          background-color: #e8e8e8;
        }
        &:last-child,
        &:nth-child(2n) {
          :after {
            width: 0;
          }
        }
      `}
  }

  ${({ theme }) => theme.breakpoints.up("lg")} {
    flex-basis: calc(33.333% - 2rem);

    ${({ $divider }) =>
      $divider &&
      css`
        :after {
          content: "";
          position: absolute;
          right: -1.5rem;
          top: 3rem;
          bottom: 3rem;
          width: 1px;
          background-color: #e8e8e8;
        }
        &:nth-child(2n) {
          :after {
            width: 1px;
          }
        }
        &:last-child,
        &:nth-child(3n) {
          :after {
            width: 0;
          }
        }
      `}
  }
`;

export const ColumnContainer = styled.div<ColumnContainerProps>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: ${({ $layout }) =>
      $layout === ColumnsLayout.double ? "5rem" : "3rem"};
    text-align: ${({ $align }) => $align};
  }

  div {
    position: relative;
    flex-basis: 100%;

    ${({ $layout }) => $layout === ColumnsLayout.single && singleLayout};
    ${({ $layout }) => $layout === ColumnsLayout.double && doubleLayout};
    ${({ $layout }) => $layout === ColumnsLayout.default && defaultLayout};

    .block-img img {
      width: 80%;
    }
  }
`;
