import styled from "styled-components";
import { H6 } from "../../design-system/Typography/headings/Headings";
import { colors } from "../../../theme/theme";
import { Text } from "../../design-system/Slices/_shared/Text/Text";

export const AsSeenInContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin: 0 auto;
  }
  .swiper {
    display: flex;
    width: 100%;
    padding: 0.25rem 0;
    .swiper-wrapper {
      transition-timing-function: linear !important;
      align-items: center;
      .swiper-slide {
        font-size: 18px;
        text-align: center;
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.swiper-slide-active {
        }
      }
    }
  }
  #as-seen-in-footer {
    padding: 0 1.75rem;
    ${({ theme }) => theme.breakpoints.up("md")} {
      padding: 0;
    }
  }
`;

export const ContentWrapper = styled(Text)`
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
`;

export const Heading = styled(H6)`
  font-style: italic;
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.white : colors.richBlue};
  margin-top: 0;
  margin-bottom: 10px;
`;

export const LogoLink = styled.a`
  opacity: 20%;
  display: flex;
  align-items: center;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 80%;
  }
`;

export const LogoImg = styled.img`
  width: auto;
  height: 60px;
  filter: ${({ theme }) =>
    theme.theme.page === "dark" ? "invert(1)" : "none"};
`;
