import React from "react";
import { graphql } from "gatsby";
import { PureSpacer } from "../../design-system/Slices/SliceSpacer/SliceSpacer";

/**
 * @typedef {import("@prismicio/client").Content.SpacerSlice} SpacerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<SpacerSlice>} SpacerProps
 * @param { SpacerProps }
 */

export interface SpacerProps {
  slice: any;
}

const Spacer = ({ slice }: SpacerProps) => {
  const size = slice.primary.size || "normal";
  return <PureSpacer $mobSize={slice.primary.mobile_size} $size={size} />;
};

export default Spacer;

export const query = graphql`
  fragment SpacerDefault on PrismicSpacerDefault {
    id
    slice_label
    slice_type
    primary {
      size
      mobile_size
    }
  }
`;
