import styled from "styled-components";

export const CloseIcon = styled.svg`
  margin-top: auto;
  width: 100%;
  height: calc(56px + 2rem);
  padding-top: 2rem;
  color: white;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  ${({ theme }) => theme.breakpoints.up("sm")} {
    height: calc(72px + 2rem);
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: none;
  }
`;
