import React from "react";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import { SliceUniversePerformance } from "../../../design-system/Slices/SliceUniversePerformance/SliceUniversePerformance";
import SliceFooterActions from "../../SliceFooterActions";
import { RiskWarningAndDisclosures } from "../../../design-system/Slices/Helpers/RiskWarningAndDisclosures";

export interface UniversePerformanceProps {
  slice: any;
  context?: any;
}

export function UniversePerformance({
  slice,
  context,
  ...props
}: UniversePerformanceProps) {
  const isMdUp = useBreakpointMdUp();
  const hasActions =
    slice.primary.cta_options.document &&
    slice.primary.cta_options.document.data.slices.length > 0;

  return (
    <SliceUniversePerformance
      actions={
        <>
          {hasActions && (
            <SliceFooterActions
              slice={slice.primary.cta_options.document.data.slices[0]}
              align={isMdUp ? "left" : "center"}
              context={context}
            />
          )}
          {slice.primary.below_cta_content?.richText && (
            <RiskWarningAndDisclosures
              content={slice.primary.below_cta_content?.richText}
              context={context}
            />
          )}
        </>
      }
      {...props}
    />
  );
}
