import React from "react";
import { Container, Padding } from "../../Container/Container";
import { colors, sizes } from "../../../../theme/theme";
import { TextAlignment } from "../../../_shared";
import {
  TextOnImageInner,
  ContentContainer,
  StyledTextOnImageContainer,
} from "./SliceTextOnImage.styles";

export interface PureSimpleTextOnImageProps {
  content?: JSX.Element | React.ReactNode | string;
  actions?: JSX.Element | React.ReactNode | string;
  belowCta?: JSX.Element | React.ReactNode | string;
  $align?: TextAlignment.left | TextAlignment.center;
  $minHeight?: string;
  bgColor?: string;
  darkBackground?: boolean;
  bgImage?: string;
  mobBgImage?: string;
}

export function PureSimpleTextOnImage({
  content,
  actions,
  belowCta,
  $align = TextAlignment.center,
  bgColor = colors.richBlue,
  darkBackground = true,
  $minHeight,
  bgImage,
  mobBgImage,
  ...props
}: PureSimpleTextOnImageProps) {
  return (
    <Container className="dark-background" {...props}>
      <TextOnImageInner
        $minHeight={$minHeight}
        $bgColor={bgColor}
        $align={$align}
        $mobBgImage={mobBgImage}
        $bgImage={bgImage}
        className={darkBackground ? "dark-background" : ""}
      >
        <ContentContainer $align={$align} $darkBackground={darkBackground}>
          {content}
          {actions}
          {belowCta}
        </ContentContainer>
      </TextOnImageInner>
    </Container>
  );
}

export interface PureFullWidthTextOnImageProps
  extends PureSimpleTextOnImageProps {
  contentWidth?: "narrow" | "wide" | "extra wide";
}

export function PureFullWidthTextOnImage({
  content,
  contentWidth = "narrow",
  actions,
  belowCta,
  $align = TextAlignment.center,
  bgColor = colors.richBlue,
  darkBackground = true,
  $minHeight,
  bgImage,
  mobBgImage,
  ...props
}: PureFullWidthTextOnImageProps) {
  const getContentWidth = () => {
    if ($align === TextAlignment.left) return "50%";

    if (contentWidth === "wide") return sizes.md;
    if (contentWidth === "extra wide") return sizes.lg;

    return sizes.sm;
  };

  return (
    <Container fullWidth={true} padding={Padding.none} {...props}>
      <StyledTextOnImageContainer
        $minHeight={$minHeight}
        fullWidth={true}
        $bgColor={bgColor}
        $align={$align}
        $mobBgImage={mobBgImage}
        $bgImage={bgImage}
        className={darkBackground ? "dark-background" : ""}
      >
        <ContentContainer
          $align={$align}
          $darkBackground={darkBackground}
          $width={getContentWidth()}
        >
          {content}
          {actions}
          {belowCta}
        </ContentContainer>
      </StyledTextOnImageContainer>
    </Container>
  );
}
