import { colors } from "../../../theme/theme";
import { transparentize } from "polished";
import styled from "styled-components";

export const Nav = styled.nav`
  font-weight: 400;

  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    max-width: 760px;
    padding: 2rem 2.5rem;
    backdrop-filter: blur(12px);
    background-color: ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.95, colors.white)
        : transparentize(0.85, colors.richBlue)};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    gap: 5px 0;

    ${({ theme }) => theme.breakpoints.up("sm")} {
      max-width: 380px;
    }
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    display: flex;
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    gap: 0 20px;
  }

  li {
    position: relative;
    list-style: none;
    width: auto;
    margin: 0;
    ${({ theme }) => theme.breakpoints.down("sm")} {
      width: 100%;
    }

    > a {
      display: flex;
      align-items: center;
      gap: 0 8px;
      color: ${({ theme }) =>
        theme.theme.page === "dark" ? colors.white : colors.richBlue};
      margin: 0;
      padding: 10px 5px;
      text-decoration: none;

      &:focus,
      &:visited {
        outline: none;
        color: ${({ theme }) =>
          theme.theme.page === "dark" ? colors.white : colors.richBlue};
      }

      ${({ theme }) => theme.breakpoints.down("md")} {
        display: flex;
        align-items: center;
        gap: 0 2px;
        color: ${colors.white};
        font-size: 1.25rem;
        font-weight: 400;
        margin: 0;
        padding: 10px 0;

        &:focus,
        &:visited {
          color: ${colors.white};
        }
      }
    }

    svg {
      font-size: 1.2rem;
      color: ${colors.white};

      ${({ theme }) => theme.breakpoints.up("md")} {
        font-size: 0.7rem;
        color: ${({ theme }) =>
          theme.theme.page === "dark" ? colors.white : colors.richBlue};
      }
    }
  }
`;

export const StyledNavItem = styled.span`
  display: flex;
  align-items: center;
  gap: 0 5px;
  color: ${({ theme }) =>
    theme.theme.page === "dark" ? colors.white : colors.richBlue};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down("md")} {
    gap: 0 6px;
    color: #fff;
    font-size: 1.25rem;
    margin: 0;
    padding: 12px 0;
  }
`;

export const StyledSubNav = styled.ul`
  ${({ theme }) => theme.breakpoints.down("md")} {
    position: relative;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    gap: 5px 0;
    transition: all 0.35s ease-in-out;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    position: absolute;
    top: 100%;
    left: -25%;
    width: auto;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: flex-start;
    gap: 17px 0;
    pointer-events: none;
    opacity: 0;
    padding: 1rem;
    border-radius: 10px;
    background-color: ${({ theme }) =>
      theme.theme.page === "dark"
        ? transparentize(0.95, colors.white)
        : transparentize(0.2, colors.white)};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(9px);
    transform: translateY(40px);
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;

    &.open {
      opacity: 1;
      transform: translateY(20px);
      pointer-events: auto;
    }
  }

  li {
    position: relative;
    list-style: none;
    width: 100%;

    ${({ theme }) => theme.breakpoints.down("md")} {
      &:last-child {
        padding-bottom: 20px;
        border-bottom: 1px rgba(238, 238, 238, 0.2) solid;
      }
    }

    ${({ theme }) => theme.breakpoints.up("md")} {
      &:last-child {
        padding-bottom: 17px;
      }
    }

    a {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 1rem;
      gap: 0 10px;
      color: ${({ theme }) =>
        theme.theme.page === "dark"
          ? colors.white
          : transparentize(0.25, colors.richBlue)};

      ${({ theme }) => theme.breakpoints.up("md")} {
        padding: 0;
        gap: 0 17px;
      }

      &.new {
        &:after {
          content: "New";
          position: relative;
          color: ${colors.white};
          font-family: ${({ theme }) => theme.typography.bodyFontFamily};
          text-transform: uppercase;
          font-size: 0.5rem;
          padding: 0.375rem 0.5rem;
          background-color: ${colors.magenta};
          border-radius: 5px;
        }
      }
      &.soon {
        &:after {
          content: "Coming soon!";
          position: relative;
          color: ${colors.white};
          font-family: ${({ theme }) => theme.typography.bodyFontFamily};
          text-transform: uppercase;
          font-size: 0.5rem;
          padding: 0.375rem 0.5rem;
          background-color: ${colors.magenta};
          border-radius: 5px;
        }
      }

      ${({ theme }) => theme.breakpoints.down("md")} {
        color: ${colors.white};
        &.new,
        &.soon {
          &:after {
            color: ${colors.white};
            white-space: nowrap;
            background-color: ${colors.magenta};
            text-transform: unset;
          }
        }
      }
    }
  }
`;
