import styled from "styled-components";
import { Container } from "../design-system/Container/Container";

export interface StyledHeroContainerProps {
  $fullHeight: boolean;
}

export const StyledHeroContainer = styled(Container)<StyledHeroContainerProps>`
  position: relative;
  width: 100%;
  min-height: ${({ $fullHeight, theme }) =>
    $fullHeight ? `calc(100vh - ${theme.header.height})` : "0"};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  padding-top: calc(${({ theme }) => theme.header.height} / 2);

  z-index: 0;
`;

StyledHeroContainer.defaultProps = {
  $fullHeight: true,
};
