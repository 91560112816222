import React from "react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Close, Text, TransferBannerEl } from "./Banner.styles";

export const BANNER_COOKIE_NAME = "dismissedIsaBanner";
const BANNER_COOKIE_EXPIRATION = 90; //days

export default function Banner() {
  const [bannerDismissed, setBannerDismissed] = useState<boolean>(
    JSON.parse(Cookies.get(BANNER_COOKIE_NAME) || "false")
  );

  useEffect(() => {
    if (!bannerDismissed) {
      document.body.classList.add("banner--open");
    } else {
      document.body.classList.remove("banner--open");
    }
  }, [bannerDismissed]);

  return (
    <>
      {!bannerDismissed && (
        <TransferBannerEl className="transfer-banner">
          <Text>
            Don’t forget to make the most of your tax allowance this tax year by
            topping up your Stocks & Shares ISA before the 5th of April.
          </Text>
          <Close
            data-testid="banner-close"
            onClick={() => {
              Cookies.set(BANNER_COOKIE_NAME, "true", {
                expires: BANNER_COOKIE_EXPIRATION,
                domain: ".tillitinvest.com",
              });
              setBannerDismissed(true);
            }}
          />
        </TransferBannerEl>
      )}
    </>
  );
}
