import React from "react";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import { No, Yes } from "../_shared/FeaturedSlides.styles";
import { TableColumnDefinition } from "../../../_shared";
import { PureFeaturedTable } from "../../../design-system/Slices/SliceFeaturedTable/SliceFeaturedTable";

export interface DarkVsTillitUniverseTableProps {
  slice: any;
}

export function DarkVsTillitUniverseTable({
  slice,
  ...props
}: DarkVsTillitUniverseTableProps) {
  const isMdUp = useBreakpointMdUp();
  const cols = [
    {
      name: "",
      key: "title",
      align: "left",
      width: isMdUp ? "50%" : "25%",
    },
    {
      name: "TILLIT Universe assets",
      key: "tillitUniverse",
      align: "center",
      headAlign: "center",
    },
    {
      name: "Dark Universe assets",
      key: "darUniverse",
      align: "center",
      headAlign: "center",
    },
  ] as Array<TableColumnDefinition<any>>;

  const rows = [];

  if (slice.items.length > 0) {
    slice.items.forEach((row: any) => {
      rows.push({
        title: row.row_title || "",
        tillitUniverse: row.tillit_universe_assets === "yes" ? <Yes /> : <No />,
        darUniverse: row.dark_universe_assets === "yes" ? <Yes /> : <No />,
      });
    });
  } else {
    rows.push({});
  }

  return <PureFeaturedTable columns={cols} data={rows} {...props} />;
}
