import styled, { css } from "styled-components";

export interface VideoWrapperProps {
  $rounded: boolean;
  $shadows: boolean;
  $margin: boolean;
}

export const VideoWrapper = styled.div<VideoWrapperProps>`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  box-shadow: ${({ $shadows, theme }) =>
    $shadows ? theme.shadows.extraSmallNeumorphic : "none"};
  border-radius: ${({ $rounded }) => ($rounded ? "12px" : "0")};
  overflow: hidden;
  margin-top: ${({ $margin }) => ($margin ? "2rem" : "0")};

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-top: ${({ $margin }) => ($margin ? "3.5rem" : "0")};
    border-radius: ${({ $rounded }) => ($rounded ? "20px" : "0")};
  }

  iframe.vimeo--video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BaseVideoShadows = css`
  ${({ theme }) =>
    theme.theme.page === "light" &&
    css`
      filter: drop-shadow(-15.6px -15.6px 31.2px #fafbff)
        drop-shadow(5.2px 7.8px 15.6px rgba(166, 171, 189, 0.25));
    `};

  ${({ theme }) => theme.breakpoints.up("md")} {
    ${({ theme }) =>
      theme.theme.page === "light" &&
      css`
        filter: drop-shadow(10px 15px 30px rgba(166, 171, 189, 0.25));
      `}
  }
`;

export interface VideoProps {
  $shadows: boolean;
  $rounded: boolean;
  $maxHeight: string;
}

export const StyledVideo = styled.video<VideoProps>`
  width: 100%;
  border-radius: ${({ $rounded }) => ($rounded ? "10px" : "0")};
  max-height: ${({ $maxHeight }) => $maxHeight};

  ${({ theme }) => theme.breakpoints.up("md")} {
    max-width: 100%;
  }
  ${({ $shadows }) => $shadows && BaseVideoShadows}
`;
