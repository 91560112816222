import React from "react";
import { useTheme } from "styled-components";
import { Keyboard, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  SliderTestimonialContainer,
  SliderTestimonialInner,
  SliderTestimonialContent,
  TestimonialContainer,
  TestimonialsContainer,
  Testimonial,
  Person,
} from "./SliceTestimonials.styles";
import { Container, Padding } from "../../Container/Container";
import { useBreakpointMdUp } from "../../../../hooks/useBreakpoint";
import "swiper/css";
import "swiper/css/navigation";
import { Rating } from "../../../Rating/Rating";
import { TestimonialProps } from "../../../_shared";

export interface PureSliderSliceTestimonialsProps {
  content: JSX.Element | React.ReactNode | string;
  testimonials: TestimonialProps[];
}

export function PureSliderSliceTestimonials({
  content,
  testimonials: testimonials,
  ...props
}: PureSliderSliceTestimonialsProps) {
  const isMdUp = useBreakpointMdUp();
  const theme = useTheme();
  const isDark = theme.theme.page === "dark";

  return (
    <SliderTestimonialContainer
      padding={Padding.both}
      fullWidth={true}
      className={isDark ? "dark-background" : ""}
      {...props}
    >
      <SliderTestimonialInner padding={Padding.none} fullWidth={false}>
        <SliderTestimonialContent>{content}</SliderTestimonialContent>
        <Swiper
          watchOverflow
          navigation={isMdUp}
          slidesPerView={"auto"}
          breakpoints={{
            200: { slidesPerView: 1, spaceBetween: 20 },
            500: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 20 },
            960: { slidesPerView: 2, spaceBetween: 20 },
            1280: { slidesPerView: 3, spaceBetween: 20 },
            1920: { slidesPerView: 3, spaceBetween: 20 },
          }}
          centeredSlides={true}
          loop={true}
          speed={1500}
          spaceBetween={20}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          keyboard={{
            enabled: true,
          }}
          modules={[Autoplay, Navigation, Keyboard]}
        >
          {testimonials.map((testimonial) => {
            return (
              <SwiperSlide key={testimonial.name}>
                <Testimonial>{testimonial.testimonial}</Testimonial>

                <Person>
                  <p className="small">
                    <strong>{testimonial.name}</strong>
                    {testimonial.tag && (
                      <>
                        <br />
                        <i>{testimonial.tag}</i>
                      </>
                    )}
                  </p>
                  {testimonial.rating && <Rating rating={testimonial.rating} />}
                </Person>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderTestimonialInner>
    </SliderTestimonialContainer>
  );
}

export interface PureSingleTestimonialSliceProps extends TestimonialProps {
  actions: JSX.Element | React.ReactNode | string;
  content: JSX.Element | React.ReactNode | string;
}

export function PureSingleTestimonialSlice({
  testimonial,
  name,
  actions,
  content,
  ...props
}: PureSingleTestimonialSliceProps) {
  return (
    <Container fullWidth={true} padding={Padding.both} {...props}>
      <SliderTestimonialContent>
        {testimonial}
        <p className="small">
          <strong>&mdash; {name}</strong>
        </p>
        <>{actions}</>
      </SliderTestimonialContent>
    </Container>
  );
}

export interface PureDualTestimonialSliceProps {
  testimonials: TestimonialProps[];
}

export function PureDualTestimonialSlice({
  testimonials,
  ...props
}: PureDualTestimonialSliceProps) {
  return (
    <Container fullWidth={false} padding={Padding.both} {...props}>
      <TestimonialsContainer>
        {testimonials.map((testimonial) => {
          return (
            <TestimonialContainer key={testimonial.id}>
              {testimonial.testimonial}
              <p className="small">
                <strong>&mdash; {testimonial.name}</strong>
                {testimonial.tag && (
                  <>
                    <br />
                    <i>{testimonial.tag}</i>
                  </>
                )}
              </p>
            </TestimonialContainer>
          );
        })}
      </TestimonialsContainer>
    </Container>
  );
}
