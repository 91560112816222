import React from "react";
import { SpacerSizing, SpacerSizings } from "../../../_shared";
import { StyledSpacer } from "./SliceSpacer.styles";

export interface PureSpacerProps {
  $size: SpacerSizings;
  $mobSize?: SpacerSizings;
}

export function PureSpacer({
  $size = SpacerSizing.normal,
  $mobSize,
  ...props
}: PureSpacerProps) {
  const mobSize = $mobSize || $size;
  return <StyledSpacer $mobSize={mobSize} $size={$size} {...props} />;
}
