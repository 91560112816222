import React from "react";
import { CookieConsent } from "@tillitinvest/cookie-consent";

export interface LayoutProps {
  children: JSX.Element;
}

export default function Providers({ children }: LayoutProps) {
  return (
    <>
      <CookieConsent
        onPreferencesChanged={(prefs) => {
          // With GTM handling consent *and* tag management, all we need to do here is update
          // GTM consent with the configured preferences. GTM'll then drop additional tags (tracking, analytics etc)
          // as required based on that preference. Or do nothing, if everything is denied.

          // @ts-ignore
          gtag("consent", "update", {
            ad_storage: prefs.allowTrackingCookies ? "granted" : "denied",
            analytics_storage: prefs.allowPerformanceCookies
              ? "granted"
              : "denied",
          });
          // @ts-ignore
          gtag("event", "consent.changed", prefs);
        }}
      />
      {children}
    </>
  );
}
