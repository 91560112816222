import { AccountType } from "./useOnboarding";

export const getNameForAccountType = (accountType: AccountType) => {
  const lookup: Partial<{ [key in AccountType]: string }> = {
    [AccountType.Sipp]: "Pension",
    [AccountType.Isa]: "Stocks & Shares ISA",
  };

  return lookup[accountType] ?? accountType;
};
