import React from "react";
import { graphql } from "gatsby";
import SliceFooterActions from "../SliceFooterActions";
import { RowSizeLookup, RowSizes } from "../../_shared/index";
import { PureTextRow } from "../../design-system/Slices/SliceTextRow/SliceTextRow";
import { Padding } from "../../design-system/Container/Container";
import { RiskWarningAndDisclosures } from "../../design-system/Slices/Helpers/RiskWarningAndDisclosures";
import CustomRichText from "../../CustomRichText";

/**
 * @typedef {import("@prismicio/client").Content.TextRowSlice} TextRowSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TextRowSlice>} TextRowProps
 * @param { TextRowProps }
 */
export interface TextRowProps {
  slice: any;
  context?: any;
}

const TextRow = ({ slice, context, ...props }: TextRowProps) => {
  const hasFooterOptions = slice.primary.cta_options.document;
  const rowSize = slice.primary.row_size
    ? RowSizeLookup[slice.primary.row_size as RowSizes]
    : RowSizeLookup["Normal"];
  const isFullWidth = slice.variation === "fullWidth";
  const withBorders = slice.variation === "withBorder";

  const disclosureAndRiskWarning = (slice.primary.risk_warning ||
    slice.primary.below_cta_content?.richText.length > 0) && (
    <RiskWarningAndDisclosures
      riskWarning={slice.primary.risk_warning}
      content={slice.primary.below_cta_content?.richText || null}
      $pad="top"
      context={context}
    />
  );

  const setAlignmentBasedOnWidth = (
    isFullWidth: boolean,
    isLarge: boolean,
    contentAlignment: string
  ) => {
    if (!isFullWidth) {
      return "center";
    }

    if (isLarge || slice.primary.force_alignment_on_mobile) {
      return contentAlignment ? "left" : "center";
    }

    return "center";
  };

  const mdUpAlign = setAlignmentBasedOnWidth(
    isFullWidth,
    true,
    slice.primary.content_alignment
  );
  const align = setAlignmentBasedOnWidth(
    isFullWidth,
    false,
    slice.primary.content_alignment
  );

  return (
    <PureTextRow
      withBorder={withBorders}
      isFullWidth={isFullWidth}
      mdUpAlign={mdUpAlign}
      alignment={align}
      rowSize={rowSize}
      padding={slice.primary?.spacing || Padding.both}
      {...props}
    >
      {slice.primary.content && (
        <CustomRichText
          field={slice.primary.content.richText}
          context={context}
        />
      )}

      {hasFooterOptions &&
        slice.primary.cta_options.document.data.slices.length > 0 && (
          <SliceFooterActions
            slice={slice.primary.cta_options.document.data.slices[0]}
            align={align}
            mdUpAlign={mdUpAlign}
            context={context}
          />
        )}

      {disclosureAndRiskWarning}
    </PureTextRow>
  );
};

export default TextRow;

export const query = graphql`
  fragment TextRowDefault on PrismicTextRowDefault {
    id
    slice_label
    slice_type
    variation
    primary {
      content {
        richText
      }
      spacing
      row_size
      cta_options {
        document {
          ...callToActions
        }
        id
        uid
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment TextRowFullWidth on PrismicTextRowFullWidth {
    id
    slice_label
    slice_type
    variation
    primary {
      content_alignment
      force_alignment_on_mobile
      spacing
      content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
  fragment TextRowWithBorder on PrismicTextRowWithBorder {
    id
    slice_label
    slice_type
    variation
    primary {
      row_size
      content {
        richText
      }
      cta_options {
        id
        uid
        document {
          ...callToActions
        }
      }
      risk_warning
      below_cta_content {
        richText
      }
    }
  }
`;
