import React from "react";
import { useIsUserAuth } from "../../../hooks/useIsUserAuth";
import * as paths from "../../../paths/index";
import { userLogout } from "../../../helpers/logout";
import { Button } from "../../design-system/Buttons";
import { trackGa, GaEventNames } from "../../../helpers/track";
import { useIsClient } from "usehooks-ts";

export function SignUpAction({}) {
  return (
    <>
      <Button
        as="a"
        href={paths.signUpPath}
        $size={"header"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "cta button",
            item_id: "start investing - header",
          });
        }}
      >
        Start investing
      </Button>
    </>
  );
}

export function StaticActions({}) {
  return (
    <>
      <Button
        as="a"
        href={paths.signUpPath}
        $size={"header"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "cta button",
            item_id: "start investing - header",
          });
        }}
      >
        Start investing
      </Button>
      <Button
        as="a"
        href={paths.signInPath}
        $color={"secondary"}
        $size={"header"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "cta button",
            item_id: "sign in - header",
          });
        }}
      >
        Sign in
      </Button>
    </>
  );
}

export function ContextualActions({}) {
  const isClient = useIsClient();
  const signedIn = useIsUserAuth();

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    userLogout();
  };

  // Ensure the initial render is the same on the server and client:
  if (!isClient) {
    return null;
  }

  if (signedIn.data.authenticated) {
    return (
      <>
        <Button
          as="a"
          href={paths.dashboardPath}
          $size={"header"}
          onClick={() => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: "cta button",
              item_id: "my dashboard - header",
            });
          }}
        >
          My dashboard
        </Button>
        <Button
          as="a"
          href={paths.logoutPath}
          $color={"secondary"}
          $size={"header"}
          onClick={(e) => {
            trackGa({
              event: GaEventNames.selectContent,
              content_type: "cta button",
              item_id: "log out - header",
            });
            handleLogout(e);
          }}
        >
          Logout
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        as="a"
        href={paths.signUpPath}
        $size={"header"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "cta button",
            item_id: "start investing - header",
          });
        }}
      >
        Start investing
      </Button>
      <Button
        as="a"
        href={paths.signInPath}
        $color={"secondary"}
        $size={"header"}
        onClick={() => {
          trackGa({
            event: GaEventNames.selectContent,
            content_type: "cta button",
            item_id: "sign in - header",
          });
        }}
      >
        Sign in
      </Button>
    </>
  );
}
