import React from "react";
import { StyledGatsbyImage, StyledStaticImage } from "./Image.style";
import { IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { ImageObjectFit, ObjectFit } from "../../_shared";

export interface ImageProps {
  withShadows?: boolean;
  rounded?: boolean;
  backgroundObjectFit?: ImageObjectFit;
  image: any;
}

export function Image({
  withShadows = true,
  rounded = true,
  backgroundObjectFit = ObjectFit.cover,
  image,
}: ImageProps) {
  const hasGatsbyImageData = image.hasOwnProperty("gatsbyImageData");

  return (
    <>
      {image && hasGatsbyImageData ? (
        <StyledGatsbyImage
          $rounded={rounded}
          $shadows={withShadows}
          objectFit={backgroundObjectFit}
          image={getImage(image.gatsbyImageData) as IGatsbyImageData}
          alt={image.alt || ""}
        />
      ) : (
        <StyledStaticImage
          $rounded={rounded}
          $shadows={withShadows}
          src={image.url}
          alt={image.alt || ""}
        />
      )}
    </>
  );
}
